<template>
  <div>
    <InfoDialog
      :infoDialogIncome.sync="infoDialog"
      :messageIncome="messageIncome"
      @closeInfoDialog="closeInfoDialog"
    />
    <AfterSuccessRegDialog
      :afterSuccessRegDialog.sync="showSuccessDialog"
      @closeSucRegDialog="closeSuccessRegDialog"
    />
    <LoginDialog
      :showDialogIncome.sync="showDialog"
      :email.sync="email"
      :activeNameTab.sync="activeNameTab"
      @openRemind="showRemindDialog = true"
      @close="closeDialog"
    />
    <RemindDialog
      :showRemindDialogIncome.sync="showRemindDialog"
      :email.sync="email"
      @close="closeRemindDialog"
    />
    <TooltipUnderConstructionDialog
      :showTooltipUnderConstructionDialogIncome.sync="
        showTooltipUnderConstructionDialog
      "
      @closeTooltipUnderDialog="closeTooltipUnderDialog"
    />
    <TooltipDialog
      :showTooltipDialogIncome.sync="showTooltipDialog"
      @closeToolDialog="closeTooltipDialog"
    />
    <EmailConfirmedDialog
      :showEmailDialogIncome.sync="showEmailDialog"
      @closeEmailD="closeEmailDialog"
    />
    <ErrorDialog
      :showErrorDialogIncome.sync="showErrorDialog"
      @closeErrorD="closeErrorDialog"
    />
    <InflictionDialog
      :toInflictDialogIncome.sync="toInflictDialog"
      @closeInflictionDialog="closeInflictionDialog"
    />
    <TransitDateDialog
      :showTransitDialogIncome.sync="transitDateDialog"
      @closeTransitDateDialog="closeTransitDateDialog"
    />
    <el-row v-if="tranSit" type="flex" justify="center" :gutter="20">
      <el-col :xs="24" :xl="24" class="report">
        <el-card class="box-card el-card-with-bg">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col
                class="header-flex-xs-center mob-flex-direction-column"
                :span="24"
                :xs="24"
              >
                <div v-if="bodyGraph" class="no-mobile">
                  Your report: {{ bodyGraph.first_name }},
                  {{ bodyGraph.birthday }}&nbsp;
                </div>
                <div v-if="bodyGraph" class="no-desktop">
                  Your report:<br />
                  {{ bodyGraph.first_name }}<br />
                  {{ bodyGraph.birthday }}
                </div>
                <div class="no-mobile">Transit: {{ tranSit.birthday }}</div>
                <div class="no-desktop">
                  Transit:<br />
                  {{ tranSit.birthday }}
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-if="tranSit">
            <div class="body-graph-wrapper">
              <TranSit
                :TranSit.sync="tranSit"
                @incomeToolObj="incomeTooltipsObj"
              />
            </div>
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <div class="no-mobile">
                  <el-button
                    v-if="tranSit"
                    @click="newTransit"
                    type="primary"
                    icon="el-icon-document"
                    size="medium"
                    plain
                    round
                  >
                    <span>New transit</span>
                  </el-button>
                  <el-button
                    @click="toInflictDialog = true"
                    type="success"
                    icon="el-icon-document"
                    size="medium"
                    plain
                    round
                  >
                    <span>Infliction</span>
                  </el-button>
                  <el-button
                    v-if="!bodyGraph"
                    @click="shareTransit(tranSit.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="medium"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-button
                    v-else
                    @click="shareInfliction(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="medium"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-link
                    v-if="isLogged"
                    download="transit.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-link
                    v-else
                    @click="showDialog = true"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-button
                    v-if="!isLogged"
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="medium"
                    plain
                    round
                  >
                    <span>Login/Register</span>
                  </el-button>
                </div>
                <div class="no-desktop">
                  <el-button
                    v-if="tranSit"
                    @click="newTransit"
                    type="primary"
                    icon="el-icon-document"
                    size="mini"
                    plain
                    round
                  >
                    <span>New</span>
                  </el-button>
                  <el-button
                    @click="toInflictDialog = true"
                    type="success"
                    icon="el-icon-document"
                    size="mini"
                    plain
                    round
                  >
                    <span>Infliction</span>
                  </el-button>
                  <el-button
                    v-if="!bodyGraph"
                    @click="shareTransit(tranSit.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="mini"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-button
                    v-else
                    @click="shareInfliction(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="mini"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-link
                    download="transit.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-button
                    v-if="!isLogged"
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="mini"
                    plain
                    round
                  >
                    <span>Login</span>
                  </el-button>
                </div>
              </el-col>
              <el-col>
                <textarea
                  ref="targetTransitUrl"
                  :value="shareLink"
                  style="height:1px;opacity:0"
                ></textarea>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import TranSit from "@/components/TranSit";
import InfoDialog from "@/components/dialogs/InfoDialog";
import TransitDateDialog from "@/components/dialogs/TransitDateDialog";
import LoginDialog from "@/components/dialogs/LoginDialog";
import RemindDialog from "@/components/dialogs/RemindDialog";
import AfterSuccessRegDialog from "@/components/dialogs/AfterSuccessRegDialog";
import TooltipDialog from "@/components/dialogs/TooltipDialog";
import TooltipUnderConstructionDialog from "@/components/dialogs/TooltipUnderConstructionDialog";
import EmailConfirmedDialog from "@/components/dialogs/EmailConfirmedDialog";
import ErrorDialog from "@/components/dialogs/ErrorDialog";
import InflictionDialog from "@/components/dialogs/InflictionDialog";

export default {
  name: "PoindOfEntry",
  props: {
    msg: String
  },
  components: {
    TranSit,
    InfoDialog,
    LoginDialog,
    RemindDialog,
    EmailConfirmedDialog,
    AfterSuccessRegDialog,
    TooltipDialog,
    TooltipUnderConstructionDialog,
    ErrorDialog,
    InflictionDialog,
    TransitDateDialog
  },
  data() {
    return {
      showDialog: false,
      showRemindDialog: false,
      showSuccessDialog: false,
      showTooltipDialog: {},
      showEmailDialog: { show: false },
      showTooltipUnderConstructionDialog: false,
      showErrorDialog: { show: false },
      activeName: "Additional Plan",
      activeCenters: "all",
      activeGates: "all",
      gatesSubconscious: [],
      gatesConscious: [],
      gatesBoth: [],
      centersDefined: [],
      centersUndefined: [],
      centersAll: [],
      infoDialog: false,
      closeButtonAttr: false,
      shareLink: "",
      image: "",
      png: "",
      toInflictDialog: false,
      transitDateDialog: false,
      messageIncome:
        "The mail has been sent successfully. All mails are processed in the order of arrival. On average, it takes from 2 to 4 hours."
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    isEmailConfirmed: {
      get() {
        return this.$store.getters.IS_EMAIL_CONFIRMED;
      },
      set(value) {
        this.$store.commit("IS_EMAIL", value);
      }
    },
    isUnderConstructionTransit: {
      get() {
        return this.$store.getters.IS_UNDER_CONSTRUCTION_TRANSIT;
      },
      set(value) {
        this.$store.commit("IS_UNDER_CONSTR_TRANSIT", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    email: {
      get() {
        return this.$store.state.User.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      }
    },
    activeNameTab: {
      get() {
        return this.$store.state.User.activeNameTab;
      },
      set(value) {
        this.$store.commit("SET_ACTIVE_NAME_TAB", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    tranSit() {
      return this.User.chartTransit;
    },
    bodyGraph: {
      get() {
        return this.User.chartTransitBodygraph;
      },
      set(value) {
        this.$store.commit("SET_INFLICTION", value);
      }
    }
  },
  watch: {
    bodyGraph() {
      this.downloadReport();
    }
  },
  mounted() {
    this.initApp();
    this.initReport();
    this.getSettings();
    this.getProfile();
    setTimeout(() => {
      if (this.tranSit) this.downloadReport();
      if (!this.tranSit) this.createTransit(this.getBirthday);
      this.getPartnerId();
    }, 500);
  },
  methods: {
    ...mapMutations([
      "SET_TRANSIT",
      "SET_INFLICTION",
      "IS_EMAIL",
      "SET_PARTNER_ID",
      "SET_PROFILE"
    ]),
    async getSettings() {
      try {
        const result = await Api.getSettings();
        if (result.success) {
          // console.log(result.messages);
          this.$store.commit("SET_MESSAGES", result.messages);
          // console.log(this.$store.state.Messages);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getProfile() {
      if (this.isLogged) {
        try {
          const result = await Api.getProfile();
          if (result.success) {
            this.SET_PROFILE(result.profile);
            if (result.profile.is_email_confirmed) {
              this.IS_EMAIL(true);
            } else {
              this.IS_EMAIL(false);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    initReport() {
      this.getItemFromLocalStorage("transitForm", "SET_TRANSIT_FORM");
      this.getItemFromLocalStorage("transitCity", "SET_TRANSIT_CITY_ID");
    },
    getBirthday() {
      const dateTimeNow = new Date();
      const year = dateTimeNow.getFullYear();
      const month = dateTimeNow.getMonth() + 1;
      const day = dateTimeNow.getDate();
      const hours = dateTimeNow.getHours();
      const minutes = dateTimeNow.getMinutes();
      const birthday = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      } ${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      return birthday;
    },
    async createTransit(birthday) {
      try {
        if (!this.city) {
          this.city = 18009;
        }
        const reportParams = {
          city_id: this.city,
          transit_birthday: birthday()
        };
        const result = await Api.createTransit(reportParams);
        if (result.success) {
          // console.log(result.report);
          this.SET_TRANSIT(result.report);
          setTimeout(() => {
            if (this.tranSit) this.downloadReport();
          }, 500);
        }
      } catch (e) {
        console.log(e);
      }
    },
    scrollToTop() {
      const widgethead = document.getElementById("app");
      widgethead.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    },
    closeRemindDialog() {
      this.showRemindDialog = false;
    },
    closeSuccessRegDialog(v) {
      this.showSuccessDialog = v;
    },
    closeInfoDialog() {
      this.infoDialog = false;
    },
    incomeTooltipsObj(obj) {
      // console.log(obj);
      if (this.isUnderConstructionTransit) {
        // this.showTooltipUnderConstructionDialog = true;
        return;
      } else {
        if (this.isLogged) {
          this.showTooltipDialog = {
            show: true,
            tooltip: obj
          };
        } /* else if (this.isLogged && !this.isEmailConfirmed) {
          this.showEmailDialog = {
            show: true,
            message: "You need to confirm your email"
          };
        } */ else {
          this.showDialog = true;
        }
      }
    },
    closeEmailDialog() {
      this.showEmailDialog = { show: false };
    },
    closeTooltipDialog(obj) {
      this.showTooltipDialog = obj;
    },
    closeTooltipUnderDialog() {
      this.showTooltipUnderConstructionDialog = false;
    },
    closeErrorDialog(obj) {
      this.showErrorDialog = obj;
    },
    newTransit() {
      this.transitDateDialog = true;
    },
    async shareTransit(id) {
      if (this.isLogged) {
        try {
          this.shareLink = "";
          const result = await Api.shareTransitReport({ report_id: id });
          if (result.success) {
            this.shareLink = result.share_link;
            // console.log(this.shareLink);
            setTimeout(() => {
              this.$refs.targetTransitUrl.select();
              document.execCommand("copy");
            }, 100);
            this.$message({
              message: "Link to share copied",
              type: "success"
            });
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.showDialog = true;
      }
    },
    closeTransitDateDialog(/* birthday */) {
      this.transitDateDialog = false;
      this.scrollToTop();
      setTimeout(() => {
        if (this.tranSit) this.downloadReport();
      }, 500);
    },
    async shareInfliction(id) {
      if (this.isLogged) {
        try {
          this.shareLink = "";
          const result = await Api.shareInflictionReport({ report_id: id });
          if (result.success) {
            this.shareLink = result.share_link;
            // console.log(this.shareLink);
            setTimeout(() => {
              this.$refs.targetTransitUrl.select();
              document.execCommand("copy");
            }, 100);
            this.$message({
              message: "Link to share copied",
              type: "success"
            });
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.showDialog = true;
      }
    },
    downloadReport() {
      const svgElement = document.getElementById("svgTransit1582");
      let { width, height } = svgElement.getBBox();
      // true for deep clone
      let clonedSvgElement = svgElement.cloneNode(true);
      let outerHTML = clonedSvgElement.outerHTML,
        blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });
      let URL = window.URL || window.webkitURL || window;
      let blobURL = URL.createObjectURL(blob);
      let image = new Image();
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = Math.round(width);
        canvas.height = Math.round(height);
        let context = canvas.getContext("2d");
        // draw image in canvas starting left-0 , top - 0
        context.drawImage(image, 0, 0, Math.round(width), Math.round(height));
        this.png = canvas.toDataURL();
        // console.log("png: ", this.png);
      };
      image.src = blobURL;
    },
    getPartnerId() {
      const instance = document.getElementById("wiget");
      if (instance) {
        this.SET_PARTNER_ID(instance.dataset.partnerId);
        // console.log("instance is: ", instance.dataset.partnerId);
      }
    },
    closeInflictionDialog(v) {
      if (v) {
        this.toInflictDialog = false;
      }
    }
  }
};
</script>
<style scoped>
.city {
  display: flex;
  align-items: center;
}
.city-right-side {
  display: flex;
  margin-bottom: 10px;
}
.city-title {
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.country-title {
  font-size: 12px;
  color: #afafaf;
  padding-left: 7px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-align-left {
  text-align: left;
}
.flex {
  display: flex;
}
.padding-lt-0 {
  padding-left: 0 !important;
}
.padding-rt-0 {
  padding-right: 0 !important;
}
.width-100 {
  width: 100%;
}
.justify-end {
  justify-content: end;
}
.report-form {
  min-width: 500px;
  max-width: 500px;
}
.report {
  min-width: 500px !important;
  max-width: 920px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-column {
  display: flex;
}
.el-col-item {
  margin-bottom: 20px;
}
.flex-column .el-col-item,
.flex-column2 .el-col-item {
  align-self: stretch;
}
.flex-column .el-col-item .el-card,
.flex-column2 .el-col-item .el-card {
  height: 100%;
}
.tip {
  padding: 8px 16px;
  background-color: #f0f0fd;
  border-radius: 4px;
  border-left: 5px solid #bcbeff;
  margin: 15px 10px;
  text-align: left;
  font-size: 16px;
  color: #4c4e86;
  line-height: 1.5em;
}
.width-70 {
  width: 70%;
}
.login-wrapper-desk {
  margin: 0 70px;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .flex-column,
  .flex-column2 {
    flex-direction: column;
  }
  .flex-column .el-col-item,
  .flex-column2 .el-col-item {
    align-self: start;
  }
  .report-form,
  .report {
    min-width: initial;
  }
  .width-100-m {
    width: 100%;
  }
  .login-wrapper-desk {
    margin: 0;
  }
  .report-form {
    min-width: 320px;
    max-width: 430px;
  }
  .report {
    min-width: 320px !important;
    max-width: 430px !important;
  }
}
</style>
