<template>
  <svg
    version="1.1"
    id="svgComposite1582"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 760 790"
    style="enable-background:new 0 0 760 790;background-color:white"
    xml:space="preserve"
  >
    <div v-html="css"></div>
    <sodipodi:namedview
      bordercolor="#666666"
      borderlayer="true"
      borderopacity="1"
      fit-margin-bottom="20"
      fit-margin-left="20"
      fit-margin-right="20"
      fit-margin-top="20"
      gridtolerance="10"
      guidetolerance="10"
      id="namedview1584"
      inkscape:current-layer="svg1582"
      inkscape:cx="145.87012"
      inkscape:cy="564.47925"
      inkscape:guide-bbox="true"
      inkscape:pagecheckerboard="false"
      inkscape:pageopacity="1"
      inkscape:pageshadow="2"
      inkscape:window-height="1386"
      inkscape:window-maximized="1"
      inkscape:window-width="2560"
      inkscape:window-x="0"
      inkscape:window-y="25"
      inkscape:zoom="1"
      objecttolerance="10"
      pagecolor="#f8f8f8"
      showgrid="false"
      showguides="true"
    >
    </sodipodi:namedview>
    <g id="layer2" inkscape:groupmode="layer" inkscape:label="Background">
      <g id="g8332" transform="translate(0,2.9624634)">
        <g v-if="!isChartTurnOn && !isChart2TurnOn" id="g7327">
          <g id="g6958" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_pluto"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.pluto)"
              transform="matrix(1 0 0 1 -10 705.2269)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.pluto.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.pluto.line || 0
              }}
            </text>
            <g
              @click="openTooltipsDialog(compoSite.chart.planets.pluto)"
              class="cursor-pointer over-shadow-anim"
              transform="translate(45.056651,0)"
            >
              <path
                id="design_sign_pluto"
                inkscape:connector-curvature="0"
                class="st13"
                d="
                        M10,709.9H-3.4c-0.4,0.1-0.8-0.3-0.9-0.8c0-0.1,0-0.1,0-0.2l0.1-22.2c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3l9.1,0.1
                        c4.4,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9l-8.1,0.1v6.2h12.4c0.6,0,1,0.4,1,1C10.8,709.6,10.5,709.9,10,709.9L10,709.9z
                        M-2.4,687.7V700l8.1-0.1c3.3,0,6.1-2.7,6.1-6.1c0-3.4-2.7-6.1-6.1-6.1L-2.4,687.7L-2.4,687.7z"
              />
              <path
                class="st13"
                d="M-3.6,710.9c-0.4,0-0.7-0.1-1-0.3c-0.4-0.3-0.7-0.8-0.8-1.2v-0.3l0.1-22.2c0-0.5,0.2-1.1,0.6-1.3
                        c0.5-0.5,1-0.7,1.5-0.6l8.9,0.1c5,0,9,4,9,9s-4,9-9,9l-7.2,0.1v4.1H10c1.1,0,2,0.9,2,2v0.2c-0.2,1-1,1.6-2,1.6H-3.3
                        C-3.4,710.9-3.5,710.9-3.6,710.9z M-1.4,688.7V699l7.2-0.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1L-1.4,688.7z"
              />
              <rect x="-10" y="683" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_pluto"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.pluto)
              "
              transform="matrix(1 0 0 1 65.9326 705.2269)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.pluto.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.pluto.line || 0
              }}
            </text>
          </g>
          <g id="g6954" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_neptune"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.neptune)
              "
              transform="matrix(1 0 0 1 -10 654.3114)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.neptune.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.neptune.line || 0
              }}
            </text>
            <g
              id="design_sign_neptune"
              @click="openTooltipsDialog(compoSite.chart.planets.neptune)"
              transform="translate(68.056651,22.292976)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st14"
                d="M-8.1,613.5l-2.4-4.5c-0.2-0.4-0.6-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,2-1,3.7-2.4,5
                        c-0.9,0.8-2.1,0.1-2.1-1.1v-9.5h0.3c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.6-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9
                        h0.8v9.5c0,1.1-1.2,1.6-2.1,1.1c-1.4-1.1-2.4-2.9-2.4-5v-5.5h0.3c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.6-0.4-0.9,0
                        l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,4.6,3,8.4,7.1,9.8c0.8,0.2,1.2,1,1.2,1.6v2.4h-1.4c-1.1,0-2,0.9-2,2l0,0
                        c0,1.1,0.9,2,2,2h1.6v1.2c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-1.2h1.4c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-1.5v-2.4
                        c0-0.8,0.5-1.4,1.2-1.6c4.1-1.3,7.1-5.2,7.1-9.8v-5.5h0.4C-8,614.4-7.8,614-8.1,613.5z"
              />
              <rect x="-33" y="605" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_neptune"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.neptune)
              "
              transform="matrix(1 0 0 1 65.9326 654.3114)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.neptune.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.neptune.line || 0
              }}
            </text>
          </g>
          <g id="g6944" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_uranus"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.uranus)"
              transform="matrix(1 0 0 1 -10 603.3959)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.uranus.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.uranus.line || 0
              }}
            </text>
            <g
              id="design_sign_uranus"
              @click="openTooltipsDialog(compoSite.chart.planets.uranus)"
              transform="translate(68.056651,21.642479)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st14"
                d="M-9.7,568.7v-5H-8v-1.8c0-1-0.9-1.7-1.7-1.7l0,0l0,0h-2c-1,0-1.7,0.9-1.7,1.7v5.1h-4v-4.7
                        c0-1-0.9-1.7-1.7-1.7l0,0c-1,0-1.7,0.9-1.7,1.7v4.7h-4v-5.1c0-1-0.9-1.7-1.7-1.7h-2l0,0l0,0c-1,0-1.7,0.9-1.7,1.7v1.8h1.7v5
                        l0,0v0.1l0,0v5h-1.7v1.8c0,1,0.9,1.7,1.7,1.7h1.8l0,0h0.1c1,0,1.7-0.9,1.7-1.7v-5.1h4v8.7c-2.1,0.9-3.5,2.9-3.3,5.3
                        c0.3,2.3,2.2,4.1,4.5,4.5c3,0.3,5.7-2.1,5.7-5c0-2.2-1.3-4-3.3-4.8v-8.7h4v5.1c0,1,0.9,1.7,1.7,1.7h0.1l0,0h1.8
                        c1,0,1.7-0.9,1.7-1.7v-1.8h-1.7L-9.7,568.7L-9.7,568.7L-9.7,568.7L-9.7,568.7z M-19,585.3c-1.1,0.2-1.8-0.8-1.7-1.7
                        c0.1-0.6,0.6-1.1,1.2-1.2c1.1-0.2,1.8,0.8,1.7,1.7C-17.8,584.6-18.3,585.2-19,585.3z"
              />
              <rect x="-33" y="550" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_uranus"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.uranus)
              "
              transform="matrix(1 0 0 1 65.9326 603.3959)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.uranus.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.uranus.line || 0
              }}
            </text>
          </g>
          <g id="g6935" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_saturn"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.saturn)"
              transform="matrix(1 0 0 1 -10 552.366)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.saturn.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.saturn.line || 0
              }}
            </text>
            <g
              id="design_sign_saturn"
              @click="openTooltipsDialog(compoSite.chart.planets.saturn)"
              transform="translate(70.056651,22.837349)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st14"
                d="M-10.5,529.7c1.7-2.1,3-5.9,1.7-9.6c-1-2.7-3.3-4.6-6.3-5.3c-3.5-0.8-6.5,0.6-8.8,2.4
                        c-0.5,0.4-1.2,0-1.2-0.6v-3h1.5c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-1.5v-1.5c0-1.1-0.9-2-2-2l0,0c-1.1,0-2,0.9-2,2v1.5h-1.4
                        c-1.1,0-2,0.9-2,2l0,0c0,1.1,0.9,2,2,2h1.5v21.8c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-9.3c0-1.3,0.4-2.6,1.2-3.6
                        c1.6-2.1,4.6-4.8,7.9-4c1.8,0.4,3,1.3,3.5,2.7c0.8,2,0,4.5-1.1,5.8c-2.9,3.5-3.3,6.7-1.2,10.5c0.5,1.1,1.8,1.3,2.8,0.6l0,0
                        c0.9-0.5,1.1-1.6,0.6-2.5C-12.8,533.4-12.2,531.7-10.5,529.7z"
              />
              <rect x="-33" y="503" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_saturn"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.saturn)
              "
              transform="matrix(1 0 0 1 65.9326 552.366)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.saturn.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.saturn.line || 0
              }}
            </text>
          </g>
          <g id="g6928" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_jupiter"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.jupiter)
              "
              transform="matrix(1 0 0 1 -10 501.5546)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.jupiter.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.jupiter.line || 0
              }}
            </text>
            <g
              id="design_sign_jupiter"
              @click="openTooltipsDialog(compoSite.chart.planets.jupiter)"
              transform="translate(70.056651,22.491493)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13"
                d="M-8.3,475.8h-5.3c-0.9,0-1.4-0.6-1.4-1.4v-13.8c0-1-0.8-1.6-1.6-1.6c-0.9,0-1.6,0.8-1.6,1.6v13.8
                        c0,0.9-0.6,1.4-1.4,1.4h-8.8c-0.8,0-1.1-0.9-0.5-1.3l3.6-3.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.8-0.8
                        c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.5-0.5c0.5-0.5,0.7-1.1,0.8-1.1c0.1-0.3,0.2-0.6,0.3-1c0.2-0.6,0.3-1.2,0.3-1.8
                        c0-0.1,0-0.2,0-0.2c0-4.3-3.6-7.9-7.9-7.9c-1.4,0-2.8,0.4-4,1.1c-1,0.5-1.1,1.8-0.3,2.6c0.5,0.5,1.3,0.6,2,0.3
                        c0.8-0.4,1.5-0.6,2.4-0.6c2.6,0,4.7,2.1,4.7,4.7c0,0.8-0.2,1.4-0.5,2.1l-2.2,2.3l-7.1,7.2v0.1c0,1.8,1.4,3.3,3.3,3.3h11.8
                        c0.9,0,1.4,0.6,1.4,1.4v4.7c0,1,0.8,1.6,1.6,1.6c0.9,0,1.6-0.8,1.6-1.6v-4.8c0-0.9,0.6-1.4,1.4-1.4H-8c1,0,1.6-0.8,1.6-1.6
                        C-6.5,476.5-7.3,475.8-8.3,475.8z"
              />
              <rect x="-33" y="450" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_jupiter"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.jupiter)
              "
              transform="matrix(1 0 0 1 65.9326 501.5546)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.jupiter.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.jupiter.line || 0
              }}
            </text>
          </g>
          <g
            id="g6922"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer"
          >
            <text
              id="design_number_mars"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.mars)"
              transform="matrix(1 0 0 1 -10 450.639)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.mars.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.mars.line || 0
              }}
            </text>
          </g>
          <g
            id="g6922.2"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer"
          >
            <g
              id="design_sign_mars"
              @click="openTooltipsDialog(compoSite.chart.planets.mars)"
              transform="translate(70.056651,22.618215)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13"
                d="M-8.5,407.3c-0.1-0.6-0.8-1.1-1.3-1.1l-5.7,0.8c-0.6,0.1-0.8,0.4-0.2,0.9l1.5,1.1
                        c0.3,0.3,0.4,0.8,0.2,1.1l-3.6,4.9c-0.6,0.9-1.7,1.2-2.8,1s-2.2-0.4-3.3-0.3l0,0l0,0c-2.9,0.2-5.5,1.6-7.3,3.9
                        c-3.3,4.3-2.4,10.4,2,13.7c1.6,1.2,3.7,2,5.8,2c0.5,0,1,0,1.4-0.1c2.6-0.4,4.9-1.7,6.4-3.8c1.7-2.3,2.4-5.2,1.6-7.9
                        c-0.2-1.1-0.6-2.1-1.2-3c-0.7-0.9-0.5-2.1,0.1-3l3.6-4.9c0.3-0.3,0.8-0.4,1.1-0.2l1.5,1.1c0.5,0.4,0.9,0.2,0.8-0.4L-8.5,407.3z
                        M-18.3,429.1c-1,1.3-2.4,2.2-4,2.4s-3.3-0.2-4.6-1.1c-2.7-2-3.3-5.9-1.2-8.6c1.1-1.4,2.7-2.3,4.6-2.5c1.4-0.1,2.9,0.3,4,1.2
                        c1.1,0.9,2,2.2,2.3,3.5C-16.9,425.9-17.2,427.7-18.3,429.1z"
              />
              <rect x="-33" y="400" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_mars"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.mars)
              "
              transform="matrix(1 0 0 1 65.9326 450.639)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.mars.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.mars.line || 0
              }}
            </text>
          </g>
          <g id="g6915" transform="translate(23.809448,18.44752)">
            <g
              id="design_sign_venus"
              @click="openTooltipsDialog(compoSite.chart.planets.venus)"
              transform="translate(68.056651,22.910158)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13"
                d="M-12.1,364c0-2.7-1.2-5.3-3.3-7.2l0,0l0,0c-1.6-1.4-3.8-2.3-6.1-2.3c-2.2,0-4.3,0.9-6,2.3l0,0l0,0
                        c-2.1,1.7-3.3,4.3-3.3,7.2c0,4,2.6,7.5,6.1,8.8c1,0.3,1.5,1.1,1.5,2.2v2.6h-2c-1,0-1.6,0.8-1.6,1.6l0,0c0,1,0.8,1.6,1.6,1.6
                        h2.1v2.2c0,1,0.8,1.6,1.6,1.6l0,0c1,0,1.6-0.8,1.6-1.6v-2.2h2c1,0,1.6-0.8,1.6-1.6l0,0c0-1-0.8-1.6-1.6-1.6h-2V375
                        c0-1,0.6-1.8,1.5-2.2C-14.7,371.4-12.1,368-12.1,364z M-27.4,364c0-1.7,0.8-3.4,2.1-4.6l0,0c2.2-1.8,5.6-1.8,7.8,0l0,0
                        c1.3,1.1,2.1,2.8,2.1,4.6c0,3.3-2.7,6-6,6C-24.7,369.9-27.4,367.2-27.4,364z"
              />
              <rect x="-33" y="348" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="design_number_venus"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.venus)"
              transform="matrix(1 0 0 1 -10 399.7235)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.venus.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.venus.line || 0
              }}
            </text>
            <text
              id="person_number_venus"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.venus)
              "
              transform="matrix(1 0 0 1 65.9326 399.7235)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.venus.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.venus.line || 0
              }}
            </text>
          </g>
          <g id="g6908" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_mercury"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.mercury)
              "
              transform="matrix(1 0 0 1 -10 348.8182)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.mercury.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.mercury.line || 0
              }}
            </text>
            <g
              id="design_sign_mercury"
              @click="openTooltipsDialog(compoSite.chart.planets.mercury)"
              transform="translate(68.056651,22.999209)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13"
                d="M-12.2,314.3c0-2.1-0.8-4.1-2.1-5.8c0.9-1.1,1.3-2.3,1.6-3.6c0.2-1.1-0.5-2-1.5-2l0,0
                        c-0.8,0-1.4,0.5-1.5,1.2c-0.2,0.8-0.5,1.4-1,2.1c-1.4-0.9-3-1.3-4.7-1.3s-3.3,0.4-4.7,1.3c-0.4-0.6-0.9-1.3-1-2.1
                        c-0.2-0.8-0.9-1.2-1.5-1.2l0,0c-1.1,0-1.7,1-1.5,2c0.3,1.3,0.9,2.5,1.6,3.6c-1.3,1.6-2.1,3.6-2.1,5.8c0,4.1,2.7,7.6,6.4,8.8
                        c0.6,0.2,1.1,0.8,1.1,1.3v2.9h-1.5c-1,0-1.7,0.9-1.7,1.7l0,0c0,1,0.9,1.7,1.7,1.7h1.5v1.7c0,1,0.9,1.7,1.7,1.7l0,0
                        c1,0,1.7-0.9,1.7-1.7v-1.6h1.5c1,0,1.7-0.9,1.7-1.7l0,0c0-1-0.9-1.7-1.7-1.7h-1.5v-2.9c0-0.6,0.4-1.2,1.1-1.3
                        C-14.9,321.9-12.2,318.4-12.2,314.3z M-27,315.1c-0.3-2,0.4-3.9,2-5.2l0,0c2.1-1.7,5.3-1.7,7.4,0l0,0l0,0c1.2,1.1,2,2.7,2,4.3
                        c0,3.1-2.6,5.7-5.7,5.7C-24.2,319.9-26.6,317.9-27,315.1z"
              />
              <rect x="-33" y="300" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_mercury"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.mercury)
              "
              transform="matrix(1 0 0 1 65.9326 348.8182)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.mercury.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.mercury.line || 0
              }}
            </text>
          </g>
          <g id="g6899" transform="translate(23.809448,18.44752)">
            <g transform="translate(45.056651,0)" class="over-shadow-anim">
              <path
                class="st15"
                d="M9.8,284c-0.3-0.2-0.3-0.6-0.1-1c0.5-0.8,0.9-1.6,0.6-2.7c-0.2-1.7-1.6-3.1-3.3-3.3
                      c-2.3-0.2-4.2,1.5-4.2,3.8c0,1.2,0.5,2.3,1.5,3l0,0c0.5,0.4,1.2,0.6,1.8,0.8c3.3,1.4,5.3,4.7,5.3,8.2c0,5-4,9.1-9.1,9.1
                      c-5,0-9.1-4-9.1-9.1c0-3.5,2.1-6.7,5.3-8.2c0.8-0.1,1.3-0.3,2-0.8l0,0c1.1-0.9,1.6-2.1,1.5-3.4c-0.2-1.7-1.6-3.3-3.4-3.4
                      c-2.3-0.2-4.2,1.5-4.2,3.8c0,0.9,0.2,1.5,0.8,2.2c0.2,0.3,0.1,0.8-0.1,1c-2.6,2.2-4.2,5.4-4.2,8.9c0,6.4,5.2,11.6,11.6,11.6
                      s11.6-5.2,11.6-11.6C14,289.3,12.5,286.1,9.8,284z M6.5,282.1L6.5,282.1L6.5,282.1c-0.3,0-0.6-0.1-0.8-0.2l0,0
                      c-0.3-0.2-0.5-0.6-0.5-1.1c0-0.9,0.8-1.4,1.5-1.2c0.5,0.1,1,0.5,1.1,1.1C8,281.3,7.3,282.1,6.5,282.1z M-1.6,279.6
                      c0.5,0.1,1,0.4,1.1,1s-0.1,1.1-0.5,1.3l0,0c-0.2,0.1-0.4,0.3-0.8,0.3l0,0l0,0l0,0c-0.5,0-1.1-0.3-1.2-0.9
                      C-3.3,280.3-2.5,279.4-1.6,279.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.south_node)"
                x="-13"
                y="269"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_south_node"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.south_node)
              "
              transform="matrix(1 0 0 1 -10 297.8924)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.south_node.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.south_node.line || 0
              }}
            </text>
            <text
              id="person_number_south_node"
              @click="
                openTooltipsDialog(
                  compoSite.chart.gatesPersonalityExt.south_node
                )
              "
              transform="matrix(1 0 0 1 65.9326 297.8924)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.south_node.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.south_node.line || 0
              }}
            </text>
          </g>
          <g id="g6895" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_north_node"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.north_node)
              "
              transform="matrix(1 0 0 1 -10 246.9872)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.north_node.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.north_node.line || 0
              }}
            </text>
            <g class="over-shadow-anim" transform="translate(45.056651,0)">
              <path
                class="st15"
                d="M14,237.5c0-6.4-5.2-11.6-11.6-11.6s-11.6,5.2-11.6,11.6c0,3.5,1.5,6.7,4.2,8.9c0.3,0.2,0.3,0.6,0.1,1
                      c-0.5,0.8-0.9,1.6-0.6,2.7c0.2,1.7,1.6,3.1,3.3,3.3c2.3,0.2,4.2-1.5,4.2-3.8c0-1.2-0.5-2.3-1.5-3l0,0c-0.5-0.4-1.2-0.8-2-0.8
                      c-3.3-1.4-5.3-4.7-5.3-8.2c0-5,4-9.1,9.1-9.1c5,0,9.1,4,9.1,9.1c0,3.5-2.1,6.7-5.3,8.2c-0.8,0.1-1.3,0.3-1.8,0.8l0,0
                      c-1.1,0.9-1.6,2.1-1.5,3.4c0.2,1.7,1.5,3.3,3.4,3.4c2.3,0.2,4.2-1.5,4.2-3.8c0-0.9-0.2-1.5-0.8-2.2c-0.2-0.3-0.1-0.8,0.1-1
                      C12.5,244.3,14,241,14,237.5z M-1.8,248.2L-1.8,248.2L-1.8,248.2c0.3,0,0.6,0.1,0.8,0.3l0,0c0.3,0.2,0.5,0.6,0.5,1.1
                      c0,0.9-0.8,1.4-1.5,1.2c-0.5-0.1-1-0.5-1.1-1.1C-3.1,249-2.5,248.2-1.8,248.2z M6.4,250.8c-0.5-0.1-1-0.4-1.1-1s0.1-1.1,0.5-1.3
                      l0,0c0.1-0.1,0.4-0.2,0.8-0.2l0,0l0,0c0.8,0,1.2,0.5,1.2,1.2S7.1,250.9,6.4,250.8z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.north_node)"
                x="-13"
                y="220"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_north_node"
              @click="
                openTooltipsDialog(
                  compoSite.chart.gatesPersonalityExt.north_node
                )
              "
              transform="matrix(1 0 0 1 65.9326 246.9872)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.north_node.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.north_node.line || 0
              }}
            </text>
          </g>
          <g id="g6891" transform="translate(23.809448,18.44752)">
            <g class="over-shadow-anim" transform="translate(45.056651,0)">
              <path
                id="design_sign_moon"
                inkscape:connector-curvature="0"
                class="st13"
                d="M-0.4,202.7c-1,0-1.7-0.1-2.7-0.3
                      c-1.7-0.3-3.3-1.1-4.9-2c-0.6-0.4-0.9-1.1-0.8-1.7c0.1-0.8,0.6-1.2,1.3-1.3c4.2-0.9,7.6-4,8.4-8.3c0.9-4.2-1-8.5-4.7-10.8
                      c-0.6-0.4-0.9-1.1-0.8-1.7c0.1-0.8,0.6-1.2,1.3-1.3c1.7-0.3,3.5-0.3,5.2,0c3.6,0.8,6.7,2.8,8.8,5.9s2.8,6.7,2.1,10.3
                      C11.9,198.2,6.1,202.7-0.4,202.7z M-0.5,197.9c-0.6,0.4-0.2,1.3,0.5,1.3c4.8-0.2,8.9-3.6,9.9-8.5s-1.6-9.7-6-11.6
                      c-0.8-0.3-1.3,0.4-1,1.1c1.5,2.8,2.2,6.1,1.4,9.5C3.8,193.1,1.9,196-0.5,197.9z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.moon)"
                x="-13"
                y="170"
                class="rectangle-over-planet cursor-pointer"
              ></rect>
            </g>
            <text
              id="design_number_moon"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.moon)"
              transform="matrix(1 0 0 1 -10 196.0716)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.moon.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.moon.line || 0
              }}
            </text>
            <text
              id="person_number_moon"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.moon)
              "
              transform="matrix(1 0 0 1 65.9326 196.0716)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.moon.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.moon.line || 0
              }}
            </text>
          </g>
          <g id="g6887" transform="translate(23.809448,18.44752)">
            <g
              id="design_sign_earth"
              transform="translate(70.056651,23.383064)"
              class="over-shadow-anim"
            >
              <path
                class="st13"
                d="M-20.8,101.3c-7.8-0.3-14.1,6-13.7,13.7c0.3,6.7,5.8,12.2,12.5,12.5c7.8,0.3,14.1-6,13.7-13.7
                          C-8.5,107.1-14.1,101.6-20.8,101.3z M-12.8,112.9h-6.1c-0.5,0-1.1-0.4-1.1-1.1v-6.1c0-0.8,0.6-1.1,1.3-1
                          c3.3,1,5.8,3.5,6.7,6.7C-11.6,112.3-12.1,112.9-12.8,112.9z M-23,105.8v6.1c0,0.5-0.4,1.1-1.1,1.1h-6.1c-0.8,0-1.1-0.6-1-1.3
                          c1-3.3,3.5-5.8,6.7-6.7C-23.6,104.6-23,105.2-23,105.8z M-30.1,116h6.1c0.5,0,1.1,0.4,1.1,1.1v6.1c0,0.8-0.6,1.1-1.3,1
                          c-3.3-1-5.8-3.5-6.7-6.7C-31.2,116.7-30.8,116-30.1,116z M-19.8,123.1V117c0-0.5,0.4-1.1,1.1-1.1h6.1c0.8,0,1.1,0.6,1,1.3
                          c-1,3.3-3.5,5.8-6.7,6.7C-19.3,124.3-19.8,123.8-19.8,123.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.earth)"
                x="-35"
                y="97"
                class="rectangle-over-planet cursor-pointer"
              ></rect>
            </g>
            <text
              id="design_number_earth"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.earth)"
              transform="matrix(1 0 0 1 -10 145.1561)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.earth.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.earth.line || 0
              }}
            </text>
            <text
              id="person_number_earth"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.earth)
              "
              transform="matrix(1 0 0 1 65.9326 145.1561)"
              class="st11 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.earth.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.earth.line || 0
              }}
            </text>
          </g>
          <g id="g6880" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_sun"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.sun)"
              inkscape:label=""
              transform="matrix(1 0 0 1 -10 94.2406)"
              class="st16 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.sun.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.sun.line || 0
              }}
            </text>
            <g
              id="person_sign_sun_00000114755085593653044530000008711818848336689320_"
              @click="openTooltipsDialog(compoSite.chart.planets.sun)"
              transform="translate(70.056651,23.28752)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13"
                d="M-21.8,77.4c-7.6,0-13.8-6.2-13.8-13.8s6.2-13.8,13.8-13.8S-8,56.1-8,63.6C-8,71.3-14,77.4-21.8,77.4z
                              M-21.8,52.9c-5.9,0-10.7,4.8-10.7,10.7s4.9,10.8,10.8,10.8s10.8-4.9,10.8-10.8C-11,57.7-15.7,52.9-21.8,52.9z"
              />
              <ellipse class="st13" cx="-21.8" cy="63.7" rx="7" ry="7" />
              <rect x="-35" y="43" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="person_number_sun"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.sun)
              "
              transform="matrix(1 0 0 1 65.9326 94.2406)"
              class="st16 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.sun.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.sun.line || 0
              }}
            </text>
          </g>
        </g>
        <g v-if="isChartTurnOn && !isChart2TurnOn" id="g7327_2">
          <g id="g6958" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_pluto2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.pluto)"
              transform="matrix(1 0 0 1 27.7143 705.2269)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.pluto.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.pluto.line || 0
              }}
            </text>
            <g
              @click="openTooltipsDialog(compoSite.chart.planets.pluto)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                id="person_sign_pluto_00000111882351693983901740000012805845954521327005_"
                inkscape:connector-curvature="0"
                class="st13_2"
                d="
                        M10,709.9H-3.4c-0.4,0.1-0.8-0.3-0.9-0.8c0-0.1,0-0.1,0-0.2l0.1-22.2c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3l9.1,0.1
                        c4.4,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9l-8.1,0.1v6.2h12.4c0.6,0,1,0.4,1,1C10.8,709.6,10.5,709.9,10,709.9L10,709.9z
                        M-2.4,687.7V700l8.1-0.1c3.3,0,6.1-2.7,6.1-6.1c0-3.4-2.7-6.1-6.1-6.1L-2.4,687.7L-2.4,687.7z"
              />
              <path
                class="st13_2"
                d="M-3.6,710.9c-0.4,0-0.7-0.1-1-0.3c-0.4-0.3-0.7-0.8-0.8-1.2v-0.3l0.1-22.2c0-0.5,0.2-1.1,0.6-1.3
                        c0.5-0.5,1-0.7,1.5-0.6l8.9,0.1c5,0,9,4,9,9s-4,9-9,9l-7.2,0.1v4.1H10c1.1,0,2,0.9,2,2v0.2c-0.2,1-1,1.6-2,1.6H-3.3
                        C-3.4,710.9-3.5,710.9-3.6,710.9z M-1.4,688.7V699l7.2-0.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1L-1.4,688.7z"
              />
              <rect x="-10" y="683" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6954"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer over-shadow-anim"
          >
            <text
              id="design_number_neptune2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.neptune)
              "
              transform="matrix(1 0 0 1 27.7143 654.3114)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.neptune.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.neptune.line || 0
              }}
            </text>
            <g
              id="person_sign_neptune_00000127761324374076008660000009390152632522840472_"
              @click="openTooltipsDialog(compoSite.chart.planets.neptune)"
              transform="translate(23.756655,22.292976)"
            >
              <path
                class="st14_2"
                d="M-8.1,613.5l-2.4-4.5c-0.2-0.4-0.6-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,2-1,3.7-2.4,5
                        c-0.9,0.8-2.1,0.1-2.1-1.1v-9.5h0.3c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.6-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9
                        h0.8v9.5c0,1.1-1.2,1.6-2.1,1.1c-1.4-1.1-2.4-2.9-2.4-5v-5.5h0.3c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.6-0.4-0.9,0
                        l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,4.6,3,8.4,7.1,9.8c0.8,0.2,1.2,1,1.2,1.6v2.4h-1.4c-1.1,0-2,0.9-2,2l0,0
                        c0,1.1,0.9,2,2,2h1.6v1.2c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-1.2h1.4c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-1.5v-2.4
                        c0-0.8,0.5-1.4,1.2-1.6c4.1-1.3,7.1-5.2,7.1-9.8v-5.5h0.4C-8,614.4-7.8,614-8.1,613.5z"
              />
              <rect x="-33" y="605" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6944"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer over-shadow-anim"
          >
            <text
              id="design_number_uranus2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.uranus)"
              transform="matrix(1 0 0 1 27.7143 603.3959)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.uranus.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.uranus.line || 0
              }}
            </text>
            <g
              id="person_sign_uranus_00000010281786881175848900000002314323419131634825_"
              @click="openTooltipsDialog(compoSite.chart.planets.uranus)"
              transform="translate(23.806655,21.642479)"
            >
              <path
                class="st14_2"
                d="M-9.7,568.7v-5H-8v-1.8c0-1-0.9-1.7-1.7-1.7l0,0l0,0h-2c-1,0-1.7,0.9-1.7,1.7v5.1h-4v-4.7
                        c0-1-0.9-1.7-1.7-1.7l0,0c-1,0-1.7,0.9-1.7,1.7v4.7h-4v-5.1c0-1-0.9-1.7-1.7-1.7h-2l0,0l0,0c-1,0-1.7,0.9-1.7,1.7v1.8h1.7v5
                        l0,0v0.1l0,0v5h-1.7v1.8c0,1,0.9,1.7,1.7,1.7h1.8l0,0h0.1c1,0,1.7-0.9,1.7-1.7v-5.1h4v8.7c-2.1,0.9-3.5,2.9-3.3,5.3
                        c0.3,2.3,2.2,4.1,4.5,4.5c3,0.3,5.7-2.1,5.7-5c0-2.2-1.3-4-3.3-4.8v-8.7h4v5.1c0,1,0.9,1.7,1.7,1.7h0.1l0,0h1.8
                        c1,0,1.7-0.9,1.7-1.7v-1.8h-1.7L-9.7,568.7L-9.7,568.7L-9.7,568.7L-9.7,568.7z M-19,585.3c-1.1,0.2-1.8-0.8-1.7-1.7
                        c0.1-0.6,0.6-1.1,1.2-1.2c1.1-0.2,1.8,0.8,1.7,1.7C-17.8,584.6-18.3,585.2-19,585.3z"
              />
              <rect x="-33" y="550" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6935"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer over-shadow-anim"
          >
            <text
              id="design_number_saturn2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.saturn)"
              transform="matrix(1 0 0 1 27.7143 552.366)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.saturn.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.saturn.line || 0
              }}
            </text>
            <g
              id="person_sign_saturn_00000023253300002657813530000005385324383920963458_"
              @click="openTooltipsDialog(compoSite.chart.planets.saturn)"
              transform="translate(23.857012,22.837349)"
            >
              <path
                class="st14_2"
                d="M-10.5,529.7c1.7-2.1,3-5.9,1.7-9.6c-1-2.7-3.3-4.6-6.3-5.3c-3.5-0.8-6.5,0.6-8.8,2.4
                        c-0.5,0.4-1.2,0-1.2-0.6v-3h1.5c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-1.5v-1.5c0-1.1-0.9-2-2-2l0,0c-1.1,0-2,0.9-2,2v1.5h-1.4
                        c-1.1,0-2,0.9-2,2l0,0c0,1.1,0.9,2,2,2h1.5v21.8c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-9.3c0-1.3,0.4-2.6,1.2-3.6
                        c1.6-2.1,4.6-4.8,7.9-4c1.8,0.4,3,1.3,3.5,2.7c0.8,2,0,4.5-1.1,5.8c-2.9,3.5-3.3,6.7-1.2,10.5c0.5,1.1,1.8,1.3,2.8,0.6l0,0
                        c0.9-0.5,1.1-1.6,0.6-2.5C-12.8,533.4-12.2,531.7-10.5,529.7z"
              />
              <rect x="-33" y="503" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g id="g6928" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_jupiter2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.jupiter)
              "
              transform="matrix(1 0 0 1 27.7143 501.5546)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.jupiter.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.jupiter.line || 0
              }}
            </text>
            <g
              id="person_sign_jupiter_00000141454809233405868060000004017440787168000685_"
              @click="openTooltipsDialog(compoSite.chart.planets.jupiter)"
              transform="translate(24.006651,22.491493)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-8.3,475.8h-5.3c-0.9,0-1.4-0.6-1.4-1.4v-13.8c0-1-0.8-1.6-1.6-1.6c-0.9,0-1.6,0.8-1.6,1.6v13.8
                        c0,0.9-0.6,1.4-1.4,1.4h-8.8c-0.8,0-1.1-0.9-0.5-1.3l3.6-3.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.8-0.8
                        c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.5-0.5c0.5-0.5,0.7-1.1,0.8-1.1c0.1-0.3,0.2-0.6,0.3-1c0.2-0.6,0.3-1.2,0.3-1.8
                        c0-0.1,0-0.2,0-0.2c0-4.3-3.6-7.9-7.9-7.9c-1.4,0-2.8,0.4-4,1.1c-1,0.5-1.1,1.8-0.3,2.6c0.5,0.5,1.3,0.6,2,0.3
                        c0.8-0.4,1.5-0.6,2.4-0.6c2.6,0,4.7,2.1,4.7,4.7c0,0.8-0.2,1.4-0.5,2.1l-2.2,2.3l-7.1,7.2v0.1c0,1.8,1.4,3.3,3.3,3.3h11.8
                        c0.9,0,1.4,0.6,1.4,1.4v4.7c0,1,0.8,1.6,1.6,1.6c0.9,0,1.6-0.8,1.6-1.6v-4.8c0-0.9,0.6-1.4,1.4-1.4H-8c1,0,1.6-0.8,1.6-1.6
                        C-6.5,476.5-7.3,475.8-8.3,475.8z"
              />
              <rect x="-33" y="450" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6922"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer"
          >
            <text
              id="design_number_mars2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.mars)"
              transform="matrix(1 0 0 1 27.7143 450.639)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.mars.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.mars.line || 0
              }}
            </text>
          </g>
          <g
            id="g6922.2"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer"
          >
            <g
              id="person_sign_mars_00000084499767858998168980000004527302232475698056_"
              @click="openTooltipsDialog(compoSite.chart.planets.mars)"
              transform="translate(23.740137,22.618215)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-8.5,407.3c-0.1-0.6-0.8-1.1-1.3-1.1l-5.7,0.8c-0.6,0.1-0.8,0.4-0.2,0.9l1.5,1.1
                        c0.3,0.3,0.4,0.8,0.2,1.1l-3.6,4.9c-0.6,0.9-1.7,1.2-2.8,1s-2.2-0.4-3.3-0.3l0,0l0,0c-2.9,0.2-5.5,1.6-7.3,3.9
                        c-3.3,4.3-2.4,10.4,2,13.7c1.6,1.2,3.7,2,5.8,2c0.5,0,1,0,1.4-0.1c2.6-0.4,4.9-1.7,6.4-3.8c1.7-2.3,2.4-5.2,1.6-7.9
                        c-0.2-1.1-0.6-2.1-1.2-3c-0.7-0.9-0.5-2.1,0.1-3l3.6-4.9c0.3-0.3,0.8-0.4,1.1-0.2l1.5,1.1c0.5,0.4,0.9,0.2,0.8-0.4L-8.5,407.3z
                        M-18.3,429.1c-1,1.3-2.4,2.2-4,2.4s-3.3-0.2-4.6-1.1c-2.7-2-3.3-5.9-1.2-8.6c1.1-1.4,2.7-2.3,4.6-2.5c1.4-0.1,2.9,0.3,4,1.2
                        c1.1,0.9,2,2.2,2.3,3.5C-16.9,425.9-17.2,427.7-18.3,429.1z"
              />
              <rect x="-33" y="400" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g id="g6915" transform="translate(23.809448,18.44752)">
            <g
              id="person_sign_venus_00000060711045547391192210000010132132755634219680_"
              @click="openTooltipsDialog(compoSite.chart.planets.venus)"
              transform="translate(23.806652,22.910158)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-12.1,364c0-2.7-1.2-5.3-3.3-7.2l0,0l0,0c-1.6-1.4-3.8-2.3-6.1-2.3c-2.2,0-4.3,0.9-6,2.3l0,0l0,0
                        c-2.1,1.7-3.3,4.3-3.3,7.2c0,4,2.6,7.5,6.1,8.8c1,0.3,1.5,1.1,1.5,2.2v2.6h-2c-1,0-1.6,0.8-1.6,1.6l0,0c0,1,0.8,1.6,1.6,1.6
                        h2.1v2.2c0,1,0.8,1.6,1.6,1.6l0,0c1,0,1.6-0.8,1.6-1.6v-2.2h2c1,0,1.6-0.8,1.6-1.6l0,0c0-1-0.8-1.6-1.6-1.6h-2V375
                        c0-1,0.6-1.8,1.5-2.2C-14.7,371.4-12.1,368-12.1,364z M-27.4,364c0-1.7,0.8-3.4,2.1-4.6l0,0c2.2-1.8,5.6-1.8,7.8,0l0,0
                        c1.3,1.1,2.1,2.8,2.1,4.6c0,3.3-2.7,6-6,6C-24.7,369.9-27.4,367.2-27.4,364z"
              />
              <rect x="-33" y="348" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="design_number_venus2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.venus)"
              transform="matrix(1 0 0 1 27.7143 399.7235)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.venus.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.venus.line || 0
              }}
            </text>
          </g>
          <g id="g6908" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_mercury2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.mercury)
              "
              transform="matrix(1 0 0 1 28.5244 348.8182)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.mercury.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.mercury.line || 0
              }}
            </text>
            <g
              id="person_sign_mercury_00000062913725719015586450000018236137589778915002_"
              @click="openTooltipsDialog(compoSite.chart.planets.mercury)"
              transform="translate(23.767386,22.999209)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-12.2,314.3c0-2.1-0.8-4.1-2.1-5.8c0.9-1.1,1.3-2.3,1.6-3.6c0.2-1.1-0.5-2-1.5-2l0,0
                        c-0.8,0-1.4,0.5-1.5,1.2c-0.2,0.8-0.5,1.4-1,2.1c-1.4-0.9-3-1.3-4.7-1.3s-3.3,0.4-4.7,1.3c-0.4-0.6-0.9-1.3-1-2.1
                        c-0.2-0.8-0.9-1.2-1.5-1.2l0,0c-1.1,0-1.7,1-1.5,2c0.3,1.3,0.9,2.5,1.6,3.6c-1.3,1.6-2.1,3.6-2.1,5.8c0,4.1,2.7,7.6,6.4,8.8
                        c0.6,0.2,1.1,0.8,1.1,1.3v2.9h-1.5c-1,0-1.7,0.9-1.7,1.7l0,0c0,1,0.9,1.7,1.7,1.7h1.5v1.7c0,1,0.9,1.7,1.7,1.7l0,0
                        c1,0,1.7-0.9,1.7-1.7v-1.6h1.5c1,0,1.7-0.9,1.7-1.7l0,0c0-1-0.9-1.7-1.7-1.7h-1.5v-2.9c0-0.6,0.4-1.2,1.1-1.3
                        C-14.9,321.9-12.2,318.4-12.2,314.3z M-27,315.1c-0.3-2,0.4-3.9,2-5.2l0,0c2.1-1.7,5.3-1.7,7.4,0l0,0l0,0c1.2,1.1,2,2.7,2,4.3
                        c0,3.1-2.6,5.7-5.7,5.7C-24.2,319.9-26.6,317.9-27,315.1z"
              />
              <rect x="-33" y="300" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g id="g6899" transform="translate(23.809448,18.44752)">
            <path
              class="st15_2"
              d="M9.8,284c-0.3-0.2-0.3-0.6-0.1-1c0.5-0.8,0.9-1.6,0.6-2.7c-0.2-1.7-1.6-3.1-3.3-3.3
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,1.2,0.5,2.3,1.5,3l0,0c0.5,0.4,1.2,0.6,1.8,0.8c3.3,1.4,5.3,4.7,5.3,8.2c0,5-4,9.1-9.1,9.1
                    c-5,0-9.1-4-9.1-9.1c0-3.5,2.1-6.7,5.3-8.2c0.8-0.1,1.3-0.3,2-0.8l0,0c1.1-0.9,1.6-2.1,1.5-3.4c-0.2-1.7-1.6-3.3-3.4-3.4
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,0.9,0.2,1.5,0.8,2.2c0.2,0.3,0.1,0.8-0.1,1c-2.6,2.2-4.2,5.4-4.2,8.9c0,6.4,5.2,11.6,11.6,11.6
                    s11.6-5.2,11.6-11.6C14,289.3,12.5,286.1,9.8,284z M6.5,282.1L6.5,282.1L6.5,282.1c-0.3,0-0.6-0.1-0.8-0.2l0,0
                    c-0.3-0.2-0.5-0.6-0.5-1.1c0-0.9,0.8-1.4,1.5-1.2c0.5,0.1,1,0.5,1.1,1.1C8,281.3,7.3,282.1,6.5,282.1z M-1.6,279.6
                    c0.5,0.1,1,0.4,1.1,1s-0.1,1.1-0.5,1.3l0,0c-0.2,0.1-0.4,0.3-0.8,0.3l0,0l0,0l0,0c-0.5,0-1.1-0.3-1.2-0.9
                    C-3.3,280.3-2.5,279.4-1.6,279.6z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart.planets.south_node)"
              x="-13"
              y="269"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="design_number_south_node2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.south_node)
              "
              transform="matrix(1 0 0 1 27.7143 297.8924)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.south_node.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.south_node.line || 0
              }}
            </text>
          </g>
          <g id="g6895" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_north_node2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesDesignExt.north_node)
              "
              transform="matrix(1 0 0 1 27.7143 246.9872)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.north_node.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.north_node.line || 0
              }}
            </text>
            <g class="over-shadow-anim">
              <path
                class="st15_2"
                d="M14,237.5c0-6.4-5.2-11.6-11.6-11.6s-11.6,5.2-11.6,11.6c0,3.5,1.5,6.7,4.2,8.9c0.3,0.2,0.3,0.6,0.1,1
                      c-0.5,0.8-0.9,1.6-0.6,2.7c0.2,1.7,1.6,3.1,3.3,3.3c2.3,0.2,4.2-1.5,4.2-3.8c0-1.2-0.5-2.3-1.5-3l0,0c-0.5-0.4-1.2-0.8-2-0.8
                      c-3.3-1.4-5.3-4.7-5.3-8.2c0-5,4-9.1,9.1-9.1c5,0,9.1,4,9.1,9.1c0,3.5-2.1,6.7-5.3,8.2c-0.8,0.1-1.3,0.3-1.8,0.8l0,0
                      c-1.1,0.9-1.6,2.1-1.5,3.4c0.2,1.7,1.5,3.3,3.4,3.4c2.3,0.2,4.2-1.5,4.2-3.8c0-0.9-0.2-1.5-0.8-2.2c-0.2-0.3-0.1-0.8,0.1-1
                      C12.5,244.3,14,241,14,237.5z M-1.8,248.2L-1.8,248.2L-1.8,248.2c0.3,0,0.6,0.1,0.8,0.3l0,0c0.3,0.2,0.5,0.6,0.5,1.1
                      c0,0.9-0.8,1.4-1.5,1.2c-0.5-0.1-1-0.5-1.1-1.1C-3.1,249-2.5,248.2-1.8,248.2z M6.4,250.8c-0.5-0.1-1-0.4-1.1-1s0.1-1.1,0.5-1.3
                      l0,0c0.1-0.1,0.4-0.2,0.8-0.2l0,0l0,0c0.8,0,1.2,0.5,1.2,1.2S7.1,250.9,6.4,250.8z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.north_node)"
                x="-13"
                y="220"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
          </g>
          <g id="g6891" transform="translate(23.809448,18.44752)">
            <g class="over-shadow-anim">
              <path
                id="design_sign_moon2"
                inkscape:connector-curvature="0"
                class="st13_2"
                d="M-0.4,202.7c-1,0-1.7-0.1-2.7-0.3
                      c-1.7-0.3-3.3-1.1-4.9-2c-0.6-0.4-0.9-1.1-0.8-1.7c0.1-0.8,0.6-1.2,1.3-1.3c4.2-0.9,7.6-4,8.4-8.3c0.9-4.2-1-8.5-4.7-10.8
                      c-0.6-0.4-0.9-1.1-0.8-1.7c0.1-0.8,0.6-1.2,1.3-1.3c1.7-0.3,3.5-0.3,5.2,0c3.6,0.8,6.7,2.8,8.8,5.9s2.8,6.7,2.1,10.3
                      C11.9,198.2,6.1,202.7-0.4,202.7z M-0.5,197.9c-0.6,0.4-0.2,1.3,0.5,1.3c4.8-0.2,8.9-3.6,9.9-8.5s-1.6-9.7-6-11.6
                      c-0.8-0.3-1.3,0.4-1,1.1c1.5,2.8,2.2,6.1,1.4,9.5C3.8,193.1,1.9,196-0.5,197.9z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.moon)"
                x="-13"
                y="170"
                class="rectangle-over-planet cursor-pointer"
              ></rect>
            </g>
            <text
              id="design_number_moon2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.moon)"
              transform="matrix(1 0 0 1 27.7143 196.0716)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.moon.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.moon.line || 0
              }}
            </text>
          </g>
          <g id="g6887" transform="translate(23.809448,18.44752)">
            <g
              id="person_sign_earth_00000119081958086116000100000013251159032665561773_"
              transform="translate(23.806655,23.383064)"
              class="over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-20.8,101.3c-7.8-0.3-14.1,6-13.7,13.7c0.3,6.7,5.8,12.2,12.5,12.5c7.8,0.3,14.1-6,13.7-13.7
                          C-8.5,107.1-14.1,101.6-20.8,101.3z M-12.8,112.9h-6.1c-0.5,0-1.1-0.4-1.1-1.1v-6.1c0-0.8,0.6-1.1,1.3-1
                          c3.3,1,5.8,3.5,6.7,6.7C-11.6,112.3-12.1,112.9-12.8,112.9z M-23,105.8v6.1c0,0.5-0.4,1.1-1.1,1.1h-6.1c-0.8,0-1.1-0.6-1-1.3
                          c1-3.3,3.5-5.8,6.7-6.7C-23.6,104.6-23,105.2-23,105.8z M-30.1,116h6.1c0.5,0,1.1,0.4,1.1,1.1v6.1c0,0.8-0.6,1.1-1.3,1
                          c-3.3-1-5.8-3.5-6.7-6.7C-31.2,116.7-30.8,116-30.1,116z M-19.8,123.1V117c0-0.5,0.4-1.1,1.1-1.1h6.1c0.8,0,1.1,0.6,1,1.3
                          c-1,3.3-3.5,5.8-6.7,6.7C-19.3,124.3-19.8,123.8-19.8,123.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.earth)"
                x="-35"
                y="97"
                class="rectangle-over-planet cursor-pointer"
              ></rect>
            </g>
            <text
              id="design_number_earth2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.earth)"
              transform="matrix(1 0 0 1 27.7143 145.1561)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.earth.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.earth.line || 0
              }}
            </text>
          </g>
          <g id="g6880" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_sun2"
              @click="openTooltipsDialog(compoSite.chart.gatesDesignExt.sun)"
              inkscape:label=""
              transform="matrix(1 0 0 1 25.9326 94.2406)"
              class="st16_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesDesignExt.sun.gate || 0 }}.{{
                compoSite.chart.gatesDesignExt.sun.line || 0
              }}
            </text>
            <g
              id="person_sign_sun_00000114755085593653044530000008711818848336689320_"
              @click="openTooltipsDialog(compoSite.chart.planets.sun)"
              transform="translate(24.056651,23.28752)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-21.8,77.4c-7.6,0-13.8-6.2-13.8-13.8s6.2-13.8,13.8-13.8S-8,56.1-8,63.6C-8,71.3-14,77.4-21.8,77.4z
                              M-21.8,52.9c-5.9,0-10.7,4.8-10.7,10.7s4.9,10.8,10.8,10.8s10.8-4.9,10.8-10.8C-11,57.7-15.7,52.9-21.8,52.9z"
              />
              <ellipse class="st13_2" cx="-21.8" cy="63.7" rx="7" ry="7" />
              <rect x="-35" y="43" class="rectangle-over-planet"></rect>
            </g>
          </g>
        </g>
        <g v-if="!isChartTurnOn && isChart2TurnOn" id="g7327_3">
          <g id="g6958" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_pluto3"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.pluto)"
              transform="matrix(1 0 0 1 27.7143 705.2269)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.pluto.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.pluto.line || 0
              }}
            </text>
            <g
              @click="openTooltipsDialog(compoSite.chart2.planets.pluto)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                id="person_sign_pluto_00000111882351693983901740000012805845954521327005_"
                inkscape:connector-curvature="0"
                class="st13_2"
                d="
                        M10,709.9H-3.4c-0.4,0.1-0.8-0.3-0.9-0.8c0-0.1,0-0.1,0-0.2l0.1-22.2c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3l9.1,0.1
                        c4.4,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9l-8.1,0.1v6.2h12.4c0.6,0,1,0.4,1,1C10.8,709.6,10.5,709.9,10,709.9L10,709.9z
                        M-2.4,687.7V700l8.1-0.1c3.3,0,6.1-2.7,6.1-6.1c0-3.4-2.7-6.1-6.1-6.1L-2.4,687.7L-2.4,687.7z"
              />
              <path
                class="st13_2"
                d="M-3.6,710.9c-0.4,0-0.7-0.1-1-0.3c-0.4-0.3-0.7-0.8-0.8-1.2v-0.3l0.1-22.2c0-0.5,0.2-1.1,0.6-1.3
                        c0.5-0.5,1-0.7,1.5-0.6l8.9,0.1c5,0,9,4,9,9s-4,9-9,9l-7.2,0.1v4.1H10c1.1,0,2,0.9,2,2v0.2c-0.2,1-1,1.6-2,1.6H-3.3
                        C-3.4,710.9-3.5,710.9-3.6,710.9z M-1.4,688.7V699l7.2-0.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1L-1.4,688.7z"
              />
              <rect x="-10" y="683" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6954"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer over-shadow-anim"
          >
            <text
              id="design_number_neptune3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.neptune)
              "
              transform="matrix(1 0 0 1 27.7143 654.3114)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.neptune.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.neptune.line || 0
              }}
            </text>
            <g
              id="person_sign_neptune_00000127761324374076008660000009390152632522840472_"
              @click="openTooltipsDialog(compoSite.chart2.planets.neptune)"
              transform="translate(23.756655,22.292976)"
            >
              <path
                class="st14_2"
                d="M-8.1,613.5l-2.4-4.5c-0.2-0.4-0.6-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,2-1,3.7-2.4,5
                        c-0.9,0.8-2.1,0.1-2.1-1.1v-9.5h0.3c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.6-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9
                        h0.8v9.5c0,1.1-1.2,1.6-2.1,1.1c-1.4-1.1-2.4-2.9-2.4-5v-5.5h0.3c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.6-0.4-0.9,0
                        l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,4.6,3,8.4,7.1,9.8c0.8,0.2,1.2,1,1.2,1.6v2.4h-1.4c-1.1,0-2,0.9-2,2l0,0
                        c0,1.1,0.9,2,2,2h1.6v1.2c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-1.2h1.4c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-1.5v-2.4
                        c0-0.8,0.5-1.4,1.2-1.6c4.1-1.3,7.1-5.2,7.1-9.8v-5.5h0.4C-8,614.4-7.8,614-8.1,613.5z"
              />
              <rect x="-33" y="605" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6944"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer over-shadow-anim"
          >
            <text
              id="design_number_uranus3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.uranus)
              "
              transform="matrix(1 0 0 1 27.7143 603.3959)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.uranus.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.uranus.line || 0
              }}
            </text>
            <g
              id="person_sign_uranus_00000010281786881175848900000002314323419131634825_"
              @click="openTooltipsDialog(compoSite.chart2.planets.uranus)"
              transform="translate(23.806655,21.642479)"
            >
              <path
                class="st14_2"
                d="M-9.7,568.7v-5H-8v-1.8c0-1-0.9-1.7-1.7-1.7l0,0l0,0h-2c-1,0-1.7,0.9-1.7,1.7v5.1h-4v-4.7
                        c0-1-0.9-1.7-1.7-1.7l0,0c-1,0-1.7,0.9-1.7,1.7v4.7h-4v-5.1c0-1-0.9-1.7-1.7-1.7h-2l0,0l0,0c-1,0-1.7,0.9-1.7,1.7v1.8h1.7v5
                        l0,0v0.1l0,0v5h-1.7v1.8c0,1,0.9,1.7,1.7,1.7h1.8l0,0h0.1c1,0,1.7-0.9,1.7-1.7v-5.1h4v8.7c-2.1,0.9-3.5,2.9-3.3,5.3
                        c0.3,2.3,2.2,4.1,4.5,4.5c3,0.3,5.7-2.1,5.7-5c0-2.2-1.3-4-3.3-4.8v-8.7h4v5.1c0,1,0.9,1.7,1.7,1.7h0.1l0,0h1.8
                        c1,0,1.7-0.9,1.7-1.7v-1.8h-1.7L-9.7,568.7L-9.7,568.7L-9.7,568.7L-9.7,568.7z M-19,585.3c-1.1,0.2-1.8-0.8-1.7-1.7
                        c0.1-0.6,0.6-1.1,1.2-1.2c1.1-0.2,1.8,0.8,1.7,1.7C-17.8,584.6-18.3,585.2-19,585.3z"
              />
              <rect x="-33" y="550" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6935"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer over-shadow-anim"
          >
            <text
              id="design_number_saturn3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.saturn)
              "
              transform="matrix(1 0 0 1 27.7143 552.366)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.saturn.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.saturn.line || 0
              }}
            </text>
            <g
              id="person_sign_saturn_00000023253300002657813530000005385324383920963458_"
              @click="openTooltipsDialog(compoSite.chart2.planets.saturn)"
              transform="translate(23.857012,22.837349)"
            >
              <path
                class="st14_2"
                d="M-10.5,529.7c1.7-2.1,3-5.9,1.7-9.6c-1-2.7-3.3-4.6-6.3-5.3c-3.5-0.8-6.5,0.6-8.8,2.4
                        c-0.5,0.4-1.2,0-1.2-0.6v-3h1.5c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-1.5v-1.5c0-1.1-0.9-2-2-2l0,0c-1.1,0-2,0.9-2,2v1.5h-1.4
                        c-1.1,0-2,0.9-2,2l0,0c0,1.1,0.9,2,2,2h1.5v21.8c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-9.3c0-1.3,0.4-2.6,1.2-3.6
                        c1.6-2.1,4.6-4.8,7.9-4c1.8,0.4,3,1.3,3.5,2.7c0.8,2,0,4.5-1.1,5.8c-2.9,3.5-3.3,6.7-1.2,10.5c0.5,1.1,1.8,1.3,2.8,0.6l0,0
                        c0.9-0.5,1.1-1.6,0.6-2.5C-12.8,533.4-12.2,531.7-10.5,529.7z"
              />
              <rect x="-33" y="503" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g id="g6928" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_jupiter3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.jupiter)
              "
              transform="matrix(1 0 0 1 27.7143 501.5546)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.jupiter.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.jupiter.line || 0
              }}
            </text>
            <g
              id="person_sign_jupiter_00000141454809233405868060000004017440787168000685_"
              @click="openTooltipsDialog(compoSite.chart2.planets.jupiter)"
              transform="translate(24.006651,22.491493)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-8.3,475.8h-5.3c-0.9,0-1.4-0.6-1.4-1.4v-13.8c0-1-0.8-1.6-1.6-1.6c-0.9,0-1.6,0.8-1.6,1.6v13.8
                        c0,0.9-0.6,1.4-1.4,1.4h-8.8c-0.8,0-1.1-0.9-0.5-1.3l3.6-3.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.8-0.8
                        c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.5-0.5c0.5-0.5,0.7-1.1,0.8-1.1c0.1-0.3,0.2-0.6,0.3-1c0.2-0.6,0.3-1.2,0.3-1.8
                        c0-0.1,0-0.2,0-0.2c0-4.3-3.6-7.9-7.9-7.9c-1.4,0-2.8,0.4-4,1.1c-1,0.5-1.1,1.8-0.3,2.6c0.5,0.5,1.3,0.6,2,0.3
                        c0.8-0.4,1.5-0.6,2.4-0.6c2.6,0,4.7,2.1,4.7,4.7c0,0.8-0.2,1.4-0.5,2.1l-2.2,2.3l-7.1,7.2v0.1c0,1.8,1.4,3.3,3.3,3.3h11.8
                        c0.9,0,1.4,0.6,1.4,1.4v4.7c0,1,0.8,1.6,1.6,1.6c0.9,0,1.6-0.8,1.6-1.6v-4.8c0-0.9,0.6-1.4,1.4-1.4H-8c1,0,1.6-0.8,1.6-1.6
                        C-6.5,476.5-7.3,475.8-8.3,475.8z"
              />
              <rect x="-33" y="450" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g
            id="g6922"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer"
          >
            <text
              id="design_number_mars3"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.mars)"
              transform="matrix(1 0 0 1 27.7143 450.639)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.mars.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.mars.line || 0
              }}
            </text>
          </g>
          <g
            id="g6922.2"
            transform="translate(23.809448,18.44752)"
            class="cursor-pointer"
          >
            <g
              id="person_sign_mars_00000084499767858998168980000004527302232475698056_"
              @click="openTooltipsDialog(compoSite.chart2.planets.mars)"
              transform="translate(23.740137,22.618215)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-8.5,407.3c-0.1-0.6-0.8-1.1-1.3-1.1l-5.7,0.8c-0.6,0.1-0.8,0.4-0.2,0.9l1.5,1.1
                        c0.3,0.3,0.4,0.8,0.2,1.1l-3.6,4.9c-0.6,0.9-1.7,1.2-2.8,1s-2.2-0.4-3.3-0.3l0,0l0,0c-2.9,0.2-5.5,1.6-7.3,3.9
                        c-3.3,4.3-2.4,10.4,2,13.7c1.6,1.2,3.7,2,5.8,2c0.5,0,1,0,1.4-0.1c2.6-0.4,4.9-1.7,6.4-3.8c1.7-2.3,2.4-5.2,1.6-7.9
                        c-0.2-1.1-0.6-2.1-1.2-3c-0.7-0.9-0.5-2.1,0.1-3l3.6-4.9c0.3-0.3,0.8-0.4,1.1-0.2l1.5,1.1c0.5,0.4,0.9,0.2,0.8-0.4L-8.5,407.3z
                        M-18.3,429.1c-1,1.3-2.4,2.2-4,2.4s-3.3-0.2-4.6-1.1c-2.7-2-3.3-5.9-1.2-8.6c1.1-1.4,2.7-2.3,4.6-2.5c1.4-0.1,2.9,0.3,4,1.2
                        c1.1,0.9,2,2.2,2.3,3.5C-16.9,425.9-17.2,427.7-18.3,429.1z"
              />
              <rect x="-33" y="400" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g id="g6915" transform="translate(23.809448,18.44752)">
            <g
              id="person_sign_venus_00000060711045547391192210000010132132755634219680_"
              @click="openTooltipsDialog(compoSite.chart2.planets.venus)"
              transform="translate(23.806652,22.910158)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-12.1,364c0-2.7-1.2-5.3-3.3-7.2l0,0l0,0c-1.6-1.4-3.8-2.3-6.1-2.3c-2.2,0-4.3,0.9-6,2.3l0,0l0,0
                        c-2.1,1.7-3.3,4.3-3.3,7.2c0,4,2.6,7.5,6.1,8.8c1,0.3,1.5,1.1,1.5,2.2v2.6h-2c-1,0-1.6,0.8-1.6,1.6l0,0c0,1,0.8,1.6,1.6,1.6
                        h2.1v2.2c0,1,0.8,1.6,1.6,1.6l0,0c1,0,1.6-0.8,1.6-1.6v-2.2h2c1,0,1.6-0.8,1.6-1.6l0,0c0-1-0.8-1.6-1.6-1.6h-2V375
                        c0-1,0.6-1.8,1.5-2.2C-14.7,371.4-12.1,368-12.1,364z M-27.4,364c0-1.7,0.8-3.4,2.1-4.6l0,0c2.2-1.8,5.6-1.8,7.8,0l0,0
                        c1.3,1.1,2.1,2.8,2.1,4.6c0,3.3-2.7,6-6,6C-24.7,369.9-27.4,367.2-27.4,364z"
              />
              <rect x="-33" y="348" class="rectangle-over-planet"></rect>
            </g>
            <text
              id="design_number_venus3"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.venus)"
              transform="matrix(1 0 0 1 27.7143 399.7235)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.venus.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.venus.line || 0
              }}
            </text>
          </g>
          <g id="g6908" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_mercury3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.mercury)
              "
              transform="matrix(1 0 0 1 28.5244 348.8182)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.mercury.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.mercury.line || 0
              }}
            </text>
            <g
              id="person_sign_mercury_00000062913725719015586450000018236137589778915002_"
              @click="openTooltipsDialog(compoSite.chart2.planets.mercury)"
              transform="translate(23.767386,22.999209)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-12.2,314.3c0-2.1-0.8-4.1-2.1-5.8c0.9-1.1,1.3-2.3,1.6-3.6c0.2-1.1-0.5-2-1.5-2l0,0
                        c-0.8,0-1.4,0.5-1.5,1.2c-0.2,0.8-0.5,1.4-1,2.1c-1.4-0.9-3-1.3-4.7-1.3s-3.3,0.4-4.7,1.3c-0.4-0.6-0.9-1.3-1-2.1
                        c-0.2-0.8-0.9-1.2-1.5-1.2l0,0c-1.1,0-1.7,1-1.5,2c0.3,1.3,0.9,2.5,1.6,3.6c-1.3,1.6-2.1,3.6-2.1,5.8c0,4.1,2.7,7.6,6.4,8.8
                        c0.6,0.2,1.1,0.8,1.1,1.3v2.9h-1.5c-1,0-1.7,0.9-1.7,1.7l0,0c0,1,0.9,1.7,1.7,1.7h1.5v1.7c0,1,0.9,1.7,1.7,1.7l0,0
                        c1,0,1.7-0.9,1.7-1.7v-1.6h1.5c1,0,1.7-0.9,1.7-1.7l0,0c0-1-0.9-1.7-1.7-1.7h-1.5v-2.9c0-0.6,0.4-1.2,1.1-1.3
                        C-14.9,321.9-12.2,318.4-12.2,314.3z M-27,315.1c-0.3-2,0.4-3.9,2-5.2l0,0c2.1-1.7,5.3-1.7,7.4,0l0,0l0,0c1.2,1.1,2,2.7,2,4.3
                        c0,3.1-2.6,5.7-5.7,5.7C-24.2,319.9-26.6,317.9-27,315.1z"
              />
              <rect x="-33" y="300" class="rectangle-over-planet"></rect>
            </g>
          </g>
          <g id="g6899" transform="translate(23.809448,18.44752)">
            <path
              class="st15_2"
              d="M9.8,284c-0.3-0.2-0.3-0.6-0.1-1c0.5-0.8,0.9-1.6,0.6-2.7c-0.2-1.7-1.6-3.1-3.3-3.3
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,1.2,0.5,2.3,1.5,3l0,0c0.5,0.4,1.2,0.6,1.8,0.8c3.3,1.4,5.3,4.7,5.3,8.2c0,5-4,9.1-9.1,9.1
                    c-5,0-9.1-4-9.1-9.1c0-3.5,2.1-6.7,5.3-8.2c0.8-0.1,1.3-0.3,2-0.8l0,0c1.1-0.9,1.6-2.1,1.5-3.4c-0.2-1.7-1.6-3.3-3.4-3.4
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,0.9,0.2,1.5,0.8,2.2c0.2,0.3,0.1,0.8-0.1,1c-2.6,2.2-4.2,5.4-4.2,8.9c0,6.4,5.2,11.6,11.6,11.6
                    s11.6-5.2,11.6-11.6C14,289.3,12.5,286.1,9.8,284z M6.5,282.1L6.5,282.1L6.5,282.1c-0.3,0-0.6-0.1-0.8-0.2l0,0
                    c-0.3-0.2-0.5-0.6-0.5-1.1c0-0.9,0.8-1.4,1.5-1.2c0.5,0.1,1,0.5,1.1,1.1C8,281.3,7.3,282.1,6.5,282.1z M-1.6,279.6
                    c0.5,0.1,1,0.4,1.1,1s-0.1,1.1-0.5,1.3l0,0c-0.2,0.1-0.4,0.3-0.8,0.3l0,0l0,0l0,0c-0.5,0-1.1-0.3-1.2-0.9
                    C-3.3,280.3-2.5,279.4-1.6,279.6z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart2.planets.south_node)"
              x="-13"
              y="269"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="design_number_south_node3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.south_node)
              "
              transform="matrix(1 0 0 1 27.7143 297.8924)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.south_node.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.south_node.line || 0
              }}
            </text>
          </g>
          <g id="g6895" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_north_node3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.north_node)
              "
              transform="matrix(1 0 0 1 27.7143 246.9872)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.north_node.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.north_node.line || 0
              }}
            </text>
            <g class="over-shadow-anim">
              <path
                class="st15_2"
                d="M14,237.5c0-6.4-5.2-11.6-11.6-11.6s-11.6,5.2-11.6,11.6c0,3.5,1.5,6.7,4.2,8.9c0.3,0.2,0.3,0.6,0.1,1
                      c-0.5,0.8-0.9,1.6-0.6,2.7c0.2,1.7,1.6,3.1,3.3,3.3c2.3,0.2,4.2-1.5,4.2-3.8c0-1.2-0.5-2.3-1.5-3l0,0c-0.5-0.4-1.2-0.8-2-0.8
                      c-3.3-1.4-5.3-4.7-5.3-8.2c0-5,4-9.1,9.1-9.1c5,0,9.1,4,9.1,9.1c0,3.5-2.1,6.7-5.3,8.2c-0.8,0.1-1.3,0.3-1.8,0.8l0,0
                      c-1.1,0.9-1.6,2.1-1.5,3.4c0.2,1.7,1.5,3.3,3.4,3.4c2.3,0.2,4.2-1.5,4.2-3.8c0-0.9-0.2-1.5-0.8-2.2c-0.2-0.3-0.1-0.8,0.1-1
                      C12.5,244.3,14,241,14,237.5z M-1.8,248.2L-1.8,248.2L-1.8,248.2c0.3,0,0.6,0.1,0.8,0.3l0,0c0.3,0.2,0.5,0.6,0.5,1.1
                      c0,0.9-0.8,1.4-1.5,1.2c-0.5-0.1-1-0.5-1.1-1.1C-3.1,249-2.5,248.2-1.8,248.2z M6.4,250.8c-0.5-0.1-1-0.4-1.1-1s0.1-1.1,0.5-1.3
                      l0,0c0.1-0.1,0.4-0.2,0.8-0.2l0,0l0,0c0.8,0,1.2,0.5,1.2,1.2S7.1,250.9,6.4,250.8z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.north_node)"
                x="-13"
                y="220"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
          </g>
          <g id="g6891" transform="translate(23.809448,18.44752)">
            <g class="over-shadow-anim">
              <path
                id="design_sign_moon3"
                inkscape:connector-curvature="0"
                class="st13_2"
                d="M-0.4,202.7c-1,0-1.7-0.1-2.7-0.3
                      c-1.7-0.3-3.3-1.1-4.9-2c-0.6-0.4-0.9-1.1-0.8-1.7c0.1-0.8,0.6-1.2,1.3-1.3c4.2-0.9,7.6-4,8.4-8.3c0.9-4.2-1-8.5-4.7-10.8
                      c-0.6-0.4-0.9-1.1-0.8-1.7c0.1-0.8,0.6-1.2,1.3-1.3c1.7-0.3,3.5-0.3,5.2,0c3.6,0.8,6.7,2.8,8.8,5.9s2.8,6.7,2.1,10.3
                      C11.9,198.2,6.1,202.7-0.4,202.7z M-0.5,197.9c-0.6,0.4-0.2,1.3,0.5,1.3c4.8-0.2,8.9-3.6,9.9-8.5s-1.6-9.7-6-11.6
                      c-0.8-0.3-1.3,0.4-1,1.1c1.5,2.8,2.2,6.1,1.4,9.5C3.8,193.1,1.9,196-0.5,197.9z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.moon)"
                x="-13"
                y="170"
                class="rectangle-over-planet cursor-pointer"
              ></rect>
            </g>
            <text
              id="design_number_moon3"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.moon)"
              transform="matrix(1 0 0 1 27.7143 196.0716)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.moon.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.moon.line || 0
              }}
            </text>
          </g>
          <g id="g6887" transform="translate(23.809448,18.44752)">
            <g
              id="person_sign_earth_00000119081958086116000100000013251159032665561773_"
              transform="translate(23.806655,23.383064)"
              class="over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-20.8,101.3c-7.8-0.3-14.1,6-13.7,13.7c0.3,6.7,5.8,12.2,12.5,12.5c7.8,0.3,14.1-6,13.7-13.7
                          C-8.5,107.1-14.1,101.6-20.8,101.3z M-12.8,112.9h-6.1c-0.5,0-1.1-0.4-1.1-1.1v-6.1c0-0.8,0.6-1.1,1.3-1
                          c3.3,1,5.8,3.5,6.7,6.7C-11.6,112.3-12.1,112.9-12.8,112.9z M-23,105.8v6.1c0,0.5-0.4,1.1-1.1,1.1h-6.1c-0.8,0-1.1-0.6-1-1.3
                          c1-3.3,3.5-5.8,6.7-6.7C-23.6,104.6-23,105.2-23,105.8z M-30.1,116h6.1c0.5,0,1.1,0.4,1.1,1.1v6.1c0,0.8-0.6,1.1-1.3,1
                          c-3.3-1-5.8-3.5-6.7-6.7C-31.2,116.7-30.8,116-30.1,116z M-19.8,123.1V117c0-0.5,0.4-1.1,1.1-1.1h6.1c0.8,0,1.1,0.6,1,1.3
                          c-1,3.3-3.5,5.8-6.7,6.7C-19.3,124.3-19.8,123.8-19.8,123.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.earth)"
                x="-35"
                y="97"
                class="rectangle-over-planet cursor-pointer"
              ></rect>
            </g>
            <text
              id="design_number_earth3"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.earth)"
              transform="matrix(1 0 0 1 27.7143 145.1561)"
              class="st11_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.earth.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.earth.line || 0
              }}
            </text>
          </g>
          <g id="g6880" transform="translate(23.809448,18.44752)">
            <text
              id="design_number_sun3"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.sun)"
              inkscape:label=""
              transform="matrix(1 0 0 1 25.9326 94.2406)"
              class="st16_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.sun.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.sun.line || 0
              }}
            </text>
            <g
              id="person_sign_sun_00000114755085593653044530000008711818848336689320_"
              @click="openTooltipsDialog(compoSite.chart2.planets.sun)"
              transform="translate(24.056651,23.28752)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st13_2"
                d="M-21.8,77.4c-7.6,0-13.8-6.2-13.8-13.8s6.2-13.8,13.8-13.8S-8,56.1-8,63.6C-8,71.3-14,77.4-21.8,77.4z
                              M-21.8,52.9c-5.9,0-10.7,4.8-10.7,10.7s4.9,10.8,10.8,10.8s10.8-4.9,10.8-10.8C-11,57.7-15.7,52.9-21.8,52.9z"
              />
              <ellipse class="st13_2" cx="-21.8" cy="63.7" rx="7" ry="7" />
              <rect x="-35" y="43" class="rectangle-over-planet"></rect>
            </g>
          </g>
        </g>
      </g>
      <g id="g8416" transform="translate(10,2.9624634)">
        <g v-if="!isChartTurnOn && !isChart2TurnOn" id="g7404">
          <g id="g7331">
            <g class="cursor-pointer over-shadow-anim">
              <path
                id="sign_pluto_chart2"
                inkscape:connector-curvature="0"
                transform="translate(-33,0)"
                class="st19"
                d="M730.4,728.6h-13.3c-0.4,0.1-0.8-0.3-0.9-0.8
                      c0-0.1,0-0.1,0-0.2l0.1-22.2c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3l9.1,0.1c4.4,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9l-8.2,0.1
                      v6.2h12.4c0.6,0,1,0.4,1,1C731.4,728.3,730.9,728.6,730.4,728.6L730.4,728.6z M718.1,706.4v12.3l8.2-0.1c3.3,0,6.1-2.8,6.1-6.1
                      s-2.8-6.1-6.1-6.1L718.1,706.4L718.1,706.4z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.pluto)"
                x="675"
                y="700"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_pluto_chart2"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.pluto)"
              transform="matrix(1 0 0 1 629.5911 723.4877)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.pluto.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.pluto.line || 0
              }}
            </text>
            <text
              id="person_number_pluto_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.pluto)
              "
              transform="matrix(1 0 0 1 707.5911 723.4877)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.pluto.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.pluto.line || 0
              }}
            </text>
          </g>
          <g id="g7167">
            <g
              id="sign_neptune_chart2"
              transform="translate(-13,22.292976)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st20"
                d="M714.7,632.1l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,2-1,3.7-2.4,5
                        c-0.9,0.8-2.1,0.1-2.1-1v-9.4h0.4c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4
                        v9.4c0,1-1.2,1.6-2.1,1c-1.4-1.1-2.4-2.9-2.4-5V633h0.4c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6
                        c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,4.6,3,8.4,7.1,9.7c0.8,0.2,1.2,1,1.2,1.6v2.4H700c-1,0-2,0.9-2,2l0,0c0,1,0.9,2,2,2h1.4v1.2
                        c0,1,0.9,2,2,2l0,0c1,0,2-0.9,2-2v-1.3h1.4c1,0,2-0.9,2-2l0,0c0-1-0.9-2-2-2h-1.4v-2.4c0-0.8,0.5-1.4,1.2-1.6
                        c4.1-1.3,7.1-5.1,7.1-9.7v-5.5h0.4C714.7,632.8,714.9,632.5,714.7,632.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite2.chart.planets.neptune)"
                x="690"
                y="620"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_neptune_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.neptune)
              "
              transform="matrix(1 0 0 1 629.5911 672.5722)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.neptune.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.neptune.line || 0
              }}
            </text>
            <text
              id="person_number_neptune_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.neptune)
              "
              transform="matrix(1 0 0 1 707.5911 672.5722)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.neptune.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.neptune.line || 0
              }}
            </text>
          </g>
          <g id="g7157">
            <g
              id="sign_uranus_chart2"
              transform="translate(-13,21.642479)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st20"
                d="M713,587.2v-5h1.7v-1.9c0-1-0.9-1.7-1.7-1.7l0,0l0,0h-1.9c-1,0-1.7,0.9-1.7,1.7v5.1h-4v-4.7
                        c0-1-0.9-1.7-1.7-1.7l0,0c-1,0-1.7,0.9-1.7,1.7v4.7h-4v-5.1c0-1-0.9-1.7-1.7-1.7h-1.9l0,0l0,0c-1,0-1.7,0.9-1.7,1.7v1.9h1.7v5
                        l0,0v0.1l0,0v5h-1.7v1.9c0,1,0.9,1.7,1.7,1.7h1.9l0,0h0.1c1,0,1.7-0.9,1.7-1.7v-5.1h4v8.7c-2.1,0.9-3.5,2.9-3.2,5.2
                        s2.2,4.1,4.5,4.5c3,0.3,5.7-2.1,5.7-5c0-2.2-1.3-4-3.3-4.8V589h4v5.1c0,1,0.9,1.7,1.7,1.7h0.1l0,0h1.9c1,0,1.7-0.9,1.7-1.7
                        v-1.9h-1.7L713,587.2L713,587.2L713,587.2L713,587.2z M703.7,603.8c-1,0.2-1.9-0.8-1.7-1.7c0.1-0.7,0.7-1.1,1.2-1.2
                        c1-0.2,1.9,0.8,1.7,1.7C704.9,603.3,704.3,603.8,703.7,603.8z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.uranus)"
                x="690"
                y="570"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_uranus_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.uranus)
              "
              transform="matrix(1 0 0 1 629.5911 621.6562)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.uranus.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.uranus.line || 0
              }}
            </text>
            <text
              id="person_number_uranus_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.uranus)
              "
              transform="matrix(1 0 0 1 707.5911 621.6562)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.uranus.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.uranus.line || 0
              }}
            </text>
          </g>
          <g id="g7148">
            <g
              id="sign_saturn_chart2"
              transform="translate(-13,22.837349)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st20"
                d="M713.3,548.1c1.7-2.1,3-5.9,1.7-9.5c-1-2.7-3.2-4.6-6.3-5.2c-3.5-0.8-6.5,0.7-8.8,2.4
                        c-0.5,0.4-1.2,0-1.2-0.7v-3h1.5c1,0,2-0.9,2-2l0,0c0-1-0.9-2-2-2h-1.5v-1.4c0-1-0.9-2-2-2l0,0c-1,0-2,0.9-2,2v1.5h-1.5
                        c-1,0-2,0.9-2,2l0,0c0,1,0.9,2,2,2h1.5V554c0,1,0.9,2,2,2l0,0c1,0,2-0.9,2-2v-9.3c0-1.3,0.4-2.6,1.2-3.6c1.6-2.1,4.6-4.8,8-4
                        c1.9,0.4,3,1.3,3.5,2.7c0.8,2,0,4.5-1,5.8c-2.9,3.5-3.3,6.8-1.2,10.6c0.5,1,1.9,1.3,2.8,0.7l0,0c0.9-0.5,1-1.6,0.7-2.5
                        C711,551.7,711.6,550.1,713.3,548.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.saturn)"
                x="690"
                y="520"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_saturn_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.saturn)
              "
              transform="matrix(1 0 0 1 629.5911 570.6269)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.saturn.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.saturn.line || 0
              }}
            </text>
            <text
              id="person_number_saturn_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.saturn)
              "
              transform="matrix(1 0 0 1 707.5911 570.6269)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.saturn.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.saturn.line || 0
              }}
            </text>
          </g>
          <g id="g7141">
            <g
              id="sign_jupiter_chart2"
              transform="translate(-12,22.491493)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st21"
                d="M715.6,494.5h-5.2c-0.9,0-1.4-0.7-1.4-1.4v-13.7c0-1-0.8-1.6-1.6-1.6c-1,0-1.6,0.8-1.6,1.6V493
                        c0,0.9-0.7,1.4-1.4,1.4h-9c-0.8,0-1-0.9-0.5-1.3l3.6-3.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.8-0.8
                        c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.5-0.5s0.2-0.3,0.4-0.4c0.3-0.5,0.3-0.7,0.4-0.8c0.1-0.3,0.2-0.7,0.3-1
                        c0.2-0.7,0.3-1.2,0.3-1.9c0-0.1,0-0.2,0-0.2c0-4.4-3.6-8-8-8c-1.4,0-2.8,0.4-4,1c-1,0.5-1,1.9-0.3,2.6c0.5,0.5,1.3,0.7,2,0.3
                        c0.8-0.4,1.5-0.7,2.4-0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.8-0.2,1.4-0.5,2.1l-2.2,2.3l-7.1,7.2v0.1c0,1.9,1.4,3.3,3.3,3.3h11.8
                        c0.9,0,1.4,0.7,1.4,1.4v4.7c0,1,0.8,1.6,1.6,1.6c1,0,1.6-0.8,1.6-1.6v-4.7c0-0.9,0.7-1.4,1.4-1.4h5.2c1,0,1.6-0.8,1.6-1.6
                        C717.3,495.4,716.6,494.5,715.6,494.5z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.jupiter)"
                x="690"
                y="470"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_jupiter_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.jupiter)
              "
              transform="matrix(1 0 0 1 629.5911 519.8149)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.jupiter.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.jupiter.line || 0
              }}
            </text>
            <text
              id="person_number_jupiter_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.jupiter)
              "
              transform="matrix(1 0 0 1 707.5911 519.8149)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.jupiter.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.jupiter.line || 0
              }}
            </text>
          </g>
          <g id="g7135">
            <g
              id="sign_mars_chart2"
              transform="translate(-10,22.618215)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st21"
                d="M713.2,425.8c-0.1-0.7-0.8-1-1.3-1l-5.7,0.8c-0.7,0.1-0.8,0.4-0.2,0.9l1.5,1.1c0.3,0.3,0.4,0.8,0.2,1.1
                        l-3.6,5c-0.7,0.9-1.7,1.2-2.8,1c-1-0.3-2.2-0.4-3.2-0.3l0,0l0,0c-2.9,0.2-5.5,1.6-7.2,3.9c-3.2,4.4-2.4,10.5,2,13.6
                        c1.6,1.2,3.7,2,5.8,2c0.5,0,1,0,1.4-0.1c2.6-0.4,4.9-1.7,6.4-3.8c1.7-2.3,2.4-5.1,1.6-8c-0.2-1-0.7-2.1-1.2-3
                        c-0.6-0.9-0.5-2.1,0.1-3l3.6-4.9c0.3-0.3,0.8-0.4,1.1-0.2l1.5,1.1c0.5,0.4,0.9,0.2,0.8-0.4L713.2,425.8z M703.6,447.6
                        c-1,1.3-2.4,2.2-4,2.4c-1.6,0.2-3.2-0.2-4.6-1.1c-2.7-2-3.2-5.9-1.2-8.6c1-1.4,2.7-2.3,4.6-2.5c1.4-0.1,2.9,0.3,4,1.2
                        c1.1,0.9,2,2.2,2.3,3.5C705,444.4,704.6,446.2,703.6,447.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.mars)"
                x="690"
                y="420"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_mars_chart2"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.mars)"
              transform="matrix(1 0 0 1 629.5911 468.8998)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.mars.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.mars.line || 0
              }}
            </text>
            <text
              id="person_number_mars_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.mars)
              "
              transform="matrix(1 0 0 1 707.5911 468.8998)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.mars.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.mars.line || 0
              }}
            </text>
          </g>
          <g id="g7128">
            <g
              id="sign_venus_chart2"
              transform="translate(-10,22.910158)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st21"
                d="M710.8,382.5c0-2.7-1.2-5.2-3.3-7.2l0,0l0,0c-1.6-1.4-3.8-2.3-6.1-2.3s-4.4,0.9-6.1,2.3l0,0l0,0
                        c-2.1,1.7-3.3,4.4-3.3,7.2c0,4,2.6,7.4,6.1,8.8c1,0.3,1.5,1.1,1.5,2.2v2.6h-1.7c-1,0-1.6,0.8-1.6,1.6l0,0c0,1,0.8,1.6,1.6,1.6
                        h2v2.2c0,1,0.8,1.6,1.6,1.6l0,0c1,0,1.6-0.8,1.6-1.6v-2.2h2c1,0,1.6-0.8,1.6-1.6l0,0c0-1-0.8-1.6-1.6-1.6h-2v-2.6
                        c0-1,0.7-1.9,1.5-2.2C708.2,389.9,710.8,386.5,710.8,382.5z M695.4,382.5c0-1.7,0.8-3.4,2.1-4.6l0,0c2.2-1.9,5.6-1.9,7.7,0l0,0
                        c1.3,1.1,2.1,2.8,2.1,4.6c0,3.3-2.7,6-6,6S695.4,385.7,695.4,382.5z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.venus)"
                x="690"
                y="370"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_venus_chart2"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.venus)"
              transform="matrix(1 0 0 1 629.5911 417.9843)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.venus.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.venus.line || 0
              }}
            </text>
            <text
              id="person_number_venus_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.venus)
              "
              transform="matrix(1 0 0 1 707.5911 417.9843)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.venus.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.venus.line || 0
              }}
            </text>
          </g>
          <g id="g7121">
            <g
              id="sign_mercury_chart2"
              transform="translate(-10,22.999209)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st21"
                d="M710.6,332.8c0-2.1-0.8-4.1-2.1-5.8c0.9-1,1.3-2.3,1.6-3.6c0.2-1-0.5-2-1.5-2l0,0c-0.8,0-1.4,0.5-1.5,1.2
                        c-0.2,0.8-0.5,1.4-1,2.1c-1.4-0.9-3-1.3-4.7-1.3s-3.3,0.4-4.7,1.3c-0.4-0.7-0.9-1.3-1-2.1c-0.2-0.8-0.9-1.2-1.5-1.2l0,0
                        c-1,0-1.7,1-1.5,2c0.3,1.3,0.9,2.5,1.6,3.6c-1.3,1.6-2.1,3.6-2.1,5.8c0,4.1,2.7,7.5,6.4,8.8c0.7,0.2,1,0.8,1,1.3v2.9h-1.5
                        c-1,0-1.7,0.9-1.7,1.7l0,0c0,1,0.9,1.7,1.7,1.7h1.5v1.7c0,1,0.9,1.7,1.7,1.7l0,0c1,0,1.7-0.9,1.7-1.7v-1.7h1.5
                        c1,0,1.7-0.9,1.7-1.7l0,0c0-1-0.9-1.7-1.7-1.7H703v-2.9c0-0.7,0.4-1.2,1-1.3C707.8,340.3,710.6,336.9,710.6,332.8z
                        M695.7,333.7c-0.3-2,0.4-3.9,2-5.1l0,0c2.1-1.7,5.2-1.7,7.3,0l0,0l0,0c1.2,1,2,2.7,2,4.4c0,3.1-2.6,5.7-5.7,5.7
                        C698.6,338.4,696.2,336.3,695.7,333.7z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.mercury)"
                x="690"
                y="315"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_mercury_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.mercury)
              "
              transform="matrix(1 0 0 1 629.5911 367.079)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.mercury.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.mercury.line || 0
              }}
            </text>
            <text
              id="person_number_mercury_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.mercury)
              "
              transform="matrix(1 0 0 1 707.5911 367.079)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.mercury.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.mercury.line || 0
              }}
            </text>
          </g>
          <g id="g7112">
            <g
              transform="translate(-35,0)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st22"
                d="M732.6,302.4c-0.3-0.2-0.3-0.7-0.1-1c0.5-0.8,0.9-1.6,0.7-2.7c-0.2-1.7-1.6-3.1-3.3-3.3
                      c-2.3-0.2-4.2,1.5-4.2,3.8c0,1.2,0.5,2.3,1.5,3l0,0c0.5,0.4,1.2,0.7,1.9,0.8c3.2,1.4,5.2,4.7,5.2,8.2c0,5-4,9.1-9.1,9.1
                      c-5,0-9.1-4-9.1-9.1c0-3.5,2.1-6.8,5.2-8.2c0.8-0.1,1.3-0.3,2-0.8l0,0c1-0.9,1.6-2.1,1.5-3.4c-0.2-1.7-1.6-3.2-3.4-3.4
                      c-2.3-0.2-4.2,1.5-4.2,3.8c0,0.9,0.2,1.5,0.8,2.2c0.2,0.3,0.1,0.8-0.1,1c-2.6,2.2-4.2,5.3-4.2,8.9c0,6.4,5.1,11.6,11.6,11.6
                      c6.4,0,11.6-5.1,11.6-11.6C736.8,307.8,735.2,304.6,732.6,302.4z M729.3,300.6L729.3,300.6L729.3,300.6c-0.3,0-0.7-0.1-0.8-0.2
                      l0,0c-0.3-0.2-0.5-0.7-0.5-1c0-0.9,0.8-1.4,1.5-1.2c0.5,0.1,1,0.5,1,1C730.8,299.8,730.1,300.6,729.3,300.6z M721.2,298.1
                      c0.5,0.1,1,0.4,1,1c0.1,0.5-0.1,1-0.5,1.3l0,0c-0.2,0.1-0.4,0.3-0.8,0.3l0,0l0,0l0,0c-0.5,0-1-0.3-1.2-0.9
                      C719.5,298.8,720.3,297.9,721.2,298.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.south_node)"
                x="710"
                y="285"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_south_node_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.south_node)
              "
              transform="matrix(1 0 0 1 629.5911 316.1533)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.south_node.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.south_node.line || 0
              }}
            </text>
            <text
              id="person_number_south_node_chart2"
              @click="
                openTooltipsDialog(
                  compoSite.chart2.gatesPersonalityExt.south_node
                )
              "
              transform="matrix(1 0 0 1 707.5911 316.1533)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.south_node.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.south_node.line || 0
              }}
            </text>
          </g>
          <g id="g7108">
            <g
              transform="translate(-35,0)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st22"
                d="M736.8,256c0-6.4-5.1-11.6-11.6-11.6c-6.4,0-11.6,5.1-11.6,11.6c0,3.5,1.5,6.8,4.2,8.9
                      c0.3,0.2,0.3,0.7,0.1,1c-0.5,0.8-0.9,1.6-0.7,2.7c0.2,1.7,1.6,3.1,3.3,3.3c2.3,0.2,4.2-1.5,4.2-3.8c0-1.2-0.5-2.3-1.5-3l0,0
                      c-0.5-0.4-1.2-0.8-2-0.8c-3.2-1.4-5.2-4.7-5.2-8.2c0-5,4-9.1,9.1-9.1c5,0,9.1,4,9.1,9.1c0,3.5-2.1,6.8-5.2,8.2
                      c-0.8,0.1-1.3,0.3-1.9,0.8l0,0c-1,0.9-1.6,2.1-1.5,3.4c0.2,1.7,1.5,3.2,3.4,3.4c2.3,0.2,4.2-1.5,4.2-3.8c0-0.9-0.2-1.5-0.8-2.2
                      c-0.2-0.3-0.1-0.8,0.1-1C735.2,262.8,736.8,259.5,736.8,256z M721.1,266.8L721.1,266.8L721.1,266.8c0.3,0,0.7,0.1,0.8,0.3l0,0
                      c0.3,0.2,0.5,0.7,0.5,1c0,0.9-0.8,1.4-1.5,1.2c-0.5-0.1-1-0.5-1-1C719.6,267.5,720.3,266.8,721.1,266.8z M729.1,269.2
                      c-0.5-0.1-1-0.4-1-1c-0.1-0.6,0.1-1,0.5-1.3l0,0c0.1-0.1,0.4-0.2,0.8-0.2l0,0l0,0c0.8,0,1.2,0.5,1.2,1.2
                      C730.5,268.9,729.9,269.3,729.1,269.2z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.north_node)"
                x="710"
                y="238"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_north_node_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesDesignExt.north_node)
              "
              transform="matrix(1 0 0 1 629.5911 265.248)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.north_node.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.north_node.line || 0
              }}
            </text>
            <text
              id="person_number_north_node_chart2"
              @click="
                openTooltipsDialog(
                  compoSite.chart2.gatesPersonalityExt.north_node
                )
              "
              transform="matrix(1 0 0 1 707.5911 265.248)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.north_node.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.north_node.line || 0
              }}
            </text>
          </g>
          <g id="g7104">
            <g
              transform="translate(-35,0)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                id="sign_moon_chart2"
                inkscape:connector-curvature="0"
                class="st21"
                d="M722.4,221c-1,0-1.7-0.1-2.7-0.3
                      c-1.7-0.3-3.3-1-4.9-2c-0.7-0.4-0.9-1-0.8-1.7c0.1-0.8,0.7-1.2,1.3-1.3c4.2-0.9,7.5-4,8.4-8.3c0.9-4.2-1-8.5-4.7-10.8
                      c-0.7-0.4-0.9-1-0.8-1.7c0.1-0.7,0.7-1.2,1.3-1.3c1.7-0.3,3.5-0.3,5.1,0c3.6,0.8,6.8,2.8,8.8,5.9c2.1,3.1,2.8,6.8,2.1,10.4
                      C734.7,216.6,728.9,221,722.4,221z M722.3,216.3c-0.7,0.4-0.2,1.3,0.5,1.3c4.8-0.2,8.9-3.6,9.8-8.5c1-4.9-1.6-9.6-6-11.6
                      c-0.8-0.3-1.3,0.4-1,1c1.5,2.8,2.2,6.1,1.4,9.4C726.5,211.4,724.8,214.4,722.3,216.3z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.moon)"
                x="710"
                y="190"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_moon_chart2"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.moon)"
              transform="matrix(1 0 0 1 629.5911 214.3325)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.moon.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.moon.line || 0
              }}
            </text>
            <text
              id="person_number_moon_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.moon)
              "
              transform="matrix(1 0 0 1 707.5911 214.3325)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.moon.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.moon.line || 0
              }}
            </text>
          </g>
          <g id="g7100">
            <g
              id="sign_earth_chart2"
              transform="translate(-13,23.383064)"
              class="cursor-pointer over-shadow-anim"
            >
              <path
                class="st21"
                d="M702,119.8c-7.7-0.3-14.1,6-13.6,13.6c0.3,6.8,5.8,12.2,12.5,12.5c7.7,0.3,14.1-6,13.6-13.6
                        C714.2,125.6,708.7,120.1,702,119.8z M710,131.4h-6.1c-0.5,0-1-0.4-1-1v-6.1c0-0.8,0.7-1.1,1.3-1c3.2,1,5.8,3.5,6.8,6.8
                        C711.2,130.7,710.7,131.4,710,131.4z M699.8,124.3v6.1c0,0.5-0.4,1-1,1h-6.1c-0.8,0-1.1-0.7-1-1.3c1-3.2,3.5-5.8,6.8-6.8
                        C699.2,123.2,699.8,123.6,699.8,124.3z M692.7,134.4h6.1c0.5,0,1,0.4,1,1v6.1c0,0.8-0.7,1.1-1.3,1c-3.2-1-5.8-3.5-6.8-6.8
                        C691.5,135.2,692,134.4,692.7,134.4z M702.9,141.6v-6.1c0-0.5,0.4-1,1-1h6.1c0.8,0,1.1,0.7,1,1.3c-1,3.2-3.5,5.8-6.8,6.8
                        C703.5,142.7,702.9,142.2,702.9,141.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.earth)"
                x="688"
                y="113"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="design_number_earth_chart2"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.earth)"
              transform="matrix(1 0 0 1 629.5911 163.4169)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.earth.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.earth.line || 0
              }}
            </text>
            <text
              id="person_number_earth_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.earth)
              "
              transform="matrix(1 0 0 1 707.5911 163.4169)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.earth.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.earth.line || 0
              }}
            </text>
          </g>
          <g id="g7093">
            <text
              id="design_number_sun_chart2"
              @click="openTooltipsDialog(compoSite.chart2.gatesDesignExt.sun)"
              transform="matrix(1 0 0 1 629.5911 112.5014)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesDesignExt.sun.gate || 0 }}.{{
                compoSite.chart2.gatesDesignExt.sun.line || 0
              }}
            </text>
            <g
              id="sign_sun_chart2"
              transform="translate(24.056651,23.28752)"
              class="planet-block-123"
            >
              <g
                transform="translate(-36,0)"
                class="cursor-pointer over-shadow-anim"
              >
                <g>
                  <path
                    class="st21"
                    d="M701.1,95.8c-7.5,0-13.7-6.2-13.7-13.7s6.2-13.7,13.7-13.7s13.7,6.2,13.7,13.7
                                C714.8,89.7,708.7,95.8,701.1,95.8z M701.1,71.4c-5.9,0-10.8,4.9-10.8,10.8S695.2,93,701.1,93c5.9,0,10.8-4.9,10.8-10.8
                                C711.8,76.1,707.1,71.4,701.1,71.4z"
                  />
                </g>
                <g>
                  <ellipse class="st23" cx="701.3" cy="82.2" rx="7" ry="7" />
                </g>
              </g>
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.sun)"
                x="688"
                y="65"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_sun_chart2"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.sun)
              "
              transform="matrix(1 0 0 1 707.5911 112.5014)"
              class="st10 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.sun.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.sun.line || 0
              }}
            </text>
          </g>
        </g>
        <g v-if="isChartTurnOn && !isChart2TurnOn" id="g7404_2">
          <g id="g7331_2">
            <path
              id="person_sign_pluto2"
              inkscape:connector-curvature="0"
              class="st19_2"
              d="M730.4,728.6h-13.3c-0.4,0.1-0.8-0.3-0.9-0.8
                    c0-0.1,0-0.1,0-0.2l0.1-22.2c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3l9.1,0.1c4.4,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9l-8.2,0.1
                    v6.2h12.4c0.6,0,1,0.4,1,1C731.4,728.3,730.9,728.6,730.4,728.6L730.4,728.6z M718.1,706.4v12.3l8.2-0.1c3.3,0,6.1-2.8,6.1-6.1
                    s-2.8-6.1-6.1-6.1L718.1,706.4L718.1,706.4z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart.planets.pluto)"
              x="713"
              y="700"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_pluto2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.pluto)
              "
              transform="matrix(1 0 0 1 657.5911 723.4877)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.pluto.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.pluto.line || 0
              }}
            </text>
          </g>
          <g id="g7167">
            <g
              id="person_sign_neptune2"
              transform="translate(23.756655,22.292976)"
            >
              <path
                class="st20_2"
                d="M714.7,632.1l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,2-1,3.7-2.4,5
                        c-0.9,0.8-2.1,0.1-2.1-1v-9.4h0.4c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4
                        v9.4c0,1-1.2,1.6-2.1,1c-1.4-1.1-2.4-2.9-2.4-5V633h0.4c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6
                        c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,4.6,3,8.4,7.1,9.7c0.8,0.2,1.2,1,1.2,1.6v2.4H700c-1,0-2,0.9-2,2l0,0c0,1,0.9,2,2,2h1.4v1.2
                        c0,1,0.9,2,2,2l0,0c1,0,2-0.9,2-2v-1.3h1.4c1,0,2-0.9,2-2l0,0c0-1-0.9-2-2-2h-1.4v-2.4c0-0.8,0.5-1.4,1.2-1.6
                        c4.1-1.3,7.1-5.1,7.1-9.7v-5.5h0.4C714.7,632.8,714.9,632.5,714.7,632.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.neptune)"
                x="690"
                y="620"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_neptune2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.neptune)
              "
              transform="matrix(1 0 0 1 657.5911 672.5722)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.neptune.gate || 0 }}.
              {{ compoSite.chart.gatesPersonalityExt.neptune.line || 0 }}
            </text>
          </g>
          <g id="g7157">
            <g
              id="person_sign_uranus2"
              transform="translate(23.806655,21.642479)"
            >
              <path
                class="st20_2"
                d="M713,587.2v-5h1.7v-1.9c0-1-0.9-1.7-1.7-1.7l0,0l0,0h-1.9c-1,0-1.7,0.9-1.7,1.7v5.1h-4v-4.7
                        c0-1-0.9-1.7-1.7-1.7l0,0c-1,0-1.7,0.9-1.7,1.7v4.7h-4v-5.1c0-1-0.9-1.7-1.7-1.7h-1.9l0,0l0,0c-1,0-1.7,0.9-1.7,1.7v1.9h1.7v5
                        l0,0v0.1l0,0v5h-1.7v1.9c0,1,0.9,1.7,1.7,1.7h1.9l0,0h0.1c1,0,1.7-0.9,1.7-1.7v-5.1h4v8.7c-2.1,0.9-3.5,2.9-3.2,5.2
                        s2.2,4.1,4.5,4.5c3,0.3,5.7-2.1,5.7-5c0-2.2-1.3-4-3.3-4.8V589h4v5.1c0,1,0.9,1.7,1.7,1.7h0.1l0,0h1.9c1,0,1.7-0.9,1.7-1.7
                        v-1.9h-1.7L713,587.2L713,587.2L713,587.2L713,587.2z M703.7,603.8c-1,0.2-1.9-0.8-1.7-1.7c0.1-0.7,0.7-1.1,1.2-1.2
                        c1-0.2,1.9,0.8,1.7,1.7C704.9,603.3,704.3,603.8,703.7,603.8z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.uranus)"
                x="690"
                y="570"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_uranus2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.uranus)
              "
              transform="matrix(1 0 0 1 657.5911 621.6562)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.uranus.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.uranus.line || 0
              }}
            </text>
          </g>
          <g id="g7148">
            <g
              id="person_sign_saturn2"
              transform="translate(23.857012,22.837349)"
            >
              <path
                class="st20_2"
                d="M713.3,548.1c1.7-2.1,3-5.9,1.7-9.5c-1-2.7-3.2-4.6-6.3-5.2c-3.5-0.8-6.5,0.7-8.8,2.4
                        c-0.5,0.4-1.2,0-1.2-0.7v-3h1.5c1,0,2-0.9,2-2l0,0c0-1-0.9-2-2-2h-1.5v-1.4c0-1-0.9-2-2-2l0,0c-1,0-2,0.9-2,2v1.5h-1.5
                        c-1,0-2,0.9-2,2l0,0c0,1,0.9,2,2,2h1.5V554c0,1,0.9,2,2,2l0,0c1,0,2-0.9,2-2v-9.3c0-1.3,0.4-2.6,1.2-3.6c1.6-2.1,4.6-4.8,8-4
                        c1.9,0.4,3,1.3,3.5,2.7c0.8,2,0,4.5-1,5.8c-2.9,3.5-3.3,6.8-1.2,10.6c0.5,1,1.9,1.3,2.8,0.7l0,0c0.9-0.5,1-1.6,0.7-2.5
                        C711,551.7,711.6,550.1,713.3,548.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.saturn)"
                x="690"
                y="520"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_saturn2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.saturn)
              "
              transform="matrix(1 0 0 1 657.5911 570.6269)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.saturn.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.saturn.line || 0
              }}
            </text>
          </g>
          <g id="g7141">
            <g
              id="person_sign_jupiter2"
              transform="translate(24.006651,22.491493)"
            >
              <path
                class="st21_2"
                d="M715.6,494.5h-5.2c-0.9,0-1.4-0.7-1.4-1.4v-13.7c0-1-0.8-1.6-1.6-1.6c-1,0-1.6,0.8-1.6,1.6V493
                        c0,0.9-0.7,1.4-1.4,1.4h-9c-0.8,0-1-0.9-0.5-1.3l3.6-3.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.8-0.8
                        c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.5-0.5s0.2-0.3,0.4-0.4c0.3-0.5,0.3-0.7,0.4-0.8c0.1-0.3,0.2-0.7,0.3-1
                        c0.2-0.7,0.3-1.2,0.3-1.9c0-0.1,0-0.2,0-0.2c0-4.4-3.6-8-8-8c-1.4,0-2.8,0.4-4,1c-1,0.5-1,1.9-0.3,2.6c0.5,0.5,1.3,0.7,2,0.3
                        c0.8-0.4,1.5-0.7,2.4-0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.8-0.2,1.4-0.5,2.1l-2.2,2.3l-7.1,7.2v0.1c0,1.9,1.4,3.3,3.3,3.3h11.8
                        c0.9,0,1.4,0.7,1.4,1.4v4.7c0,1,0.8,1.6,1.6,1.6c1,0,1.6-0.8,1.6-1.6v-4.7c0-0.9,0.7-1.4,1.4-1.4h5.2c1,0,1.6-0.8,1.6-1.6
                        C717.3,495.4,716.6,494.5,715.6,494.5z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.jupiter)"
                x="690"
                y="470"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_jupiter2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.jupiter)
              "
              transform="matrix(1 0 0 1 657.5911 519.8149)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.jupiter.gate || 0 }}.
              {{ compoSite.chart.gatesPersonalityExt.jupiter.line || 0 }}
            </text>
          </g>
          <g id="g7135">
            <g
              id="person_sign_mars2"
              transform="translate(23.740137,22.618215)"
            >
              <path
                class="st21_2"
                d="M713.2,425.8c-0.1-0.7-0.8-1-1.3-1l-5.7,0.8c-0.7,0.1-0.8,0.4-0.2,0.9l1.5,1.1c0.3,0.3,0.4,0.8,0.2,1.1
                        l-3.6,5c-0.7,0.9-1.7,1.2-2.8,1c-1-0.3-2.2-0.4-3.2-0.3l0,0l0,0c-2.9,0.2-5.5,1.6-7.2,3.9c-3.2,4.4-2.4,10.5,2,13.6
                        c1.6,1.2,3.7,2,5.8,2c0.5,0,1,0,1.4-0.1c2.6-0.4,4.9-1.7,6.4-3.8c1.7-2.3,2.4-5.1,1.6-8c-0.2-1-0.7-2.1-1.2-3
                        c-0.6-0.9-0.5-2.1,0.1-3l3.6-4.9c0.3-0.3,0.8-0.4,1.1-0.2l1.5,1.1c0.5,0.4,0.9,0.2,0.8-0.4L713.2,425.8z M703.6,447.6
                        c-1,1.3-2.4,2.2-4,2.4c-1.6,0.2-3.2-0.2-4.6-1.1c-2.7-2-3.2-5.9-1.2-8.6c1-1.4,2.7-2.3,4.6-2.5c1.4-0.1,2.9,0.3,4,1.2
                        c1.1,0.9,2,2.2,2.3,3.5C705,444.4,704.6,446.2,703.6,447.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.mars)"
                x="690"
                y="420"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_mars2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.mars)
              "
              transform="matrix(1 0 0 1 657.5911 468.8998)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.mars.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.mars.line || 0
              }}
            </text>
          </g>
          <g id="g7128">
            <g
              id="person_sign_venus2"
              transform="translate(23.806652,22.910158)"
            >
              <path
                class="st21_2"
                d="M710.8,382.5c0-2.7-1.2-5.2-3.3-7.2l0,0l0,0c-1.6-1.4-3.8-2.3-6.1-2.3s-4.4,0.9-6.1,2.3l0,0l0,0
                        c-2.1,1.7-3.3,4.4-3.3,7.2c0,4,2.6,7.4,6.1,8.8c1,0.3,1.5,1.1,1.5,2.2v2.6h-1.7c-1,0-1.6,0.8-1.6,1.6l0,0c0,1,0.8,1.6,1.6,1.6
                        h2v2.2c0,1,0.8,1.6,1.6,1.6l0,0c1,0,1.6-0.8,1.6-1.6v-2.2h2c1,0,1.6-0.8,1.6-1.6l0,0c0-1-0.8-1.6-1.6-1.6h-2v-2.6
                        c0-1,0.7-1.9,1.5-2.2C708.2,389.9,710.8,386.5,710.8,382.5z M695.4,382.5c0-1.7,0.8-3.4,2.1-4.6l0,0c2.2-1.9,5.6-1.9,7.7,0l0,0
                        c1.3,1.1,2.1,2.8,2.1,4.6c0,3.3-2.7,6-6,6S695.4,385.7,695.4,382.5z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.venus)"
                x="690"
                y="370"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_venus2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.venus)
              "
              transform="matrix(1 0 0 1 657.5911 417.9843)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.venus.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.venus.line || 0
              }}
            </text>
          </g>
          <g id="g7121">
            <g
              id="person_sign_mercury2"
              transform="translate(23.767386,22.999209)"
            >
              <path
                class="st21_2"
                d="M710.6,332.8c0-2.1-0.8-4.1-2.1-5.8c0.9-1,1.3-2.3,1.6-3.6c0.2-1-0.5-2-1.5-2l0,0c-0.8,0-1.4,0.5-1.5,1.2
                        c-0.2,0.8-0.5,1.4-1,2.1c-1.4-0.9-3-1.3-4.7-1.3s-3.3,0.4-4.7,1.3c-0.4-0.7-0.9-1.3-1-2.1c-0.2-0.8-0.9-1.2-1.5-1.2l0,0
                        c-1,0-1.7,1-1.5,2c0.3,1.3,0.9,2.5,1.6,3.6c-1.3,1.6-2.1,3.6-2.1,5.8c0,4.1,2.7,7.5,6.4,8.8c0.7,0.2,1,0.8,1,1.3v2.9h-1.5
                        c-1,0-1.7,0.9-1.7,1.7l0,0c0,1,0.9,1.7,1.7,1.7h1.5v1.7c0,1,0.9,1.7,1.7,1.7l0,0c1,0,1.7-0.9,1.7-1.7v-1.7h1.5
                        c1,0,1.7-0.9,1.7-1.7l0,0c0-1-0.9-1.7-1.7-1.7H703v-2.9c0-0.7,0.4-1.2,1-1.3C707.8,340.3,710.6,336.9,710.6,332.8z
                        M695.7,333.7c-0.3-2,0.4-3.9,2-5.1l0,0c2.1-1.7,5.2-1.7,7.3,0l0,0l0,0c1.2,1,2,2.7,2,4.4c0,3.1-2.6,5.7-5.7,5.7
                        C698.6,338.4,696.2,336.3,695.7,333.7z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.mercury)"
                x="690"
                y="315"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_mercury2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.mercury)
              "
              transform="matrix(1 0 0 1 657.5911 367.079)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.mercury.gate || 0 }}.
              {{ compoSite.chart.gatesPersonalityExt.mercury.line || 0 }}
            </text>
          </g>
          <g id="g7112">
            <path
              class="st22_2"
              d="M732.6,302.4c-0.3-0.2-0.3-0.7-0.1-1c0.5-0.8,0.9-1.6,0.7-2.7c-0.2-1.7-1.6-3.1-3.3-3.3
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,1.2,0.5,2.3,1.5,3l0,0c0.5,0.4,1.2,0.7,1.9,0.8c3.2,1.4,5.2,4.7,5.2,8.2c0,5-4,9.1-9.1,9.1
                    c-5,0-9.1-4-9.1-9.1c0-3.5,2.1-6.8,5.2-8.2c0.8-0.1,1.3-0.3,2-0.8l0,0c1-0.9,1.6-2.1,1.5-3.4c-0.2-1.7-1.6-3.2-3.4-3.4
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,0.9,0.2,1.5,0.8,2.2c0.2,0.3,0.1,0.8-0.1,1c-2.6,2.2-4.2,5.3-4.2,8.9c0,6.4,5.1,11.6,11.6,11.6
                    c6.4,0,11.6-5.1,11.6-11.6C736.8,307.8,735.2,304.6,732.6,302.4z M729.3,300.6L729.3,300.6L729.3,300.6c-0.3,0-0.7-0.1-0.8-0.2
                    l0,0c-0.3-0.2-0.5-0.7-0.5-1c0-0.9,0.8-1.4,1.5-1.2c0.5,0.1,1,0.5,1,1C730.8,299.8,730.1,300.6,729.3,300.6z M721.2,298.1
                    c0.5,0.1,1,0.4,1,1c0.1,0.5-0.1,1-0.5,1.3l0,0c-0.2,0.1-0.4,0.3-0.8,0.3l0,0l0,0l0,0c-0.5,0-1-0.3-1.2-0.9
                    C719.5,298.8,720.3,297.9,721.2,298.1z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart.planets.south_node)"
              x="710"
              y="285"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_south_node2"
              @click="
                openTooltipsDialog(
                  compoSite.chart.gatesPersonalityExt.south_node
                )
              "
              transform="matrix(1 0 0 1 657.5911 316.1533)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.south_node.gate || 0 }}.
              {{ compoSite.chart.gatesPersonalityExt.south_node.line || 0 }}
            </text>
          </g>
          <g id="g7108">
            <path
              class="st22_2"
              d="M736.8,256c0-6.4-5.1-11.6-11.6-11.6c-6.4,0-11.6,5.1-11.6,11.6c0,3.5,1.5,6.8,4.2,8.9
                    c0.3,0.2,0.3,0.7,0.1,1c-0.5,0.8-0.9,1.6-0.7,2.7c0.2,1.7,1.6,3.1,3.3,3.3c2.3,0.2,4.2-1.5,4.2-3.8c0-1.2-0.5-2.3-1.5-3l0,0
                    c-0.5-0.4-1.2-0.8-2-0.8c-3.2-1.4-5.2-4.7-5.2-8.2c0-5,4-9.1,9.1-9.1c5,0,9.1,4,9.1,9.1c0,3.5-2.1,6.8-5.2,8.2
                    c-0.8,0.1-1.3,0.3-1.9,0.8l0,0c-1,0.9-1.6,2.1-1.5,3.4c0.2,1.7,1.5,3.2,3.4,3.4c2.3,0.2,4.2-1.5,4.2-3.8c0-0.9-0.2-1.5-0.8-2.2
                    c-0.2-0.3-0.1-0.8,0.1-1C735.2,262.8,736.8,259.5,736.8,256z M721.1,266.8L721.1,266.8L721.1,266.8c0.3,0,0.7,0.1,0.8,0.3l0,0
                    c0.3,0.2,0.5,0.7,0.5,1c0,0.9-0.8,1.4-1.5,1.2c-0.5-0.1-1-0.5-1-1C719.6,267.5,720.3,266.8,721.1,266.8z M729.1,269.2
                    c-0.5-0.1-1-0.4-1-1c-0.1-0.6,0.1-1,0.5-1.3l0,0c0.1-0.1,0.4-0.2,0.8-0.2l0,0l0,0c0.8,0,1.2,0.5,1.2,1.2
                    C730.5,268.9,729.9,269.3,729.1,269.2z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart.planets.north_node)"
              x="710"
              y="238"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_north_node2"
              @click="
                openTooltipsDialog(
                  compoSite.chart.gatesPersonalityExt.north_node
                )
              "
              transform="matrix(1 0 0 1 657.5911 265.248)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.north_node.gate || 0 }}.
              {{ compoSite.chart.gatesPersonalityExt.north_node.line || 0 }}
            </text>
          </g>
          <g id="g7104">
            <path
              id="person_sign_moon2"
              inkscape:connector-curvature="0"
              class="st21_2"
              d="M722.4,221c-1,0-1.7-0.1-2.7-0.3
                    c-1.7-0.3-3.3-1-4.9-2c-0.7-0.4-0.9-1-0.8-1.7c0.1-0.8,0.7-1.2,1.3-1.3c4.2-0.9,7.5-4,8.4-8.3c0.9-4.2-1-8.5-4.7-10.8
                    c-0.7-0.4-0.9-1-0.8-1.7c0.1-0.7,0.7-1.2,1.3-1.3c1.7-0.3,3.5-0.3,5.1,0c3.6,0.8,6.8,2.8,8.8,5.9c2.1,3.1,2.8,6.8,2.1,10.4
                    C734.7,216.6,728.9,221,722.4,221z M722.3,216.3c-0.7,0.4-0.2,1.3,0.5,1.3c4.8-0.2,8.9-3.6,9.8-8.5c1-4.9-1.6-9.6-6-11.6
                    c-0.8-0.3-1.3,0.4-1,1c1.5,2.8,2.2,6.1,1.4,9.4C726.5,211.4,724.8,214.4,722.3,216.3z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart.planets.moon)"
              x="710"
              y="190"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_moon2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.moon)
              "
              transform="matrix(1 0 0 1 657.5911 214.3325)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.moon.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.moon.line || 0
              }}
            </text>
          </g>
          <g id="g7100">
            <g
              id="person_sign_earth2"
              transform="translate(23.806655,23.383064)"
            >
              <path
                class="st21_2"
                d="M702,119.8c-7.7-0.3-14.1,6-13.6,13.6c0.3,6.8,5.8,12.2,12.5,12.5c7.7,0.3,14.1-6,13.6-13.6
                        C714.2,125.6,708.7,120.1,702,119.8z M710,131.4h-6.1c-0.5,0-1-0.4-1-1v-6.1c0-0.8,0.7-1.1,1.3-1c3.2,1,5.8,3.5,6.8,6.8
                        C711.2,130.7,710.7,131.4,710,131.4z M699.8,124.3v6.1c0,0.5-0.4,1-1,1h-6.1c-0.8,0-1.1-0.7-1-1.3c1-3.2,3.5-5.8,6.8-6.8
                        C699.2,123.2,699.8,123.6,699.8,124.3z M692.7,134.4h6.1c0.5,0,1,0.4,1,1v6.1c0,0.8-0.7,1.1-1.3,1c-3.2-1-5.8-3.5-6.8-6.8
                        C691.5,135.2,692,134.4,692.7,134.4z M702.9,141.6v-6.1c0-0.5,0.4-1,1-1h6.1c0.8,0,1.1,0.7,1,1.3c-1,3.2-3.5,5.8-6.8,6.8
                        C703.5,142.7,702.9,142.2,702.9,141.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.earth)"
                x="688"
                y="113"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_earth2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.earth)
              "
              transform="matrix(1 0 0 1 657.5911 163.4169)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart.gatesPersonalityExt.earth.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.earth.line || 0
              }}
            </text>
          </g>
          <g id="g7093">
            <g
              id="person_sign_sun2"
              transform="translate(24.056651,23.28752)"
              class="planet-block-123"
            >
              <g>
                <g>
                  <path
                    class="st21_2"
                    d="M701.1,95.8c-7.5,0-13.7-6.2-13.7-13.7s6.2-13.7,13.7-13.7s13.7,6.2,13.7,13.7
                                C714.8,89.7,708.7,95.8,701.1,95.8z M701.1,71.4c-5.9,0-10.8,4.9-10.8,10.8S695.2,93,701.1,93c5.9,0,10.8-4.9,10.8-10.8
                                C711.8,76.1,707.1,71.4,701.1,71.4z"
                  />
                </g>
                <g>
                  <ellipse class="st23_2" cx="701.3" cy="82.2" rx="7" ry="7" />
                </g>
              </g>
              <rect
                @click="openTooltipsDialog(compoSite.chart.planets.sun)"
                x="688"
                y="65"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_sun2"
              @click="
                openTooltipsDialog(compoSite.chart.gatesPersonalityExt.sun)
              "
              transform="matrix(1 0 0 1 657.5911 112.5014)"
              class="st10_2 st1 st12 cursor-pointer"
            >
              {{ compoSite.chart.gatesPersonalityExt.sun.gate || 0 }}.{{
                compoSite.chart.gatesPersonalityExt.sun.line || 0
              }}
            </text>
          </g>
        </g>
        <g v-if="!isChartTurnOn && isChart2TurnOn" id="g7404_3">
          <g id="g7331_3">
            <path
              id="person_sign_pluto3"
              inkscape:connector-curvature="0"
              class="st19_2"
              d="M730.4,728.6h-13.3c-0.4,0.1-0.8-0.3-0.9-0.8
                    c0-0.1,0-0.1,0-0.2l0.1-22.2c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3l9.1,0.1c4.4,0,7.9,3.5,7.9,7.9s-3.5,7.9-7.9,7.9l-8.2,0.1
                    v6.2h12.4c0.6,0,1,0.4,1,1C731.4,728.3,730.9,728.6,730.4,728.6L730.4,728.6z M718.1,706.4v12.3l8.2-0.1c3.3,0,6.1-2.8,6.1-6.1
                    s-2.8-6.1-6.1-6.1L718.1,706.4L718.1,706.4z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart2.planets.pluto)"
              x="713"
              y="700"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_pluto3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.pluto)
              "
              transform="matrix(1 0 0 1 657.5911 723.4877)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.pluto.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.pluto.line || 0
              }}
            </text>
          </g>
          <g id="g7167">
            <g
              id="person_sign_neptune3"
              transform="translate(23.756655,22.292976)"
            >
              <path
                class="st20_2"
                d="M714.7,632.1l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,2-1,3.7-2.4,5
                        c-0.9,0.8-2.1,0.1-2.1-1v-9.4h0.4c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6c-0.2,0.4,0,0.9,0.5,0.9h0.4
                        v9.4c0,1-1.2,1.6-2.1,1c-1.4-1.1-2.4-2.9-2.4-5V633h0.4c0.5,0,0.8-0.3,0.5-0.9l-2.4-4.6c-0.2-0.4-0.7-0.4-0.9,0l-2.4,4.6
                        c-0.2,0.4,0,0.9,0.5,0.9h0.4v5.5c0,4.6,3,8.4,7.1,9.7c0.8,0.2,1.2,1,1.2,1.6v2.4H700c-1,0-2,0.9-2,2l0,0c0,1,0.9,2,2,2h1.4v1.2
                        c0,1,0.9,2,2,2l0,0c1,0,2-0.9,2-2v-1.3h1.4c1,0,2-0.9,2-2l0,0c0-1-0.9-2-2-2h-1.4v-2.4c0-0.8,0.5-1.4,1.2-1.6
                        c4.1-1.3,7.1-5.1,7.1-9.7v-5.5h0.4C714.7,632.8,714.9,632.5,714.7,632.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.neptune)"
                x="690"
                y="620"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_neptune3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.neptune)
              "
              transform="matrix(1 0 0 1 657.5911 672.5722)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.neptune.gate || 0 }}.
              {{ compoSite.chart2.gatesPersonalityExt.neptune.line || 0 }}
            </text>
          </g>
          <g id="g7157">
            <g
              id="person_sign_uranus3"
              transform="translate(23.806655,21.642479)"
            >
              <path
                class="st20_2"
                d="M713,587.2v-5h1.7v-1.9c0-1-0.9-1.7-1.7-1.7l0,0l0,0h-1.9c-1,0-1.7,0.9-1.7,1.7v5.1h-4v-4.7
                        c0-1-0.9-1.7-1.7-1.7l0,0c-1,0-1.7,0.9-1.7,1.7v4.7h-4v-5.1c0-1-0.9-1.7-1.7-1.7h-1.9l0,0l0,0c-1,0-1.7,0.9-1.7,1.7v1.9h1.7v5
                        l0,0v0.1l0,0v5h-1.7v1.9c0,1,0.9,1.7,1.7,1.7h1.9l0,0h0.1c1,0,1.7-0.9,1.7-1.7v-5.1h4v8.7c-2.1,0.9-3.5,2.9-3.2,5.2
                        s2.2,4.1,4.5,4.5c3,0.3,5.7-2.1,5.7-5c0-2.2-1.3-4-3.3-4.8V589h4v5.1c0,1,0.9,1.7,1.7,1.7h0.1l0,0h1.9c1,0,1.7-0.9,1.7-1.7
                        v-1.9h-1.7L713,587.2L713,587.2L713,587.2L713,587.2z M703.7,603.8c-1,0.2-1.9-0.8-1.7-1.7c0.1-0.7,0.7-1.1,1.2-1.2
                        c1-0.2,1.9,0.8,1.7,1.7C704.9,603.3,704.3,603.8,703.7,603.8z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.uranus)"
                x="690"
                y="570"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_uranus3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.uranus)
              "
              transform="matrix(1 0 0 1 657.5911 621.6562)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.uranus.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.uranus.line || 0
              }}
            </text>
          </g>
          <g id="g7148">
            <g
              id="person_sign_saturn3"
              transform="translate(23.857012,22.837349)"
            >
              <path
                class="st20_2"
                d="M713.3,548.1c1.7-2.1,3-5.9,1.7-9.5c-1-2.7-3.2-4.6-6.3-5.2c-3.5-0.8-6.5,0.7-8.8,2.4
                        c-0.5,0.4-1.2,0-1.2-0.7v-3h1.5c1,0,2-0.9,2-2l0,0c0-1-0.9-2-2-2h-1.5v-1.4c0-1-0.9-2-2-2l0,0c-1,0-2,0.9-2,2v1.5h-1.5
                        c-1,0-2,0.9-2,2l0,0c0,1,0.9,2,2,2h1.5V554c0,1,0.9,2,2,2l0,0c1,0,2-0.9,2-2v-9.3c0-1.3,0.4-2.6,1.2-3.6c1.6-2.1,4.6-4.8,8-4
                        c1.9,0.4,3,1.3,3.5,2.7c0.8,2,0,4.5-1,5.8c-2.9,3.5-3.3,6.8-1.2,10.6c0.5,1,1.9,1.3,2.8,0.7l0,0c0.9-0.5,1-1.6,0.7-2.5
                        C711,551.7,711.6,550.1,713.3,548.1z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.saturn)"
                x="690"
                y="520"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_saturn3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.saturn)
              "
              transform="matrix(1 0 0 1 657.5911 570.6269)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.saturn.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.saturn.line || 0
              }}
            </text>
          </g>
          <g id="g7141">
            <g
              id="person_sign_jupiter3"
              transform="translate(24.006651,22.491493)"
            >
              <path
                class="st21_2"
                d="M715.6,494.5h-5.2c-0.9,0-1.4-0.7-1.4-1.4v-13.7c0-1-0.8-1.6-1.6-1.6c-1,0-1.6,0.8-1.6,1.6V493
                        c0,0.9-0.7,1.4-1.4,1.4h-9c-0.8,0-1-0.9-0.5-1.3l3.6-3.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.8-0.8
                        c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.5-0.5s0.2-0.3,0.4-0.4c0.3-0.5,0.3-0.7,0.4-0.8c0.1-0.3,0.2-0.7,0.3-1
                        c0.2-0.7,0.3-1.2,0.3-1.9c0-0.1,0-0.2,0-0.2c0-4.4-3.6-8-8-8c-1.4,0-2.8,0.4-4,1c-1,0.5-1,1.9-0.3,2.6c0.5,0.5,1.3,0.7,2,0.3
                        c0.8-0.4,1.5-0.7,2.4-0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.8-0.2,1.4-0.5,2.1l-2.2,2.3l-7.1,7.2v0.1c0,1.9,1.4,3.3,3.3,3.3h11.8
                        c0.9,0,1.4,0.7,1.4,1.4v4.7c0,1,0.8,1.6,1.6,1.6c1,0,1.6-0.8,1.6-1.6v-4.7c0-0.9,0.7-1.4,1.4-1.4h5.2c1,0,1.6-0.8,1.6-1.6
                        C717.3,495.4,716.6,494.5,715.6,494.5z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.jupiter)"
                x="690"
                y="470"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_jupiter3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.jupiter)
              "
              transform="matrix(1 0 0 1 657.5911 519.8149)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.jupiter.gate || 0 }}.
              {{ compoSite.chart2.gatesPersonalityExt.jupiter.line || 0 }}
            </text>
          </g>
          <g id="g7135">
            <g
              id="person_sign_mars3"
              transform="translate(23.740137,22.618215)"
            >
              <path
                class="st21_2"
                d="M713.2,425.8c-0.1-0.7-0.8-1-1.3-1l-5.7,0.8c-0.7,0.1-0.8,0.4-0.2,0.9l1.5,1.1c0.3,0.3,0.4,0.8,0.2,1.1
                        l-3.6,5c-0.7,0.9-1.7,1.2-2.8,1c-1-0.3-2.2-0.4-3.2-0.3l0,0l0,0c-2.9,0.2-5.5,1.6-7.2,3.9c-3.2,4.4-2.4,10.5,2,13.6
                        c1.6,1.2,3.7,2,5.8,2c0.5,0,1,0,1.4-0.1c2.6-0.4,4.9-1.7,6.4-3.8c1.7-2.3,2.4-5.1,1.6-8c-0.2-1-0.7-2.1-1.2-3
                        c-0.6-0.9-0.5-2.1,0.1-3l3.6-4.9c0.3-0.3,0.8-0.4,1.1-0.2l1.5,1.1c0.5,0.4,0.9,0.2,0.8-0.4L713.2,425.8z M703.6,447.6
                        c-1,1.3-2.4,2.2-4,2.4c-1.6,0.2-3.2-0.2-4.6-1.1c-2.7-2-3.2-5.9-1.2-8.6c1-1.4,2.7-2.3,4.6-2.5c1.4-0.1,2.9,0.3,4,1.2
                        c1.1,0.9,2,2.2,2.3,3.5C705,444.4,704.6,446.2,703.6,447.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.mars)"
                x="690"
                y="420"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_mars3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.mars)
              "
              transform="matrix(1 0 0 1 657.5911 468.8998)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.mars.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.mars.line || 0
              }}
            </text>
          </g>
          <g id="g7128">
            <g
              id="person_sign_venus3"
              transform="translate(23.806652,22.910158)"
            >
              <path
                class="st21_2"
                d="M710.8,382.5c0-2.7-1.2-5.2-3.3-7.2l0,0l0,0c-1.6-1.4-3.8-2.3-6.1-2.3s-4.4,0.9-6.1,2.3l0,0l0,0
                        c-2.1,1.7-3.3,4.4-3.3,7.2c0,4,2.6,7.4,6.1,8.8c1,0.3,1.5,1.1,1.5,2.2v2.6h-1.7c-1,0-1.6,0.8-1.6,1.6l0,0c0,1,0.8,1.6,1.6,1.6
                        h2v2.2c0,1,0.8,1.6,1.6,1.6l0,0c1,0,1.6-0.8,1.6-1.6v-2.2h2c1,0,1.6-0.8,1.6-1.6l0,0c0-1-0.8-1.6-1.6-1.6h-2v-2.6
                        c0-1,0.7-1.9,1.5-2.2C708.2,389.9,710.8,386.5,710.8,382.5z M695.4,382.5c0-1.7,0.8-3.4,2.1-4.6l0,0c2.2-1.9,5.6-1.9,7.7,0l0,0
                        c1.3,1.1,2.1,2.8,2.1,4.6c0,3.3-2.7,6-6,6S695.4,385.7,695.4,382.5z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.venus)"
                x="690"
                y="370"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_venus3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.venus)
              "
              transform="matrix(1 0 0 1 657.5911 417.9843)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.venus.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.venus.line || 0
              }}
            </text>
          </g>
          <g id="g7121">
            <g
              id="person_sign_mercury3"
              transform="translate(23.767386,22.999209)"
            >
              <path
                class="st21_2"
                d="M710.6,332.8c0-2.1-0.8-4.1-2.1-5.8c0.9-1,1.3-2.3,1.6-3.6c0.2-1-0.5-2-1.5-2l0,0c-0.8,0-1.4,0.5-1.5,1.2
                        c-0.2,0.8-0.5,1.4-1,2.1c-1.4-0.9-3-1.3-4.7-1.3s-3.3,0.4-4.7,1.3c-0.4-0.7-0.9-1.3-1-2.1c-0.2-0.8-0.9-1.2-1.5-1.2l0,0
                        c-1,0-1.7,1-1.5,2c0.3,1.3,0.9,2.5,1.6,3.6c-1.3,1.6-2.1,3.6-2.1,5.8c0,4.1,2.7,7.5,6.4,8.8c0.7,0.2,1,0.8,1,1.3v2.9h-1.5
                        c-1,0-1.7,0.9-1.7,1.7l0,0c0,1,0.9,1.7,1.7,1.7h1.5v1.7c0,1,0.9,1.7,1.7,1.7l0,0c1,0,1.7-0.9,1.7-1.7v-1.7h1.5
                        c1,0,1.7-0.9,1.7-1.7l0,0c0-1-0.9-1.7-1.7-1.7H703v-2.9c0-0.7,0.4-1.2,1-1.3C707.8,340.3,710.6,336.9,710.6,332.8z
                        M695.7,333.7c-0.3-2,0.4-3.9,2-5.1l0,0c2.1-1.7,5.2-1.7,7.3,0l0,0l0,0c1.2,1,2,2.7,2,4.4c0,3.1-2.6,5.7-5.7,5.7
                        C698.6,338.4,696.2,336.3,695.7,333.7z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.mercury)"
                x="690"
                y="315"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_mercury3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.mercury)
              "
              transform="matrix(1 0 0 1 657.5911 367.079)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.mercury.gate || 0 }}.
              {{ compoSite.chart2.gatesPersonalityExt.mercury.line || 0 }}
            </text>
          </g>
          <g id="g7112">
            <path
              class="st22_2"
              d="M732.6,302.4c-0.3-0.2-0.3-0.7-0.1-1c0.5-0.8,0.9-1.6,0.7-2.7c-0.2-1.7-1.6-3.1-3.3-3.3
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,1.2,0.5,2.3,1.5,3l0,0c0.5,0.4,1.2,0.7,1.9,0.8c3.2,1.4,5.2,4.7,5.2,8.2c0,5-4,9.1-9.1,9.1
                    c-5,0-9.1-4-9.1-9.1c0-3.5,2.1-6.8,5.2-8.2c0.8-0.1,1.3-0.3,2-0.8l0,0c1-0.9,1.6-2.1,1.5-3.4c-0.2-1.7-1.6-3.2-3.4-3.4
                    c-2.3-0.2-4.2,1.5-4.2,3.8c0,0.9,0.2,1.5,0.8,2.2c0.2,0.3,0.1,0.8-0.1,1c-2.6,2.2-4.2,5.3-4.2,8.9c0,6.4,5.1,11.6,11.6,11.6
                    c6.4,0,11.6-5.1,11.6-11.6C736.8,307.8,735.2,304.6,732.6,302.4z M729.3,300.6L729.3,300.6L729.3,300.6c-0.3,0-0.7-0.1-0.8-0.2
                    l0,0c-0.3-0.2-0.5-0.7-0.5-1c0-0.9,0.8-1.4,1.5-1.2c0.5,0.1,1,0.5,1,1C730.8,299.8,730.1,300.6,729.3,300.6z M721.2,298.1
                    c0.5,0.1,1,0.4,1,1c0.1,0.5-0.1,1-0.5,1.3l0,0c-0.2,0.1-0.4,0.3-0.8,0.3l0,0l0,0l0,0c-0.5,0-1-0.3-1.2-0.9
                    C719.5,298.8,720.3,297.9,721.2,298.1z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart2.planets.south_node)"
              x="710"
              y="285"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_south_node3"
              @click="
                openTooltipsDialog(
                  compoSite.chart2.gatesPersonalityExt.south_node
                )
              "
              transform="matrix(1 0 0 1 657.5911 316.1533)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.south_node.gate || 0 }}.
              {{ compoSite.chart2.gatesPersonalityExt.south_node.line || 0 }}
            </text>
          </g>
          <g id="g7108">
            <path
              class="st22_2"
              d="M736.8,256c0-6.4-5.1-11.6-11.6-11.6c-6.4,0-11.6,5.1-11.6,11.6c0,3.5,1.5,6.8,4.2,8.9
                    c0.3,0.2,0.3,0.7,0.1,1c-0.5,0.8-0.9,1.6-0.7,2.7c0.2,1.7,1.6,3.1,3.3,3.3c2.3,0.2,4.2-1.5,4.2-3.8c0-1.2-0.5-2.3-1.5-3l0,0
                    c-0.5-0.4-1.2-0.8-2-0.8c-3.2-1.4-5.2-4.7-5.2-8.2c0-5,4-9.1,9.1-9.1c5,0,9.1,4,9.1,9.1c0,3.5-2.1,6.8-5.2,8.2
                    c-0.8,0.1-1.3,0.3-1.9,0.8l0,0c-1,0.9-1.6,2.1-1.5,3.4c0.2,1.7,1.5,3.2,3.4,3.4c2.3,0.2,4.2-1.5,4.2-3.8c0-0.9-0.2-1.5-0.8-2.2
                    c-0.2-0.3-0.1-0.8,0.1-1C735.2,262.8,736.8,259.5,736.8,256z M721.1,266.8L721.1,266.8L721.1,266.8c0.3,0,0.7,0.1,0.8,0.3l0,0
                    c0.3,0.2,0.5,0.7,0.5,1c0,0.9-0.8,1.4-1.5,1.2c-0.5-0.1-1-0.5-1-1C719.6,267.5,720.3,266.8,721.1,266.8z M729.1,269.2
                    c-0.5-0.1-1-0.4-1-1c-0.1-0.6,0.1-1,0.5-1.3l0,0c0.1-0.1,0.4-0.2,0.8-0.2l0,0l0,0c0.8,0,1.2,0.5,1.2,1.2
                    C730.5,268.9,729.9,269.3,729.1,269.2z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart2.planets.north_node)"
              x="710"
              y="238"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_north_node3"
              @click="
                openTooltipsDialog(
                  compoSite.chart2.gatesPersonalityExt.north_node
                )
              "
              transform="matrix(1 0 0 1 657.5911 265.248)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.north_node.gate || 0 }}.
              {{ compoSite.chart2.gatesPersonalityExt.north_node.line || 0 }}
            </text>
          </g>
          <g id="g7104">
            <path
              id="person_sign_moon3"
              inkscape:connector-curvature="0"
              class="st21_2"
              d="M722.4,221c-1,0-1.7-0.1-2.7-0.3
                    c-1.7-0.3-3.3-1-4.9-2c-0.7-0.4-0.9-1-0.8-1.7c0.1-0.8,0.7-1.2,1.3-1.3c4.2-0.9,7.5-4,8.4-8.3c0.9-4.2-1-8.5-4.7-10.8
                    c-0.7-0.4-0.9-1-0.8-1.7c0.1-0.7,0.7-1.2,1.3-1.3c1.7-0.3,3.5-0.3,5.1,0c3.6,0.8,6.8,2.8,8.8,5.9c2.1,3.1,2.8,6.8,2.1,10.4
                    C734.7,216.6,728.9,221,722.4,221z M722.3,216.3c-0.7,0.4-0.2,1.3,0.5,1.3c4.8-0.2,8.9-3.6,9.8-8.5c1-4.9-1.6-9.6-6-11.6
                    c-0.8-0.3-1.3,0.4-1,1c1.5,2.8,2.2,6.1,1.4,9.4C726.5,211.4,724.8,214.4,722.3,216.3z"
            />
            <rect
              @click="openTooltipsDialog(compoSite.chart2.planets.moon)"
              x="710"
              y="190"
              class="rectangle-over-planet cursor-pointer over-shadow-anim"
            ></rect>
            <text
              id="person_number_moon3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.moon)
              "
              transform="matrix(1 0 0 1 657.5911 214.3325)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.moon.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.moon.line || 0
              }}
            </text>
          </g>
          <g id="g7100">
            <g
              id="person_sign_earth3"
              transform="translate(23.806655,23.383064)"
            >
              <path
                class="st21_2"
                d="M702,119.8c-7.7-0.3-14.1,6-13.6,13.6c0.3,6.8,5.8,12.2,12.5,12.5c7.7,0.3,14.1-6,13.6-13.6
                        C714.2,125.6,708.7,120.1,702,119.8z M710,131.4h-6.1c-0.5,0-1-0.4-1-1v-6.1c0-0.8,0.7-1.1,1.3-1c3.2,1,5.8,3.5,6.8,6.8
                        C711.2,130.7,710.7,131.4,710,131.4z M699.8,124.3v6.1c0,0.5-0.4,1-1,1h-6.1c-0.8,0-1.1-0.7-1-1.3c1-3.2,3.5-5.8,6.8-6.8
                        C699.2,123.2,699.8,123.6,699.8,124.3z M692.7,134.4h6.1c0.5,0,1,0.4,1,1v6.1c0,0.8-0.7,1.1-1.3,1c-3.2-1-5.8-3.5-6.8-6.8
                        C691.5,135.2,692,134.4,692.7,134.4z M702.9,141.6v-6.1c0-0.5,0.4-1,1-1h6.1c0.8,0,1.1,0.7,1,1.3c-1,3.2-3.5,5.8-6.8,6.8
                        C703.5,142.7,702.9,142.2,702.9,141.6z"
              />
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.earth)"
                x="688"
                y="113"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_earth3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.earth)
              "
              transform="matrix(1 0 0 1 657.5911 163.4169)"
              class="st10_2 st1 st12 cursor-pointer over-shadow-anim"
            >
              {{ compoSite.chart2.gatesPersonalityExt.earth.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.earth.line || 0
              }}
            </text>
          </g>
          <g id="g7093">
            <g
              id="person_sign_sun3"
              transform="translate(24.056651,23.28752)"
              class="planet-block-123"
            >
              <g>
                <g>
                  <path
                    class="st21_2"
                    d="M701.1,95.8c-7.5,0-13.7-6.2-13.7-13.7s6.2-13.7,13.7-13.7s13.7,6.2,13.7,13.7
                                C714.8,89.7,708.7,95.8,701.1,95.8z M701.1,71.4c-5.9,0-10.8,4.9-10.8,10.8S695.2,93,701.1,93c5.9,0,10.8-4.9,10.8-10.8
                                C711.8,76.1,707.1,71.4,701.1,71.4z"
                  />
                </g>
                <g>
                  <ellipse class="st23_2" cx="701.3" cy="82.2" rx="7" ry="7" />
                </g>
              </g>
              <rect
                @click="openTooltipsDialog(compoSite.chart2.planets.sun)"
                x="688"
                y="65"
                class="rectangle-over-planet cursor-pointer over-shadow-anim"
              ></rect>
            </g>
            <text
              id="person_number_sun3"
              @click="
                openTooltipsDialog(compoSite.chart2.gatesPersonalityExt.sun)
              "
              transform="matrix(1 0 0 1 657.5911 112.5014)"
              class="st10_2 st1 st12 cursor-pointer"
            >
              {{ compoSite.chart2.gatesPersonalityExt.sun.gate || 0 }}.{{
                compoSite.chart2.gatesPersonalityExt.sun.line || 0
              }}
            </text>
          </g>
        </g>
      </g>
      <g>
        <g>
          <rect
            x="396.8"
            y="674.8"
            transform="matrix(0.7648 -0.6443 0.6443 0.7648 -319.5819 482.0521)"
            class="st24"
            width="207.2"
            height="7.8"
          />
          <path
            class="st25"
            d="M577.1,609.2l4.8,5.7L423.7,748.2l-4.8-5.7L577.1,609.2 M577.1,608.9L418.5,742.5l5.2,6.1L582.3,615
                L577.1,608.9L577.1,608.9z"
          />
        </g>
        <g>
          <polygon
            class="st24"
            points="411.6,721.9 570.1,588.5 575.1,594.4 496.5,660.6 416.5,727.8 			"
          />
          <path
            class="st25"
            d="M570.1,588.7l4.9,5.7l-78.5,66l-79.9,67.1l-4.8-5.7l79.8-67.1L570.1,588.7 M570.1,588.4l-78.7,66.2
                l-80,67.3l5.1,6.1l80.1-67.3l78.7-66.2L570.1,588.4L570.1,588.4z"
          />
        </g>
        <path
          class="st26"
          d="M559,579.6l-5.2-6.1l-78.6,66.2l0,0l-70.9,59.7v-54v-57v-86.2l41.9-15.7l-2.8-7.5l-39.1,14.7V419v-61.5v-62
            V293c9,15.6,45.3,77.7,76.4,130.6l0,0L562,561.4l6.9-4.1l-81-137.8l0,0c-26.4-45-76.7-130.8-78.2-133.7l-3.6,1.8l-1.6,0.7v-55.9
            V163v-39.5v-32h-7.9v32V163v69.5v56v69V419v77.7l-12.5,4.7V419v-61.5v-69v-56V163v-39.5v-32h-8v32V163v69.5v56v69V419v82.5v2.9
            l-12.6,4.7v-7.6V419v-61.5v-69v-56V163v-39.5v-32h-7.9v32V163v69.5v51.6c-5.5,9.6-52.5,89.8-77.7,132.8l0,0l-81.1,138l6.9,4.1
            l14.3-24.3l38.6,14.5l-33.2,12.4l2.8,7.5l41.7-15.7l69.7,26.2l2.8-7.5l-61.2-23L338,527l17.4-6.5v67.9v57v54L285,640.1v0.1
            L206.4,574l-5.2,6.1l78.6,66.2v-0.1l75.6,63.6v14.6h7.9v-14.8l1.7-2.1l-1.7-1.5v-60.6v-57v-70.8l12.6-4.7v75.6v57v79h8v-79v-57
            v-78.6l12.5-4.7v83.2v57v60.7l-1.3,1.1l1.3,1.6v15.6h7.9v-14.5l76-64.1L559,579.6z M355.4,357.4v61.5v82.5V512l-20.2,7.6
            l-67.4,25.3l-45.9-17.3l61.2-104.2h47.3v-8h-42.5c25.7-43.8,54.2-92.5,67.6-115.5v57.5L355.4,357.4L355.4,357.4z"
        />
        <g>
          <polygon
            class="st24"
            points="255.6,681.1 177.1,614.9 182,609 260.5,675.1 343.8,745.4 338.8,751.3 			"
          />
          <path
            class="st25"
            d="M182,609.2l78.4,65.9l83.2,70.2l-4.9,5.7l-83.1-70l-78.4-66L182,609.2 M182,608.9l-5.1,6.1l78.6,66.2
                l83.3,70.3l5.2-6.1L260.6,675L182,608.9L182,608.9z"
          />
        </g>
        <g>
          <polygon
            class="st24"
            points="266.7,665.9 188.2,599.7 193.1,593.8 271.6,660 352.4,727.3 347.4,733.2 			"
          />
          <path
            class="st25"
            d="M193.1,594l78.4,66l80.7,67.2l-4.9,5.7l-80.6-67.2l-78.4-66L193.1,594 M193.1,593.7l-5.1,6.1l78.6,66.2
                l80.8,67.4l5.2-6.1l-80.9-67.4L193.1,593.7L193.1,593.7z"
          />
        </g>
        <g>
          <path
            class="st24"
            d="M177.5,553l81.2-137.9c7.4-12.6,16.7-28.4,26.2-44.7c24.7-42.1,50.2-85.7,51.9-88.9l2.6,1.3l4.5,1.9
                c-2,3.5-42.7,73.2-78.5,134.2l-81.2,138L177.5,553z"
          />
          <path
            class="st25"
            d="M336.9,281.7l2.5,1.3l4.3,1.9c-2.7,4.7-43,73.6-78.5,134.1l-81.1,137.8l-6.5-3.8l81.1-137.7
                c7.4-12.6,16.6-28.3,26.1-44.6C309.4,328.8,334.7,285.7,336.9,281.7 M336.8,281.4c-1.5,2.9-51.8,88.6-78.2,133.7l-81.2,138
                l6.9,4.1l81.2-138.1c35.5-60.5,77.9-133,78.6-134.3l-4.6-2L336.8,281.4L336.8,281.4z"
          />
        </g>
        <g>
          <path
            class="st24"
            d="M447.2,396.1l-4.9-8.3c-19.4-33.1-30.4-51.9-31.3-53.5l3.5-1.7l3.4-1.8c1.6,2.7,24,41.3,35.7,61.3v0.1h0.1
                l37.2,63.3l-6.7,4L447.2,396.1z"
          />
          <path
            class="st25"
            d="M417.9,330.9c2,3.5,24.1,41.4,35.7,61.2l0.1,0.1h0.1l37.1,63.2l-6.5,3.8L447.3,396l-4.6-7.8
                c-19.3-32.8-30.3-51.6-31.5-53.8l3.4-1.7L417.9,330.9 M418,330.6l-3.5,1.9l-3.6,1.8c0.2,0.5,12.4,21.3,36.2,61.9l37.2,63.4
                l6.9-4.1L453.9,392h-0.1C441.7,371.3,418.8,332,418,330.6L418,330.6z"
          />
        </g>
        <g>
          <path
            class="st24"
            d="M496.1,418.9c-39-66.4-78.6-134.2-80.3-137.5l3.6-1.5l3.5-1.7c1.8,3.3,30.2,51.7,55.2,94.5
                c9.1,15.5,17.8,30.3,24.8,42.3l81,138l-6.7,4L496.1,418.9z"
          />
          <path
            class="st25"
            d="M422.8,278.3c2.3,4.1,30.3,52,55.1,94.3c9.1,15.5,17.8,30.4,24.9,42.4l81,137.9l-6.5,3.8l-81.1-137.9
                c-37.7-64.1-77.9-132.9-80.3-137.3l3.5-1.5L422.8,278.3 M422.9,278l-3.6,1.8l-3.7,1.6c0.5,1.3,44,75.6,80.4,137.6l81.2,138.1
                l6.9-4.1L503,414.9C476,368.8,424.4,280.9,422.9,278L422.9,278z"
          />
        </g>
        <g>
          <polygon
            class="st24"
            points="411.6,600.2 457.8,587 508.6,572.7 510.6,580.2 460,594.4 413.7,607.6 			"
          />
          <path
            class="st25"
            d="M508.5,572.9l2,7.2l-50.6,14.2l-46.2,13.1l-2-7.2l46.1-13.1L508.5,572.9 M508.7,572.6l-50.9,14.3
                l-46.3,13.2l2.1,7.7l46.4-13.2l50.8-14.3L508.7,572.6L508.7,572.6z"
          />
        </g>
        <g>
          <polygon
            class="st24"
            points="294,594.2 243.3,580 245.4,572.5 296.1,586.8 341.5,600.4 339.5,607.9 			"
          />
          <path
            class="st25"
            d="M245.5,572.7l50.6,14.2l45.4,13.6l-2,7.2l-45.4-13.6l-50.6-14.2L245.5,572.7 M245.3,572.4l-2.1,7.7
                l50.8,14.3l45.6,13.7l2.1-7.7l-45.6-13.7L245.3,572.4L245.3,572.4z"
          />
        </g>
        <g>
          <polygon
            class="st24"
            points="527.4,533.3 509.4,502.8 516.2,498.8 534.2,529.5 550.8,557.6 544.2,561.5 			"
          />
          <path
            class="st25"
            d="M516.1,499l17.9,30.6l16.6,28l-6.4,3.8l-16.7-28.1l-17.9-30.5L516.1,499 M516.3,498.7l-6.9,4.1l18,30.7
                l16.8,28.3l6.8-4.1l-16.7-28.2L516.3,498.7L516.3,498.7z"
          />
        </g>
        <g>
          <polygon
            class="st24"
            points="442.5,446.8 422.3,422.8 428.2,417.7 448.4,441.7 469.8,467 463.9,472 			"
          />
          <path
            class="st25"
            d="M428.2,417.9l20.1,23.9l21.3,25.1l-5.7,4.9l-21.3-25.1l-20.1-23.9L428.2,417.9 M428.2,417.6l-6.1,5.2
                l20.3,24.1l21.5,25.3l6.1-5.2l-21.5-25.3L428.2,417.6L428.2,417.6z"
          />
        </g>
      </g>
    </g>
    <g
      id="layer8"
      inkscape:groupmode="layer"
      inkscape:label="Gates Person"
      sodipodi:insensitive="true"
    >
      <g id="Слой_14" transform="translate(31.134997,17.409983)">
        <rect
          id="gate_41_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                41
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                41
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="377.8"
          y="690.3"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -346.1888 440.5635)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_41_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                41
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                41
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="377.8"
          y="690.3"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -346.1888 440.5635)"
          width="104.7"
          height="8"
        />

        <rect
          id="gate_30_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                30
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                30
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="458"
          y="623.5"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -284.4468 475.7161)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_30_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                30
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                30
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="458"
          y="623.5"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -284.4468 475.7161)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_55_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                55
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                55
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="450.9"
          y="603"
          transform="matrix(0.7653 -0.6437 0.6437 0.7653 -272.8276 465.8037)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_55_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                55
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                55
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="450.9"
          y="603"
          transform="matrix(0.7653 -0.6437 0.6437 0.7653 -272.8276 465.8037)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_49_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                49
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                49
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="434.7"
          y="588.2"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_49_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                49
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                49
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="434.7"
          y="588.2"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_39_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                39
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                39
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="370.6"
          y="669.7"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -334.6085 431.0764)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_39_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                39
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                39
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="370.6"
          y="669.7"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -334.6085 431.0764)"
          width="104.7"
          height="8"
        />

        <rect
          id="gate_19_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                19
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                19
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="354.3"
          y="655"
          transform="matrix(0.7651 -0.644 0.644 0.7651 -328.8389 416.7515)"
          width="104.8"
          height="8"
        />
        <rect
          id="gate_19_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                19
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                19
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="354.3"
          y="655"
          transform="matrix(0.7651 -0.644 0.644 0.7651 -328.8389 416.7515)"
          width="104.8"
          height="8"
        />

        <rect
          id="gate_58_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                58
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                58
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="264.7"
          y="641.2"
          transform="matrix(0.645 -0.7642 0.7642 0.645 -436.2997 452.3404)"
          width="8"
          height="109.1"
        />
        <rect
          id="gate_58_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                58
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                58
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="264.7"
          y="641.2"
          transform="matrix(0.645 -0.7642 0.7642 0.645 -436.2997 452.3404)"
          width="8"
          height="109.1"
        />

        <rect
          id="gate_18_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                18
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                18
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="183.7"
          y="576.1"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.184 366.8567)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_18_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                18
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                18
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="183.7"
          y="576.1"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.184 366.8567)"
          width="8"
          height="102.8"
        />

        <rect
          id="gate_38_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                38
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                38
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="274.4"
          y="626.4"
          transform="matrix(0.6411 -0.7674 0.7674 0.6411 -421.1985 457.324)"
          width="8"
          height="105.3"
        />
        <rect
          id="gate_38_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                38
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                38
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="274.4"
          y="626.4"
          transform="matrix(0.6411 -0.7674 0.7674 0.6411 -421.1985 457.324)"
          width="8"
          height="105.3"
        />

        <rect
          id="gate_54_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                54
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                54
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="287.4"
          y="607"
          transform="matrix(0.6438 -0.7652 0.7652 0.6438 -400.7362 457.8434)"
          width="8"
          height="104.7"
        />
        <rect
          id="gate_54_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                54
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                54
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="287.4"
          y="607"
          transform="matrix(0.6438 -0.7652 0.7652 0.6438 -400.7362 457.8434)"
          width="8"
          height="104.7"
        />

        <rect
          id="gate_36_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                36
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                36
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="505.1"
          y="388.5"
          transform="matrix(0.8623 -0.5064 0.5064 0.8623 -167.1954 322.335)"
          width="8"
          height="160.2"
        />
        <rect
          id="gate_36_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                36
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                36
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="505.1"
          y="388.5"
          transform="matrix(0.8623 -0.5064 0.5064 0.8623 -167.1954 322.335)"
          width="8"
          height="160.2"
        />

        <path
          id="gate_45_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                45
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                45
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M416,378.7c-23.7-40.6-35.9-61.4-36.1-61.8l3.6-1.8l3.5-1.9c0.8,1.4,23.8,40.8,35.9,61.5L416,378.7z"
        />
        <path
          id="gate_45_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                45
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                45
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M416,378.7c-23.7-40.6-35.9-61.4-36.1-61.8l3.6-1.8l3.5-1.9c0.8,1.4,23.8,40.8,35.9,61.5L416,378.7z"
        />

        <rect
          id="gate_21_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                21
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                21
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="434.1"
          y="371.5"
          transform="matrix(0.8622 -0.5065 0.5065 0.8622 -146.4489 278.1473)"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="73.6"
        />
        <rect
          id="gate_21_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                21
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                21
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="434.1"
          y="371.5"
          transform="matrix(0.8622 -0.5065 0.5065 0.8622 -146.4489 278.1473)"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="73.6"
        />

        <rect
          id="gate_22_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                22
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                22
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="489.7"
          y="392.9"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -171.6475 315.6749)"
          width="8"
          height="160.1"
        />
        <rect
          id="gate_22_person2"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                22
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                22
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="489.7"
          y="392.9"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -171.6475 315.6749)"
          width="8"
          height="160.1"
        />

        <rect
          id="gate_57_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[57])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                57
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                57
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="129.5"
          y="466.3"
          transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1018 412.7197)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_57_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[57])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                57
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                57
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="129.5"
          y="466.3"
          transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1018 412.7197)"
          width="160.1"
          height="8"
        />
        <path
          id="gate_20_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
        />
        <path
          id="gate_20_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
        />

        <rect
          id="gate_28_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                28
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                28
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="194.8"
          y="560.9"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.6078 369.9385)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_28_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                28
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                28
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="194.8"
          y="560.9"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.6078 369.9385)"
          width="8"
          height="102.8"
        />

        <rect
          id="gate_32_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                32
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                32
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="208"
          y="541.2"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8424 373.0252)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_32_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                32
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                32
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="208"
          y="541.2"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8424 373.0252)"
          width="8"
          height="102.8"
        />
        <path
          id="gate_35_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                35
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                35
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M465,401.6c-36.4-62-79.8-136.3-80.4-137.6l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,53.1,90.9,80.1,137L465,401.6z
            "
        />
        <path
          id="gate_35_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                35
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                35
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M465,401.6c-36.4-62-79.8-136.3-80.4-137.6l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,53.1,90.9,80.1,137L465,401.6z
            "
        />
        <path
          id="gate_12_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 12) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 12) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M449.7,406c-35.5-60.5-77.9-133-78.5-134.3l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.7
            L449.7,406z"
        />
        <path
          id="gate_12_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                12
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M449.7,406c-35.5-60.5-77.9-133-78.5-134.3l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.7
            L449.7,406z"
        />
        <path
          id="gate_12_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 12) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 12) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M449.7,406c-35.5-60.5-77.9-133-78.5-134.3l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.7
            L449.7,406z"
        />
        <path
          id="gate_12_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                12
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M449.7,406c-35.5-60.5-77.9-133-78.5-134.3l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.7
            L449.7,406z"
        />
        <path
          id="gate_12_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                12
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                12
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M449.7,406c-35.5-60.5-77.9-133-78.5-134.3l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.7
            L449.7,406z"
        />
        <path
          id="gate_12_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                12
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                12
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M449.7,406c-35.5-60.5-77.9-133-78.5-134.3l7.4-3.2l-3.7,1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.7
            L449.7,406z"
        />
        <path
          id="gate_16_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                16
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                16
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          d="M234.5,401.6l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C312.4,268.6,270,341.1,234.5,401.6z"
        />
        <path
          id="gate_16_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                16
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                16
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M234.5,401.6l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C312.4,268.6,270,341.1,234.5,401.6z"
        />
        <rect
          id="gate_10_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="247"
          y="397.9"
          width="49.2"
          height="8"
        />
        <rect
          id="gate_10_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="247"
          y="397.9"
          width="49.2"
          height="8"
        />
        <path
          id="gate_10_person_10_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              compoSite.chart.channels2['10-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_10_design_10_34_channel"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 10) &&
              compoSite.chart.channels2['10-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_10_both_10_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              compoSite.chart.channels2['10-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st9"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <path
          id="gate_10_person2_10_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              compoSite.chart2.channels2['10-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_10_design2_10_34_channel"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 10) &&
              compoSite.chart2.channels2['10-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_10_both2_10_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              compoSite.chart2.channels2['10-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st9"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <path
          id="gate_20_person_20_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              compoSite.chart.channels2['20-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_20_design_20_34_channel"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 20) &&
              compoSite.chart.channels2['20-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_20_both_20_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              compoSite.chart.channels2['20-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st9"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <path
          id="gate_20_person2_20_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              compoSite.chart2.channels2['20-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_20_design2_20_34_channel"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 20) &&
              compoSite.chart2.channels2['20-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_20_both2_20_34_channel"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              compoSite.chart2.channels2['20-34'] &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st9"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <!-- Additional channels at charts -->
        <path
          id="gate_10_person_10_34_channel_chart1"
          :class="{
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              compoSite.chart.channels2['10-34'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_10_design_10_34_channel_chart1"
          :class="{
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 10) &&
              compoSite.chart.channels2['10-34'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_10_both_10_34_channel_chart1"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              compoSite.chart.channels2['10-34'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st38"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <path
          id="gate_10_person2_10_34_channel_chart2"
          :class="{
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                10
              ) &&
              compoSite.chart2.channels2['10-34'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_10_design2_10_34_channel_chart2"
          :class="{
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 10) &&
              compoSite.chart2.channels2['10-34'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_10_both2_10_34_channel_chart2"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              compoSite.chart2.channels2['10-34'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st38"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <path
          id="gate_20_person_20_34_channel_chart1"
          :class="{
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              compoSite.chart.channels2['20-34'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_20_design_20_34_channel_chart1"
          :class="{
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 20) &&
              compoSite.chart.channels2['20-34'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_20_both_20_34_channel_chart1"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              compoSite.chart.channels2['20-34'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st38"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <path
          id="gate_20_person2_20_34_channel_chart2"
          :class="{
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                20
              ) &&
              compoSite.chart2.channels2['20-34'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <path
          id="gate_20_design2_20_34_channel_chart2"
          :class="{
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 20) &&
              compoSite.chart2.channels2['20-34'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
          transform="translate(-32,-18)"
        />
        <g
          id="gate_20_both2_20_34_channel_chart2"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              compoSite.chart2.channels2['20-34'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M 213 531.577271 L 280.278412 415 L 286 418.422699 L 218.721588 535 Z"
            transform="translate(-32,-18)"
          />
          <path
            class="st38"
            d="M 219 527.724854 L 284.826782 415.49231 L 286 419.767517 L 220.173187 532 Z"
            transform="translate(-32,-18)"
          />
        </g>
        <!-- End additional channels-->
        <rect
          id="gate_34_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[34])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                34
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                34
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="244"
          y="471.5"
          transform="matrix(0.3515 -0.9362 0.9362 0.3515 -340.6756 579.5215)"
          width="8"
          height="128.3"
        />
        <rect
          id="gate_34_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[34])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                34
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                34
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="244"
          y="471.5"
          transform="matrix(0.3515 -0.9362 0.9362 0.3515 -340.6756 579.5215)"
          width="8"
          height="128.3"
        />
        <rect
          id="gate_27_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                27
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                27
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="282.2"
          y="556.2"
          transform="matrix(0.286 -0.9582 0.9582 0.286 -351.5284 688.4722)"
          width="8"
          height="47.9"
        />
        <rect
          id="gate_27_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                27
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                27
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="282.2"
          y="556.2"
          transform="matrix(0.286 -0.9582 0.9582 0.286 -351.5284 688.4722)"
          width="8"
          height="47.9"
        />

        <rect
          id="gate_59_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                59
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                59
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="380.6"
          y="575.9"
          transform="matrix(0.9624 -0.2717 0.2717 0.9624 -142.3351 131.7713)"
          width="48.2"
          height="8"
        />
        <rect
          id="gate_59_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                59
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                59
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="380.6"
          y="575.9"
          transform="matrix(0.9624 -0.2717 0.2717 0.9624 -142.3351 131.7713)"
          width="48.2"
          height="8"
        />

        <rect
          id="gate_50_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                50
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                50
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="235"
          y="539.3"
          transform="matrix(0.2732 -0.962 0.962 0.2732 -370.4136 641.0118)"
          width="8"
          height="52.7"
        />
        <rect
          id="gate_50_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                50
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                50
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="235"
          y="539.3"
          transform="matrix(0.2732 -0.962 0.962 0.2732 -370.4136 641.0118)"
          width="8"
          height="52.7"
        />

        <rect
          id="gate_06_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                6
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                6
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="426.9"
          y="562.1"
          transform="matrix(0.9626 -0.271 0.271 0.9626 -136.4507 144.0277)"
          width="52.8"
          height="8"
        />
        <rect
          id="gate_06_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                6
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                6
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="426.9"
          y="562.1"
          transform="matrix(0.9626 -0.271 0.271 0.9626 -136.4507 144.0277)"
          width="52.8"
          height="8"
        />

        <rect
          id="gate_37_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                37
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                37
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="504"
          y="511.7"
          transform="matrix(0.8593 -0.5115 0.5115 0.8593 -198.6402 334.1532)"
          width="8"
          height="32.8"
        />
        <rect
          id="gate_37_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                37
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                37
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="504"
          y="511.7"
          transform="matrix(0.8593 -0.5115 0.5115 0.8593 -198.6402 334.1532)"
          width="8"
          height="32.8"
        />

        <rect
          id="gate_26_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                26
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                26
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="301.9"
          y="481.5"
          transform="matrix(0.9363 -0.3513 0.3513 0.9363 -147.6308 157.3058)"
          width="115.6"
          height="8"
        />
        <rect
          id="gate_26_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                26
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                26
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="301.9"
          y="481.5"
          transform="matrix(0.9363 -0.3513 0.3513 0.9363 -147.6308 157.3058)"
          width="115.6"
          height="8"
        />

        <rect
          id="gate_40_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                40
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                40
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="486.6"
          y="480.9"
          transform="matrix(0.8612 -0.5083 0.5083 0.8612 -185.3855 318.6001)"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="35.6"
        />
        <rect
          id="gate_40_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                40
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                40
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="486.6"
          y="480.9"
          transform="matrix(0.8612 -0.5083 0.5083 0.8612 -185.3855 318.6001)"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="35.6"
        />

        <rect
          id="gate_25_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                25
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                25
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="400.3"
          y="399"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -172.1083 357.9811)"
          width="8"
          height="31.5"
        />
        <rect
          id="gate_25_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                25
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                25
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="400.3"
          y="399"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -172.1083 357.9811)"
          width="8"
          height="31.5"
        />

        <rect
          id="gate_51_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                51
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                51
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="421.3"
          y="422.9"
          transform="matrix(0.765 -0.644 0.644 0.765 -183.0686 377.1554)"
          width="8"
          height="33.1"
        />
        <rect
          id="gate_51_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                51
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                51
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="421.3"
          y="422.9"
          transform="matrix(0.765 -0.644 0.644 0.765 -183.0686 377.1554)"
          width="8"
          height="33.1"
        />

        <rect
          id="gate_44_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                44
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                44
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="189.8"
          y="522.8"
          transform="matrix(0.9363 -0.3511 0.3511 0.9363 -169.0688 121.173)"
          width="119.6"
          height="8"
        />
        <rect
          id="gate_44_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                44
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                44
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="189.8"
          y="522.8"
          transform="matrix(0.9363 -0.3511 0.3511 0.9363 -169.0688 121.173)"
          width="119.6"
          height="8"
        />
      </g>
      <g id="Слой_10-2" transform="translate(31.140015,17.409983)">
        <rect
          id="gate_42_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                42
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                42
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_42_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                42
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                42
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_05_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                5
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                5
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_05_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                5
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                5
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_15_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                15
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                15
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_15_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                15
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                15
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_07_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                7
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                7
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_07_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                7
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                7
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_31_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                31
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                31
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_31_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                31
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                31
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_62_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                62
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                62
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_62_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                62
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                62
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_17_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                17
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                17
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_17_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                17
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                17
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_64_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                64
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                64
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_64_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                64
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                64
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_60_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                60
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                60
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_60_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                60
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                60
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_03_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                3
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                3
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_03_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                3
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                3
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_14_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                14
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                14
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_14_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                14
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                14
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_02_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                2
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                2
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_02_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                2
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                2
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_01_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                1
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                1
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="339.9"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_01_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                1
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                1
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="339.9"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_08_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                8
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                8
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="270.9"
          width="8"
          height="69"
        />
        <rect
          id="gate_08_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                8
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                8
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="270.9"
          width="8"
          height="69"
        />
        <rect
          id="gate_23_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                23
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                23
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_23_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                23
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                23
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_43_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                43
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                43
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_43_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                43
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                43
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_61_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                61
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                61
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="73.9"
          width="8"
          height="32"
        />
        <rect
          id="gate_61_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                61
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                61
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="73.9"
          width="8"
          height="32"
        />
        <rect
          id="gate_52_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                52
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                52
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_52_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                52
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                52
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_09_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                9
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                9
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_09_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                9
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                9
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_29_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                29
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                29
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_29_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                29
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                29
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_46_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                46
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                46
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_46_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                46
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                46
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_13_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                13
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                13
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_13_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                13
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                13
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_33_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                33
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                33
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_33_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                33
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                33
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_56_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                56
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                56
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_56_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                56
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                56
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_11_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                11
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                11
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_11_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                11
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                11
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_04_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                4
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                4
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_04_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                4
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                4
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />

        <rect
          id="gate_63_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                63
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                63
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_63_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                63
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                63
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_53_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                53
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                53
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_53_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                53
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                53
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_47_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                47
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                47
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_47_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                47
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                47
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_24_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                24
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                24
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_24_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                24
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                24
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="105.9"
          width="8"
          height="39.5"
        />
      </g>
    </g>
    <g
      id="layer7"
      inkscape:groupmode="layer"
      inkscape:label="Gates Design"
      sodipodi:insensitive="true"
    >
      <g id="Слой_15" transform="translate(31.134997,17.409983)">
        <rect
          id="gate_41_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 41) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 41) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="377.8"
          y="690.3"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -346.1888 440.5635)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_41_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                41
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="377.8"
          y="690.3"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -346.1888 440.5635)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_41_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 41) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 41) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="377.8"
          y="690.3"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -346.1888 440.5635)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_41_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                41
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="377.8"
          y="690.3"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -346.1888 440.5635)"
          width="104.7"
          height="8"
        />

        <rect
          id="gate_30_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 30) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 30) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="458"
          y="623.5"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -284.4468 475.7161)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_30_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                30
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="458"
          y="623.5"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -284.4468 475.7161)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_30_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 30) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 30) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="458"
          y="623.5"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -284.4468 475.7161)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_30_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                30
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="458"
          y="623.5"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -284.4468 475.7161)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_55_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 55) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 55) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="450.9"
          y="603"
          transform="matrix(0.7653 -0.6437 0.6437 0.7653 -272.8276 465.8037)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_55_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                55
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="450.9"
          y="603"
          transform="matrix(0.7653 -0.6437 0.6437 0.7653 -272.8276 465.8037)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_55_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 55) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 55) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="450.9"
          y="603"
          transform="matrix(0.7653 -0.6437 0.6437 0.7653 -272.8276 465.8037)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_55_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                55
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="450.9"
          y="603"
          transform="matrix(0.7653 -0.6437 0.6437 0.7653 -272.8276 465.8037)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_49_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 49) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 49) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="434.7"
          y="588.2"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_49_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                49
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="434.7"
          y="588.2"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_49_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 49) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 49) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="434.7"
          y="588.2"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
          width="102.8"
          height="8"
        />
        <rect
          id="gate_49_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                49
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="434.7"
          y="588.2"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
          width="102.8"
          height="8"
        />

        <rect
          id="gate_39_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 39) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 39) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="370.6"
          y="669.7"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -334.6085 431.0764)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_39_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                39
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="370.6"
          y="669.7"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -334.6085 431.0764)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_39_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 39) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 39) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="370.6"
          y="669.7"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -334.6085 431.0764)"
          width="104.7"
          height="8"
        />
        <rect
          id="gate_39_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                39
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="370.6"
          y="669.7"
          transform="matrix(0.7647 -0.6444 0.6444 0.7647 -334.6085 431.0764)"
          width="104.7"
          height="8"
        />

        <rect
          id="gate_19_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 19) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 19) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="354.3"
          y="655"
          transform="matrix(0.7651 -0.644 0.644 0.7651 -328.8389 416.7515)"
          width="104.8"
          height="8"
        />
        <rect
          id="gate_19_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                19
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="354.3"
          y="655"
          transform="matrix(0.7651 -0.644 0.644 0.7651 -328.8389 416.7515)"
          width="104.8"
          height="8"
        />
        <rect
          id="gate_19_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 19) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 19) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="354.3"
          y="655"
          transform="matrix(0.7651 -0.644 0.644 0.7651 -328.8389 416.7515)"
          width="104.8"
          height="8"
        />
        <rect
          id="gate_19_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                19
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="354.3"
          y="655"
          transform="matrix(0.7651 -0.644 0.644 0.7651 -328.8389 416.7515)"
          width="104.8"
          height="8"
        />

        <rect
          id="gate_58_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 58) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 58) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="266.2"
          y="640.5"
          transform="matrix(0.6444 -0.7647 0.7647 0.6444 -436.9482 454.4994)"
          width="8"
          height="113.1"
        />
        <rect
          id="gate_58_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                58
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="266.2"
          y="640.5"
          transform="matrix(0.6444 -0.7647 0.7647 0.6444 -436.9482 454.4994)"
          width="8"
          height="113.1"
        />
        <rect
          id="gate_58_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 58) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 58) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="266.2"
          y="640.5"
          transform="matrix(0.6444 -0.7647 0.7647 0.6444 -436.9482 454.4994)"
          width="8"
          height="113.1"
        />
        <rect
          id="gate_58_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                58
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="266.2"
          y="640.5"
          transform="matrix(0.6444 -0.7647 0.7647 0.6444 -436.9482 454.4994)"
          width="8"
          height="113.1"
        />

        <rect
          id="gate_38_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 38) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 38) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="274.4"
          y="626.4"
          transform="matrix(0.6411 -0.7674 0.7674 0.6411 -421.1985 457.324)"
          width="8"
          height="105.3"
        />
        <rect
          id="gate_38_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                38
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="274.4"
          y="626.4"
          transform="matrix(0.6411 -0.7674 0.7674 0.6411 -421.1985 457.324)"
          width="8"
          height="105.3"
        />
        <rect
          id="gate_38_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 38) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 38) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="274.4"
          y="626.4"
          transform="matrix(0.6411 -0.7674 0.7674 0.6411 -421.1985 457.324)"
          width="8"
          height="105.3"
        />
        <rect
          id="gate_38_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                38
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="274.4"
          y="626.4"
          transform="matrix(0.6411 -0.7674 0.7674 0.6411 -421.1985 457.324)"
          width="8"
          height="105.3"
        />

        <rect
          id="gate_54_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 54) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 54) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="287.4"
          y="607"
          transform="matrix(0.6438 -0.7652 0.7652 0.6438 -400.7362 457.8434)"
          width="8"
          height="104.7"
        />
        <rect
          id="gate_54_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                54
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="287.4"
          y="607"
          transform="matrix(0.6438 -0.7652 0.7652 0.6438 -400.7362 457.8434)"
          width="8"
          height="104.7"
        />
        <rect
          id="gate_54_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 54) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 54) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="287.4"
          y="607"
          transform="matrix(0.6438 -0.7652 0.7652 0.6438 -400.7362 457.8434)"
          width="8"
          height="104.7"
        />
        <rect
          id="gate_54_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                54
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="287.4"
          y="607"
          transform="matrix(0.6438 -0.7652 0.7652 0.6438 -400.7362 457.8434)"
          width="8"
          height="104.7"
        />

        <rect
          id="gate_36_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 36) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 36) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="505.1"
          y="388.5"
          transform="matrix(0.8623 -0.5064 0.5064 0.8623 -167.1954 322.335)"
          width="8"
          height="160.2"
        />
        <rect
          id="gate_36_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                36
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="505.1"
          y="388.5"
          transform="matrix(0.8623 -0.5064 0.5064 0.8623 -167.1954 322.335)"
          width="8"
          height="160.2"
        />
        <rect
          id="gate_36_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 36) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 36) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="505.1"
          y="388.5"
          transform="matrix(0.8623 -0.5064 0.5064 0.8623 -167.1954 322.335)"
          width="8"
          height="160.2"
        />
        <rect
          id="gate_36_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                36
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="505.1"
          y="388.5"
          transform="matrix(0.8623 -0.5064 0.5064 0.8623 -167.1954 322.335)"
          width="8"
          height="160.2"
        />

        <rect
          id="gate_48_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 48) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 48) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="110.3"
          y="464.6"
          transform="matrix(0.5071 -0.8619 0.8619 0.5071 -310.0302 395.0333)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_48_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                48
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="110.3"
          y="464.6"
          transform="matrix(0.5071 -0.8619 0.8619 0.5071 -310.0302 395.0333)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_48_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 48) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 48) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="110.3"
          y="464.6"
          transform="matrix(0.5071 -0.8619 0.8619 0.5071 -310.0302 395.0333)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_48_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                48
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="110.3"
          y="464.6"
          transform="matrix(0.5071 -0.8619 0.8619 0.5071 -310.0302 395.0333)"
          width="160.1"
          height="8"
        />
        <path
          id="gate_45_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 45) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 45) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M416,378.7c-23.7-40.6-35.9-61.4-36.1-61.8l3.6-1.8l3.5-1.9c0.8,1.4,23.8,40.8,35.9,61.5L416,378.7z"
        />
        <path
          id="gate_45_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                45
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M416,378.7c-23.7-40.6-35.9-61.4-36.1-61.8l3.6-1.8l3.5-1.9c0.8,1.4,23.8,40.8,35.9,61.5L416,378.7z"
        />
        <path
          id="gate_45_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 45) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 45) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          d="M416,378.7c-23.7-40.6-35.9-61.4-36.1-61.8l3.6-1.8l3.5-1.9c0.8,1.4,23.8,40.8,35.9,61.5L416,378.7z"
        />
        <path
          id="gate_45_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                45
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          d="M416,378.7c-23.7-40.6-35.9-61.4-36.1-61.8l3.6-1.8l3.5-1.9c0.8,1.4,23.8,40.8,35.9,61.5L416,378.7z"
        />

        <rect
          id="gate_21_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 21) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 21) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="434.2"
          y="371.6"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -146.5794 278.6158)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="73.6"
        />
        <rect
          id="gate_21_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                21
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="434.2"
          y="371.6"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -146.5794 278.6158)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="73.6"
        />
        <rect
          id="gate_21_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 21) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 21) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="434.2"
          y="371.6"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -146.5794 278.6158)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="73.6"
        />
        <rect
          id="gate_21_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                21
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="434.2"
          y="371.6"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -146.5794 278.6158)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="73.6"
        />

        <rect
          id="gate_22_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 22) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 22) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="489.7"
          y="392.9"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -171.6475 315.6749)"
          width="8"
          height="160.1"
        />
        <rect
          id="gate_22_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                22
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="489.7"
          y="392.9"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -171.6475 315.6749)"
          width="8"
          height="160.1"
        />
        <rect
          id="gate_22_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 22) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 22) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="489.7"
          y="392.9"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -171.6475 315.6749)"
          width="8"
          height="160.1"
        />
        <rect
          id="gate_22_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                22
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="489.7"
          y="392.9"
          transform="matrix(0.8619 -0.5071 0.5071 0.8619 -171.6475 315.6749)"
          width="8"
          height="160.1"
        />

        <rect
          id="gate_57_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[57])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 57) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 57) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="129.5"
          y="466.3"
          transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1018 412.7197)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_57_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[57])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                57
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="129.5"
          y="466.3"
          transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1018 412.7197)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_57_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[57])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 57) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 57) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="129.5"
          y="466.3"
          transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1018 412.7197)"
          width="160.1"
          height="8"
        />
        <rect
          id="gate_57_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[57])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                57
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="129.5"
          y="466.3"
          transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1018 412.7197)"
          width="160.1"
          height="8"
        />

        <rect
          id="gate_18_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 18) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 18) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="183.7"
          y="576.1"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.184 366.8567)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_18_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                18
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="183.7"
          y="576.1"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.184 366.8567)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_18_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 18) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 18) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="183.7"
          y="576.1"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.184 366.8567)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_18_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                18
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="183.7"
          y="576.1"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.184 366.8567)"
          width="8"
          height="102.8"
        />

        <rect
          id="gate_28_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 28) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 28) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="194.8"
          y="560.9"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.6078 369.9385)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_28_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                28
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="194.8"
          y="560.9"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.6078 369.9385)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_28_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 28) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 28) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="194.8"
          y="560.9"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.6078 369.9385)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_28_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                28
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="194.8"
          y="560.9"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.6078 369.9385)"
          width="8"
          height="102.8"
        />

        <rect
          id="gate_32_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 32) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 32) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="208"
          y="541.2"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8424 373.0252)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_32_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                32
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="208"
          y="541.2"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8424 373.0252)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_32_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 32) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 32) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="208"
          y="541.2"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8424 373.0252)"
          width="8"
          height="102.8"
        />
        <rect
          id="gate_32_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                32
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="208"
          y="541.2"
          transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8424 373.0252)"
          width="8"
          height="102.8"
        />

        <rect
          id="gate_35_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 35) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 35) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="424.1"
          y="250.6"
          transform="matrix(0.8634 -0.5045 0.5045 0.8634 -108.3136 261.1331)"
          width="8"
          height="160"
        />
        <rect
          id="gate_35_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                35
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="424.1"
          y="250.6"
          transform="matrix(0.8634 -0.5045 0.5045 0.8634 -108.3136 261.1331)"
          width="8"
          height="160"
        />
        <rect
          id="gate_35_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 35) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 35) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="424.1"
          y="250.6"
          transform="matrix(0.8634 -0.5045 0.5045 0.8634 -108.3136 261.1331)"
          width="8"
          height="160"
        />
        <rect
          id="gate_35_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                35
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="424.1"
          y="250.6"
          transform="matrix(0.8634 -0.5045 0.5045 0.8634 -108.3136 261.1331)"
          width="8"
          height="160"
        />
        <path
          id="gate_16_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 16) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 16) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M234.5,401.6l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C312.4,268.6,270,341.1,234.5,401.6z"
        />
        <path
          id="gate_16_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                16
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M234.5,401.6l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C312.4,268.6,270,341.1,234.5,401.6z"
        />
        <path
          id="gate_16_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 16) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 16) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          d="M234.5,401.6l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C312.4,268.6,270,341.1,234.5,401.6z"
        />
        <path
          id="gate_16_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                16
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          d="M234.5,401.6l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C312.4,268.6,270,341.1,234.5,401.6z"
        />
        <path
          id="gate_20_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 20) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 20) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
        />
        <path
          id="gate_20_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
        />
        <path
          id="gate_20_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 20) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 20) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
        />
        <path
          id="gate_20_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                2
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.8,78.2-133.7l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
        />
        <rect
          id="gate_10_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 10) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 10) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="247"
          y="397.9"
          width="49.2"
          height="8"
        />
        <rect
          id="gate_10_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="247"
          y="397.9"
          width="49.2"
          height="8"
        />
        <rect
          id="gate_10_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 10) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 10) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="247"
          y="397.9"
          width="49.2"
          height="8"
        />
        <rect
          id="gate_10_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="247"
          y="397.9"
          width="49.2"
          height="8"
        />
        <g>
          <rect
            id="gate_34_design"
            @click="openTooltipsDialog(compoSite.chart.gatesExt[34])"
            :class="{
              visible:
                isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 34) &&
                !isChartTurnOn &&
                !isChart2TurnOn,
              st38:
                isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 34) &&
                isChartTurnOn &&
                !isChart2TurnOn
            }"
            class="st9 invisible cursor-pointer over-shadow-anim"
            x="244.2"
            y="471.3"
            transform="matrix(0.3516 -0.9362 0.9362 0.3516 -340.7274 579.77)"
            width="8"
            height="129.1"
          />
          <rect
            id="gate_34_both"
            @click="openTooltipsDialog(compoSite.chart.gatesExt[34])"
            :class="{
              visible:
                isDefinedObj(
                  compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                  34
                ) &&
                !isChartTurnOn &&
                !isChart2TurnOn
            }"
            class="st9 invisible cursor-pointer over-shadow-anim"
            x="244.2"
            y="471.3"
            transform="matrix(0.3516 -0.9362 0.9362 0.3516 -340.7274 579.77)"
            width="8"
            height="129.1"
          />
          <rect
            id="gate_34_design2"
            @click="openTooltipsDialog(compoSite.chart2.gatesExt[34])"
            :class="{
              visible:
                isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 34) &&
                !isChartTurnOn &&
                !isChart2TurnOn,
              st38:
                isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 34) &&
                !isChartTurnOn &&
                isChart2TurnOn
            }"
            class="st10 invisible cursor-pointer over-shadow-anim"
            x="244.2"
            y="471.3"
            transform="matrix(0.3516 -0.9362 0.9362 0.3516 -340.7274 579.77)"
            width="8"
            height="129.1"
          />
          <rect
            id="gate_34_both2"
            @click="openTooltipsDialog(compoSite.chart2.gatesExt[34])"
            :class="{
              visible:
                isDefinedObj(
                  compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                  34
                ) &&
                !isChartTurnOn &&
                !isChart2TurnOn
            }"
            class="st10 invisible cursor-pointer over-shadow-anim"
            x="244.2"
            y="471.3"
            transform="matrix(0.3516 -0.9362 0.9362 0.3516 -340.7274 579.77)"
            width="8"
            height="129.1"
          />
        </g>

        <rect
          id="gate_27_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 27) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 27) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="282.2"
          y="556.2"
          transform="matrix(0.286 -0.9582 0.9582 0.286 -351.5284 688.4722)"
          width="8"
          height="47.9"
        />
        <rect
          id="gate_27_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                27
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="282.2"
          y="556.2"
          transform="matrix(0.286 -0.9582 0.9582 0.286 -351.5284 688.4722)"
          width="8"
          height="47.9"
        />
        <rect
          id="gate_27_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 27) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 27) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="282.2"
          y="556.2"
          transform="matrix(0.286 -0.9582 0.9582 0.286 -351.5284 688.4722)"
          width="8"
          height="47.9"
        />
        <rect
          id="gate_27_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                27
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="282.2"
          y="556.2"
          transform="matrix(0.286 -0.9582 0.9582 0.286 -351.5284 688.4722)"
          width="8"
          height="47.9"
        />

        <rect
          id="gate_59_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 59) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 59) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="380.6"
          y="575.9"
          transform="matrix(0.9624 -0.2717 0.2717 0.9624 -142.3351 131.7713)"
          width="48.2"
          height="8"
        />
        <rect
          id="gate_59_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                59
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="380.6"
          y="575.9"
          transform="matrix(0.9624 -0.2717 0.2717 0.9624 -142.3351 131.7713)"
          width="48.2"
          height="8"
        />
        <rect
          id="gate_59_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 59) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 59) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="380.6"
          y="575.9"
          transform="matrix(0.9624 -0.2717 0.2717 0.9624 -142.3351 131.7713)"
          width="48.2"
          height="8"
        />
        <rect
          id="gate_59_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                59
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="380.6"
          y="575.9"
          transform="matrix(0.9624 -0.2717 0.2717 0.9624 -142.3351 131.7713)"
          width="48.2"
          height="8"
        />
        <rect
          id="gate_50_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 50) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 50) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="234.7"
          y="539.5"
          transform="matrix(0.2742 -0.9617 0.9617 0.2742 -370.7557 640.1635)"
          width="8"
          height="52.5"
        />
        <rect
          id="gate_50_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                50
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="234.7"
          y="539.5"
          transform="matrix(0.2742 -0.9617 0.9617 0.2742 -370.7557 640.1635)"
          width="8"
          height="52.5"
        />
        <rect
          id="gate_50_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 50) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 50) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="234.7"
          y="539.5"
          transform="matrix(0.2742 -0.9617 0.9617 0.2742 -370.7557 640.1635)"
          width="8"
          height="52.5"
        />
        <rect
          id="gate_50_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                50
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="234.7"
          y="539.5"
          transform="matrix(0.2742 -0.9617 0.9617 0.2742 -370.7557 640.1635)"
          width="8"
          height="52.5"
        />

        <rect
          id="gate_06_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 6) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 6) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="426.9"
          y="562.1"
          transform="matrix(0.9626 -0.271 0.271 0.9626 -136.4507 144.0277)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="52.8"
          height="8"
        />
        <rect
          id="gate_06_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                6
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="426.9"
          y="562.1"
          transform="matrix(0.9626 -0.271 0.271 0.9626 -136.4507 144.0277)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="52.8"
          height="8"
        />
        <rect
          id="gate_06_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 6) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 6) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="426.9"
          y="562.1"
          transform="matrix(0.9626 -0.271 0.271 0.9626 -136.4507 144.0277)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="52.8"
          height="8"
        />
        <rect
          id="gate_06_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                6
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="426.9"
          y="562.1"
          transform="matrix(0.9626 -0.271 0.271 0.9626 -136.4507 144.0277)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="52.8"
          height="8"
        />
        <rect
          id="gate_37_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 37) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 37) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="504"
          y="511.8"
          transform="matrix(0.8602 -0.51 0.51 0.8602 -198.3524 332.934)"
          width="8"
          height="32.8"
        />
        <rect
          id="gate_37_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                37
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="504"
          y="511.8"
          transform="matrix(0.8602 -0.51 0.51 0.8602 -198.3524 332.934)"
          width="8"
          height="32.8"
        />
        <rect
          id="gate_37_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 37) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 37) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="504"
          y="511.8"
          transform="matrix(0.8602 -0.51 0.51 0.8602 -198.3524 332.934)"
          width="8"
          height="32.8"
        />
        <rect
          id="gate_37_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                37
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="504"
          y="511.8"
          transform="matrix(0.8602 -0.51 0.51 0.8602 -198.3524 332.934)"
          width="8"
          height="32.8"
        />

        <rect
          id="gate_26_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 26) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 26) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="301.9"
          y="481.5"
          transform="matrix(0.9363 -0.3513 0.3513 0.9363 -147.6308 157.3058)"
          width="115.6"
          height="8"
        />
        <rect
          id="gate_26_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                26
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="301.9"
          y="481.5"
          transform="matrix(0.9363 -0.3513 0.3513 0.9363 -147.6308 157.3058)"
          width="115.6"
          height="8"
        />
        <rect
          id="gate_26_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 26) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 26) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="301.9"
          y="481.5"
          transform="matrix(0.9363 -0.3513 0.3513 0.9363 -147.6308 157.3058)"
          width="115.6"
          height="8"
        />
        <rect
          id="gate_26_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                26
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="301.9"
          y="481.5"
          transform="matrix(0.9363 -0.3513 0.3513 0.9363 -147.6308 157.3058)"
          width="115.6"
          height="8"
        />

        <rect
          id="gate_40_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 40) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 40) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="486.6"
          y="480.8"
          transform="matrix(0.862 -0.5069 0.5069 0.862 -185.0396 317.4899)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="35.6"
        />
        <rect
          id="gate_40_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                40
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="486.6"
          y="480.8"
          transform="matrix(0.862 -0.5069 0.5069 0.862 -185.0396 317.4899)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="35.6"
        />
        <rect
          id="gate_40_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 40) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 40) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="486.6"
          y="480.8"
          transform="matrix(0.862 -0.5069 0.5069 0.862 -185.0396 317.4899)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="35.6"
        />
        <rect
          id="gate_40_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                40
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="486.6"
          y="480.8"
          transform="matrix(0.862 -0.5069 0.5069 0.862 -185.0396 317.4899)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="35.6"
        />

        <rect
          id="gate_25_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 25) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 25) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="400.3"
          y="399"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -172.1083 357.9811)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="31.5"
        />
        <rect
          id="gate_25_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                25
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="400.3"
          y="399"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -172.1083 357.9811)"
          class="st9 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="31.5"
        />
        <rect
          id="gate_25_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 25) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 25) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="400.3"
          y="399"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -172.1083 357.9811)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="31.5"
        />
        <rect
          id="gate_25_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                25
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="400.3"
          y="399"
          transform="matrix(0.7648 -0.6442 0.6442 0.7648 -172.1083 357.9811)"
          class="st10 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="31.5"
        />

        <rect
          id="gate_51_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 51) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 51) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="421.3"
          y="422.9"
          transform="matrix(0.765 -0.644 0.644 0.765 -183.0686 377.1554)"
          width="8"
          height="33.1"
        />
        <rect
          id="gate_51_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                51
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="421.3"
          y="422.9"
          transform="matrix(0.765 -0.644 0.644 0.765 -183.0686 377.1554)"
          width="8"
          height="33.1"
        />
        <rect
          id="gate_51_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 51) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 51) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="421.3"
          y="422.9"
          transform="matrix(0.765 -0.644 0.644 0.765 -183.0686 377.1554)"
          width="8"
          height="33.1"
        />
        <rect
          id="gate_51_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                51
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="421.3"
          y="422.9"
          transform="matrix(0.765 -0.644 0.644 0.765 -183.0686 377.1554)"
          width="8"
          height="33.1"
        />

        <rect
          id="gate_44_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 44) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 44) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="189.8"
          y="522.8"
          transform="matrix(0.9363 -0.3511 0.3511 0.9363 -169.0688 121.173)"
          width="119.6"
          height="8"
        />
        <rect
          id="gate_44_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                44
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          x="189.8"
          y="522.8"
          transform="matrix(0.9363 -0.3511 0.3511 0.9363 -169.0688 121.173)"
          width="119.6"
          height="8"
        />
        <rect
          id="gate_44_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 44) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 44) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="189.8"
          y="522.8"
          transform="matrix(0.9363 -0.3511 0.3511 0.9363 -169.0688 121.173)"
          width="119.6"
          height="8"
        />
        <rect
          id="gate_44_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                44
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st10 invisible cursor-pointer over-shadow-anim"
          x="189.8"
          y="522.8"
          transform="matrix(0.9363 -0.3511 0.3511 0.9363 -169.0688 121.173)"
          width="119.6"
          height="8"
        />
      </g>
      <g id="Слой_11-2" transform="translate(31.140015,17.409983)">
        <rect
          id="gate_42_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 42) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 42) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_42_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                42
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_42_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 42) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 42) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_42_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                42
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="570.9"
          width="7.9"
          height="57"
        />
        <rect
          id="gate_05_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 5) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 5) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_05_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                5
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_05_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 5) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 5) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_05_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                5
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="483.9"
          width="7.9"
          height="87"
        />
        <rect
          id="gate_15_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 15) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 15) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_15_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                15
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_15_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 15) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 15) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_15_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                15
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="401.4"
          width="7.9"
          height="82.5"
        />
        <rect
          id="gate_07_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 7) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 7) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_07_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                7
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_07_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 7) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 7) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_07_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                7
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="339.9"
          width="7.9"
          height="61.5"
        />
        <rect
          id="gate_31_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 31) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 31) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_31_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                31
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_31_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 31) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 31) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_31_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                31
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="270.9"
          width="7.9"
          height="69"
        />
        <rect
          id="gate_62_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 62) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 62) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_62_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                62
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_62_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 62) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 62) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_62_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                62
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="214.9"
          width="7.9"
          height="56"
        />
        <rect
          id="gate_17_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 17) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 17) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_17_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                17
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_17_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 17) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 17) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_17_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                17
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="145.4"
          width="7.9"
          height="69.5"
        />
        <rect
          id="gate_64_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 64) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 64) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_64_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                64
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_64_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 64) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 64) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_64_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                64
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="73.9"
          width="7.9"
          height="32"
        />
        <rect
          id="gate_60_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 60) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 60) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_60_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                60
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_60_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 60) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 60) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_60_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                60
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_03_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 3) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 3) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_03_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                3
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_03_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 3) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 3) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_03_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                3
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_14_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 14) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 14) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_14_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                14
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_14_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 14) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 14) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_14_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                14
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_02_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 2) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 2) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_02_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                2
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_02_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 2) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 2) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_02_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                2
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_01_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 1) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 1) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="339.9"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_01_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                1
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="339.9"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_01_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 1) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 1) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="339.9"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_01_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                1
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="339.9"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_08_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 8) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 8) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="270.9"
          width="8"
          height="69"
        />
        <rect
          id="gate_08_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                8
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="270.9"
          width="8"
          height="69"
        />
        <rect
          id="gate_08_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 8) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 8) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="270.9"
          width="8"
          height="69"
        />
        <rect
          id="gate_08_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                8
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="270.9"
          width="8"
          height="69"
        />
        <rect
          id="gate_23_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 23) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 23) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_23_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                23
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_23_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 23) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 23) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_23_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                23
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_43_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 43) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 43) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_43_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                43
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_43_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 43) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 43) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_43_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                43
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_61_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 61) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 61) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="344.9"
          y="73.9"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="32"
        />
        <rect
          id="gate_61_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                61
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="344.9"
          y="73.9"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="32"
        />
        <rect
          id="gate_61_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 61) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 61) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="344.9"
          y="73.9"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="32"
        />
        <rect
          id="gate_61_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                61
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="344.9"
          y="73.9"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="32"
        />
        <rect
          id="gate_52_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 52) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 52) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_52_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                52
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_52_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 52) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 52) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_52_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                52
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="627.9"
          width="8"
          height="79"
        />
        <rect
          id="gate_09_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 9) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 9) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_09_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                9
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_09_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 9) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 9) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_09_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                9
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="570.9"
          width="8"
          height="57"
        />
        <rect
          id="gate_29_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 29) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 29) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_29_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                29
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_29_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 29) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 29) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_29_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                29
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="483.9"
          width="8"
          height="87"
        />
        <rect
          id="gate_46_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 46) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 46) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_46_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                46
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_46_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 46) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 46) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_46_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                46
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="401.4"
          width="8"
          height="82.5"
        />
        <rect
          id="gate_13_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 13) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 13) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="365.4"
          y="339.9"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_13_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                13
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="365.4"
          y="339.9"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_13_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 13) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 13) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="365.4"
          y="339.9"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_13_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                13
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="365.4"
          y="339.9"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="61.5"
        />
        <rect
          id="gate_33_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 33) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 33) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="365.4"
          y="270.9"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="69"
        />
        <rect
          id="gate_33_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                33
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="365.4"
          y="270.9"
          class="st28 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="69"
        />
        <rect
          id="gate_33_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 33) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 33) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          x="365.4"
          y="270.9"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="69"
        />
        <rect
          id="gate_33_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                33
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          x="365.4"
          y="270.9"
          class="st27 invisible cursor-pointer over-shadow-anim"
          width="8"
          height="69"
        />
        <rect
          id="gate_56_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 56) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 56) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_56_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                56
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_56_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 56) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 56) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_56_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                56
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="214.9"
          width="8"
          height="56"
        />
        <rect
          id="gate_11_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 11) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 11) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_11_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                11
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_11_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 11) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 11) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_11_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                11
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="145.4"
          width="8"
          height="69.5"
        />
        <rect
          id="gate_04_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 4) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 4) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_04_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                4
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_04_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 4) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 4) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_04_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                4
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="105.9"
          width="8"
          height="39.5"
        />

        <rect
          id="gate_63_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 63) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 63) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="73.9"
          width="8"
          height="32"
        />
        <rect
          id="gate_63_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                63
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="73.9"
          width="8"
          height="32"
        />
        <rect
          id="gate_63_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 63) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 63) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="73.9"
          width="8"
          height="32"
        />
        <rect
          id="gate_63_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                63
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="365.4"
          y="73.9"
          width="8"
          height="32"
        />
        <rect
          id="gate_53_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 53) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 53) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_53_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                53
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_53_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 53) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 53) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_53_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                53
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="627.9"
          width="7.9"
          height="79"
        />
        <rect
          id="gate_47_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 47) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 47) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_47_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                47
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_47_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 47) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 47) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_47_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                47
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="324.4"
          y="105.9"
          width="7.9"
          height="39.5"
        />
        <rect
          id="gate_24_design"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 24) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart.gatesActivatedAtDesignOnly, 24) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_24_both"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                24
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_24_design2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 24) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st38:
              isDefinedObj(compoSite.chart2.gatesActivatedAtDesignOnly, 24) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="105.9"
          width="8"
          height="39.5"
        />
        <rect
          id="gate_24_both2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                24
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="344.9"
          y="105.9"
          width="8"
          height="39.5"
        />
      </g>
    </g>
    <g
      id="layer6"
      inkscape:groupmode="layer"
      inkscape:label="Gates Connection"
      sodipodi:insensitive="true"
    >
      <g id="Слой_16" transform="translate(31.134997,17.409983)">
        <g
          id="gate_44_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 44) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="189.7"
            y="522.8"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -169.1191 121.1813)"
            class="st27"
            width="119.6"
            height="8"
          />

          <rect
            x="190.4"
            y="526.7"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -169.7418 121.5482)"
            class="st9"
            width="119.6"
            height="4"
          />
        </g>
        <g
          id="gate_41_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 41) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="377.7"
            y="690.4"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -346.1918 440.363)"
            class="st27"
            width="104.8"
            height="8"
          />
          <polygon
            class="st9"
            points="389.9,728 470.2,660.6 472.7,663.7 392.5,731.1 			"
          />
        </g>
        <g
          id="gate_58_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 58) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="266.3"
            y="640.6"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -437.0297 454.8037)"
            class="st27"
            width="8"
            height="113"
          />

          <rect
            x="267"
            y="642"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -438.6014 454.325)"
            class="st9"
            width="4"
            height="113.1"
          />
        </g>
        <g
          id="gate_30_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 30) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="458.1"
            y="623.5"
            transform="matrix(0.7646 -0.6445 0.6445 0.7646 -284.5009 476.052)"
            class="st27"
            width="102.7"
            height="8"
          />

          <rect
            x="459.3"
            y="627.1"
            transform="matrix(0.7646 -0.6445 0.6445 0.7646 -285.2496 477.2019)"
            class="st9"
            width="102.7"
            height="4"
          />
        </g>
        <g
          id="gate_18_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 18) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="183.7"
            y="576.1"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.1847 366.8568)"
            class="st27"
            width="8"
            height="102.8"
          />

          <rect
            x="184.4"
            y="577.6"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -414.7946 366.3961)"
            class="st9"
            width="4"
            height="102.8"
          />
        </g>

        <rect
          id="gate_48_person"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                48
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityOnly,
                48
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st28 invisible cursor-pointer over-shadow-anim"
          x="110.9"
          y="464.2"
          transform="matrix(0.5074 -0.8617 0.8617 0.5074 -309.5637 394.9042)"
          width="159.4"
          height="8"
        />
        <rect
          id="gate_48_person2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                48
              ) &&
              !isChartTurnOn &&
              !isChart2TurnOn,
            st39:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityOnly,
                48
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          x="110.9"
          y="464.2"
          transform="matrix(0.5074 -0.8617 0.8617 0.5074 -309.5637 394.9042)"
          width="159.4"
          height="8"
        />
        <polygon
          id="gate_48_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 48) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          points="150.2,536.9 230.9,399.6 234.4,401.6 153.7,539 		"
        />
        <g
          id="gate_16_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 16) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M234.4,401.6l-6.9-4c26.4-45,76.7-130.7,78.2-133.6l2.7,1.4l4.6,2C312.4,268.6,269.9,341,234.4,401.6z"
          />
          <path
            class="st9"
            d="M234.4,401.6l-3.5-2c26.5-45.2,77-131.4,78.2-133.7l3.6,1.7C312.2,268.9,252.6,370.5,234.4,401.6z"
          />
        </g>
        <g
          id="gate_35_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 35) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M465.1,401.6c-35.5-60.5-80.7-137.4-81.2-138.7l3.7-1.6l3.6-1.8c1.5,2.9,54.5,93.1,80.9,138L465.1,401.6z"
          />
          <path
            class="st9"
            d="M465.1,401.6c-18.2-31.1-80.5-137.1-81.1-138.5l1.8-0.8l1.8-0.9c1.2,2.3,54.4,92.9,80.9,138.1L465.1,401.6z"
          />
        </g>
        <g
          id="gate_57_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 5) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="129.5"
            y="466.3"
            transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1188 412.7231)"
            class="st27"
            width="160.1"
            height="8"
          />

          <rect
            x="130.3"
            y="469.3"
            transform="matrix(0.5068 -0.862 0.862 0.5068 -302.5332 413.8016)"
            class="st9"
            width="160.1"
            height="4"
          />
        </g>
        <polygon
          id="gate_38_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 38) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
          points="235.6,648.5 240.8,642.4 321.4,709.8 316.3,716 		"
        />
        <g
          id="gate_55_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 55) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="451"
            y="602.9"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -272.8225 466.3625)"
            class="st27"
            width="102.8"
            height="8"
          />

          <rect
            x="452.2"
            y="606.4"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -273.5066 467.4883)"
            class="st9"
            width="102.8"
            height="4"
          />
        </g>
        <g
          id="gate_28_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 28) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="194.9"
            y="560.9"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.573 370.015)"
            class="st27"
            width="8"
            height="102.8"
          />
          <line class="st29" x1="159.5" y1="579.2" x2="238.1" y2="645.4" />

          <rect
            x="195.5"
            y="562.4"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -399.2184 369.4779)"
            class="st9"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_22_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 22) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="489.7"
            y="392.9"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -171.5566 315.2886)"
            class="st27"
            width="8"
            height="160.1"
          />

          <rect
            x="490"
            y="393.9"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -172.2975 314.5652)"
            class="st9"
            width="4"
            height="160.1"
          />
        </g>
        <g
          id="gate_12_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 12) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M449.7,406c-35.5-60.5-78-133-78.5-134.3l3.7-1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.6L449.7,406z"
          />
          <path
            class="st9"
            d="M449.7,406c-18.2-31.1-77.8-132.7-78.4-134.1l1.8-0.8l1.8-0.9c1.2,2.3,51.7,88.5,78.2,133.7L449.7,406z"
          />
        </g>
        <g
          id="gate_20_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 20) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.7,78.2-133.6l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
          />
          <path
            class="st9"
            d="M253.6,403.3l-3.5-2c26.5-45.2,77-131.4,78.2-133.7l3.6,1.7C331.4,270.6,271.8,372.2,253.6,403.3z"
          />
        </g>
        <g
          id="gate_19_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 19) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="354.3"
            y="655"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -328.8897 416.9642)"
            class="st27"
            width="104.8"
            height="8"
          />

          <rect
            x="355.7"
            y="658.6"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -329.5912 418.2423)"
            class="st9"
            width="104.8"
            height="4"
          />
        </g>
        <g
          id="gate_49_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 49) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="434.7"
            y="588.2"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
            class="st27"
            width="102.8"
            height="8"
          />

          <rect
            x="436"
            y="591.7"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.8463 453.5954)"
            class="st9"
            width="102.8"
            height="4"
          />
        </g>
        <g
          id="gate_32_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 32) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="208.1"
            y="541.2"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8074 373.1017)"
            class="st27"
            width="8"
            height="102.8"
          />

          <rect
            x="208.7"
            y="542.7"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -379.4529 372.5647)"
            class="st9"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_27_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 27) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <polygon
            class="st27"
            points="262.8,576.9 265,569.2 310.7,583 308.4,590.6 			"
          />
          <polygon
            class="st9"
            points="262.8,577 263.9,573.1 309.5,586.8 308.4,590.6 			"
          />
        </g>
        <g
          id="gate_59_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 59) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="380.6"
            y="575.8"
            transform="matrix(0.9625 -0.2713 0.2713 0.9625 -142.1215 131.5405)"
            class="st27"
            width="48.2"
            height="8"
          />

          <rect
            x="381.2"
            y="579.8"
            transform="matrix(0.9624 -0.2715 0.2715 0.9624 -142.7344 131.8931)"
            class="st9"
            width="48.2"
            height="4"
          />
        </g>
        <g
          id="gate_50_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 50) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="234.7"
            y="539.5"
            transform="matrix(0.2713 -0.9625 0.9625 0.2713 -370.7979 642.1307)"
            class="st27"
            width="8"
            height="52.8"
          />
          <polygon
            class="st9"
            points="212,562.6 213.1,558.7 263.9,573.1 262.8,577 			"
          />
        </g>
        <g
          id="gate_06_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 6) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="427"
            y="562.1"
            transform="matrix(0.9625 -0.2713 0.2713 0.9625 -136.5782 144.239)"
            class="st27"
            width="52.8"
            height="8"
          />

          <rect
            x="427.5"
            y="566.1"
            transform="matrix(0.9624 -0.2715 0.2715 0.9624 -137.1893 144.5734)"
            class="st9"
            width="52.8"
            height="4"
          />
        </g>
        <g
          id="gate_37_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 37) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="504"
            y="511.7"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -198.208 332.5944)"
            class="st27"
            width="8"
            height="32.8"
          />

          <rect
            x="504.3"
            y="512.7"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -198.9549 331.8676)"
            class="st9"
            width="4"
            height="32.8"
          />
        </g>
        <g
          id="gate_40_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 40) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="486.6"
            y="480.9"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -185.6291 319.6164)"
            class="st27"
            width="8"
            height="35.5"
          />

          <rect
            x="486.9"
            y="482"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -186.427 318.9036)"
            class="st9"
            width="4"
            height="35.5"
          />
        </g>
        <g
          id="gate_26_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 26) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="301.9"
            y="481.5"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -147.598 157.2352)"
            class="st27"
            width="115.5"
            height="8"
          />

          <rect
            x="302.6"
            y="485.4"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -148.2207 157.6021)"
            class="st9"
            width="115.5"
            height="4"
          />
        </g>
        <g
          id="gate_34_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[34])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 34) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          transform="translate(-1)"
        >
          <rect
            x="245.2"
            y="471.5"
            transform="matrix(0.3513 -0.9363 0.9363 0.3513 -339.9839 580.8569)"
            class="st27"
            width="8"
            height="128.5"
          />

          <rect
            x="246.8"
            y="473.5"
            transform="matrix(0.3513 -0.9363 0.9363 0.3513 -342.1295 581.7713)"
            class="st9"
            width="4"
            height="128.5"
          />
        </g>
        <g
          id="gate_10_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 10) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          transform="translate(-0.75)"
        >
          <rect x="247" y="397.9" class="st27" width="49.5" height="8" />
          <rect x="247" y="401.9" class="st9" width="49.5" height="4" />
        </g>
        <g
          id="gate_51_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 51) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="421.2"
            y="423"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -183.0747 376.6263)"
            class="st27"
            width="8"
            height="33"
          />

          <rect
            x="421.7"
            y="424.2"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -184.1985 375.9426)"
            class="st9"
            width="4"
            height="33"
          />
        </g>

        <g
          id="gate_25_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 25) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="400.5"
            y="399"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -172.0043 357.5046)"
            class="st27"
            width="8"
            height="31.5"
          />

          <rect
            x="400.9"
            y="400.3"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -173.2158 356.78)"
            class="st9"
            width="4"
            height="31.5"
          />
        </g>
        <g
          id="gate_21_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 21) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="434.2"
            y="371.6"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -146.5297 278.2832)"
            class="st27"
            width="8"
            height="73.7"
          />

          <rect
            x="434.4"
            y="372.6"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -147.2844 277.5091)"
            class="st9"
            width="4"
            height="73.7"
          />
        </g>
        <g
          id="gate_45_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 45) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st27"
            d="M416.1,378.7c-35.9-61.1-36.1-61.7-36.3-62l3.7-1.6l3.5-1.8c0.9,1.7,26.3,44.9,35.9,61.4L416.1,378.7z"
          />
          <path
            class="st9"
            d="M416.1,378.7c-16.3-27.8-35.9-61.2-36.1-61.8l3.7-1.6l-1.8,0.8l1.8-0.9c0.7,1.4,23.8,40.8,36,61.5
              L416.1,378.7z"
          />
        </g>
        <g
          id="gate_36_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 36) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="505.1"
            y="388.5"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -167.2052 322.4839)"
            class="st27"
            width="8"
            height="160.1"
          />

          <rect
            x="505.3"
            y="389.5"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -167.9599 321.7098)"
            class="st9"
            width="4"
            height="160.1"
          />
        </g>

        <polygon
          id="gate_39_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 39) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
          points="383,707.6 463.1,640 465.6,643.1 385.6,710.7 		"
        />
        <g
          id="gate_54_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 54) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="287.3"
            y="607"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -400.7209 457.4514)"
            class="st27"
            width="8"
            height="104.8"
          />

          <rect
            x="288.2"
            y="608.5"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -402.2596 457.1438)"
            class="st9"
            width="4"
            height="104.8"
          />
        </g>
      </g>
      <g id="Слой_3" transform="translate(31.240005,17.409983)">
        <g
          id="gate_42_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 42) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="570.5" class="st27" width="8" height="57" />
          <rect x="324.3" y="570.5" class="st9" width="4" height="57" />
        </g>
        <g>
          <g
            id="gate_05_connection"
            @click="openTooltipsDialog(compoSite.connection.gatesExt[5])"
            :class="{
              visible:
                isDefinedObj(compoSite.connection.gates3, 5) &&
                !isChartTurnOn &&
                !isChart2TurnOn
            }"
            class="invisible cursor-pointer over-shadow-anim"
          >
            <rect x="324.3" y="483.5" class="st27" width="8" height="87" />
            <rect x="324.3" y="483.5" class="st9" width="4" height="87" />
          </g>
        </g>
        <g
          id="gate_15_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 15) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="400.8" class="st27" width="8" height="82.7" />
          <rect x="324.3" y="400.8" class="st9" width="4" height="82.7" />
        </g>
        <g
          id="gate_07_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 7) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="339.3" class="st27" width="8" height="61.5" />
          <rect x="324.3" y="339.3" class="st9" width="4" height="61.5" />
        </g>
        <g
          id="gate_31_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 31) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="270.3" class="st27" width="8" height="69" />
          <rect x="324.3" y="270.3" class="st9" width="4" height="69" />
        </g>
        <g
          id="gate_62_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 62) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="214.3" class="st27" width="8" height="56" />
          <rect x="324.3" y="214.3" class="st9" width="4" height="56" />
        </g>
        <g
          id="gate_17_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 17) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="144.8" class="st27" width="8" height="69.5" />
          <rect x="324.3" y="144.8" class="st9" width="4" height="69.5" />
        </g>
        <g
          id="gate_64_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 64) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="74" class="st27" width="8" height="31.8" />
          <rect x="324.3" y="74" class="st9" width="4" height="31.8" />
        </g>
        <g
          id="gate_03_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 3) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="570.9" class="st27" width="8" height="57" />
          <rect x="344.8" y="570.9" class="st9" width="4" height="57" />
        </g>
        <g
          id="gate_14_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 14) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="483.9" class="st27" width="8" height="87" />
          <rect x="344.8" y="483.9" class="st9" width="4" height="87" />
        </g>
        <g
          id="gate_02_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 2) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="401.4" class="st27" width="8" height="82.5" />
          <rect x="344.8" y="401.4" class="st9" width="4" height="82.5" />
        </g>
        <g
          id="gate_01_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 1) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="339.9" class="st27" width="8" height="61.5" />
          <rect x="344.8" y="339.9" class="st9" width="4" height="61.5" />
        </g>
        <g
          id="gate_08_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 8) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="270.9" class="st27" width="8" height="69" />
          <rect x="344.8" y="270.9" class="st9" width="4" height="69" />
        </g>
        <g
          id="gate_23_connection"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 23) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="214.9" class="st27" width="8" height="56" />
          <rect x="344.8" y="214.9" class="st9" width="4" height="56" />
        </g>
        <g
          id="gate_43_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 43) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="145.4" class="st27" width="8" height="69.5" />
          <rect x="344.8" y="145.4" class="st9" width="4" height="69.5" />
        </g>
        <g
          id="gate_61_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 61) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="73.9" class="st27" width="8" height="32" />
          <rect x="344.8" y="73.9" class="st9" width="4" height="32" />
        </g>
        <g
          id="gate_52_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 52) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="627.9" class="st27" width="8" height="79" />
          <rect x="365.3" y="627.9" class="st9" width="4" height="79" />
        </g>
        <g
          id="gate_09_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 9) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="570.9" class="st27" width="8" height="57" />
          <rect x="365.3" y="570.9" class="st9" width="4" height="57" />
        </g>
        <g
          id="gate_29_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 29) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="483.9" class="st27" width="8" height="87" />
          <rect x="365.3" y="483.9" class="st9" width="4" height="87" />
        </g>
        <g
          id="gate_46_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 46) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="401.4" class="st27" width="8" height="82.5" />
          <rect x="365.3" y="401.4" class="st9" width="4" height="82.5" />
        </g>
        <g
          id="gate_13_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 13) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="339.9" class="st27" width="8" height="61.5" />
          <rect x="365.3" y="339.9" class="st9" width="4" height="61.5" />
        </g>
        <g
          id="gate_33_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 33) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="270.9" class="st27" width="8" height="69" />
          <rect x="365.3" y="270.9" class="st9" width="4" height="69" />
        </g>
        <g
          id="gate_56_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 56) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="214.9" class="st27" width="8" height="56" />
          <rect x="365.3" y="214.9" class="st9" width="4" height="56" />
        </g>
        <g
          id="gate_11_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 11) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="145.4" class="st27" width="8" height="69.5" />
          <rect x="365.3" y="145.4" class="st9" width="4" height="69.5" />
        </g>
        <g
          id="gate_04_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 4) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="105.9" class="st27" width="8" height="39.5" />
          <rect x="365.3" y="105.9" class="st9" width="4" height="39.5" />
        </g>
        <g
          id="gate_63_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 63) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="73.9" class="st27" width="8" height="32" />
          <rect x="365.3" y="73.9" class="st9" width="4" height="32" />
        </g>
        <g
          id="gate_53_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 53) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="627.5" class="st27" width="8" height="79.4" />
          <rect x="324.3" y="627.5" class="st9" width="4" height="79.4" />
        </g>
        <g
          id="gate_47_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 47) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="105.3" class="st27" width="8" height="39.5" />
          <rect x="324.3" y="105.3" class="st9" width="4" height="39.5" />
        </g>
        <g
          id="gate_24_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 24) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="105.9" class="st27" width="8" height="39.5" />
          <rect x="344.8" y="105.9" class="st9" width="4" height="39.5" />
        </g>
        <g
          id="gate_60_connection"
          @click="openTooltipsDialog(compoSite.connection.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(compoSite.connection.gates3, 60) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="627.9" class="st27" width="8" height="79" />
          <rect x="344.8" y="627.9" class="st9" width="4" height="79" />
        </g>
      </g>
    </g>
    <g
      id="layer_Gates_Chart1_Both"
      inkscape:groupmode="layer"
      inkscape:label="Gates Both"
      sodipodi:insensitive="true"
    >
      <g
        id="Слой_16_Gates_Chart1_Both"
        transform="translate(31.134997,17.409983)"
      >
        <g
          id="gate_44_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                44
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="189.7"
            y="522.8"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -169.1191 121.1813)"
            class="st39"
            width="119.6"
            height="8"
          />

          <rect
            x="190.4"
            y="526.7"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -169.7418 121.5482)"
            class="st38"
            width="119.6"
            height="4"
          />
        </g>
        <g
          id="gate_41_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                41
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="377.7"
            y="690.4"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -346.1918 440.363)"
            class="st39"
            width="104.8"
            height="8"
          />
          <polygon
            class="st38"
            points="389.9,728 470.2,660.6 472.7,663.7 392.5,731.1 			"
          />
        </g>
        <g
          id="gate_58_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                58
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="266.3"
            y="640.6"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -437.0297 454.8037)"
            class="st39"
            width="8"
            height="113"
          />

          <rect
            x="267"
            y="642"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -438.6014 454.325)"
            class="st38"
            width="4"
            height="113.1"
          />
        </g>
        <g
          id="gate_30_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                30
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="458.1"
            y="623.5"
            transform="matrix(0.7646 -0.6445 0.6445 0.7646 -284.5009 476.052)"
            class="st39"
            width="102.7"
            height="8"
          />

          <rect
            x="459.3"
            y="627.1"
            transform="matrix(0.7646 -0.6445 0.6445 0.7646 -285.2496 477.2019)"
            class="st38"
            width="102.7"
            height="4"
          />
        </g>
        <g
          id="gate_18_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                18
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="183.7"
            y="576.1"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.1847 366.8568)"
            class="st39"
            width="8"
            height="102.8"
          />

          <rect
            x="184.4"
            y="577.6"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -414.7946 366.3961)"
            class="st38"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_16_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                16
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M234.4,401.6l-6.9-4c26.4-45,76.7-130.7,78.2-133.6l2.7,1.4l4.6,2C312.4,268.6,269.9,341,234.4,401.6z"
          />
          <path
            class="st38"
            d="M234.4,401.6l-3.5-2c26.5-45.2,77-131.4,78.2-133.7l3.6,1.7C312.2,268.9,252.6,370.5,234.4,401.6z"
          />
        </g>
        <g
          id="gate_48_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                48
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="110.9"
            y="464.2"
            transform="matrix(0.5074 -0.8617 0.8617 0.5074 -309.5637 394.9042)"
            class="st38"
            width="159.4"
            height="8"
          />
          <polygon
            class="st39"
            points="150.2,536.9 230.9,399.6 234.4,401.6 153.7,539 		"
          />
        </g>
        <g
          id="gate_35_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                35
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M465.1,401.6c-35.5-60.5-80.7-137.4-81.2-138.7l3.7-1.6l3.6-1.8c1.5,2.9,54.5,93.1,80.9,138L465.1,401.6z"
          />
          <path
            class="st38"
            d="M465.1,401.6c-18.2-31.1-80.5-137.1-81.1-138.5l1.8-0.8l1.8-0.9c1.2,2.3,54.4,92.9,80.9,138.1L465.1,401.6z"
          />
        </g>
        <g
          id="gate_57_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                5
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="129.5"
            y="466.3"
            transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1188 412.7231)"
            class="st39"
            width="160.1"
            height="8"
          />

          <rect
            x="130.3"
            y="469.3"
            transform="matrix(0.5068 -0.862 0.862 0.5068 -302.5332 413.8016)"
            class="st38"
            width="160.1"
            height="4"
          />
        </g>
        <g
          id="gate_38_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                38
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
        >
          <polygon
            class="st39"
            points="235.6,648.5 240.8,642.4 321.4,709.8 316.3,716 		"
          />
          <polygon
            class="st38"
            points="235.6,648.5 238.2,645.4 318.9,712.9 316.3,716 		"
          />
        </g>
        <g
          id="gate_55_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                55
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="451"
            y="602.9"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -272.8225 466.3625)"
            class="st39"
            width="102.8"
            height="8"
          />

          <rect
            x="452.2"
            y="606.4"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -273.5066 467.4883)"
            class="st38"
            width="102.8"
            height="4"
          />
        </g>
        <g
          id="gate_28_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                28
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="194.9"
            y="560.9"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.573 370.015)"
            class="st39"
            width="8"
            height="102.8"
          />
          <line class="st39" x1="159.5" y1="579.2" x2="238.1" y2="645.4" />

          <rect
            x="195.5"
            y="562.4"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -399.2184 369.4779)"
            class="st38"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_22_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                22
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="489.7"
            y="392.9"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -171.5566 315.2886)"
            class="st39"
            width="8"
            height="160.1"
          />

          <rect
            x="490"
            y="393.9"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -172.2975 314.5652)"
            class="st38"
            width="4"
            height="160.1"
          />
        </g>
        <g
          id="gate_12_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                12
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M449.7,406c-35.5-60.5-78-133-78.5-134.3l3.7-1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.6L449.7,406z"
          />
          <path
            class="st38"
            d="M449.7,406c-18.2-31.1-77.8-132.7-78.4-134.1l1.8-0.8l1.8-0.9c1.2,2.3,51.7,88.5,78.2,133.7L449.7,406z"
          />
        </g>
        <g
          id="gate_20_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.7,78.2-133.6l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
          />
          <path
            class="st38"
            d="M253.6,403.3l-3.5-2c26.5-45.2,77-131.4,78.2-133.7l3.6,1.7C331.4,270.6,271.8,372.2,253.6,403.3z"
          />
        </g>
        <g
          id="gate_19_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                19
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="354.3"
            y="655"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -328.8897 416.9642)"
            class="st39"
            width="104.8"
            height="8"
          />

          <rect
            x="355.7"
            y="658.6"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -329.5912 418.2423)"
            class="st38"
            width="104.8"
            height="4"
          />
        </g>
        <g
          id="gate_49_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                49
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="434.7"
            y="588.2"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
            class="st39"
            width="102.8"
            height="8"
          />

          <rect
            x="436"
            y="591.7"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.8463 453.5954)"
            class="st38"
            width="102.8"
            height="4"
          />
        </g>
        <g
          id="gate_32_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                32
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="208.1"
            y="541.2"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8074 373.1017)"
            class="st39"
            width="8"
            height="102.8"
          />

          <rect
            x="208.7"
            y="542.7"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -379.4529 372.5647)"
            class="st38"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_27_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                27
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <polygon
            class="st39"
            points="262.8,576.9 265,569.2 310.7,583 308.4,590.6 			"
          />
          <polygon
            class="st38"
            points="262.8,577 263.9,573.1 309.5,586.8 308.4,590.6 			"
          />
        </g>
        <g
          id="gate_59_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                59
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="380.6"
            y="575.8"
            transform="matrix(0.9625 -0.2713 0.2713 0.9625 -142.1215 131.5405)"
            class="st39"
            width="48.2"
            height="8"
          />

          <rect
            x="381.2"
            y="579.8"
            transform="matrix(0.9624 -0.2715 0.2715 0.9624 -142.7344 131.8931)"
            class="st38"
            width="48.2"
            height="4"
          />
        </g>
        <g
          id="gate_50_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                50
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="234.7"
            y="539.5"
            transform="matrix(0.2713 -0.9625 0.9625 0.2713 -370.7979 642.1307)"
            class="st39"
            width="8"
            height="52.8"
          />
          <polygon
            class="st38"
            points="212,562.6 213.1,558.7 263.9,573.1 262.8,577 			"
          />
        </g>
        <g
          id="gate_06_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                6
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="427"
            y="562.1"
            transform="matrix(0.9625 -0.2713 0.2713 0.9625 -136.5782 144.239)"
            class="st39"
            width="52.8"
            height="8"
          />

          <rect
            x="427.5"
            y="566.1"
            transform="matrix(0.9624 -0.2715 0.2715 0.9624 -137.1893 144.5734)"
            class="st38"
            width="52.8"
            height="4"
          />
        </g>
        <g
          id="gate_37_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                37
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="504"
            y="511.7"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -198.208 332.5944)"
            class="st39"
            width="8"
            height="32.8"
          />

          <rect
            x="504.3"
            y="512.7"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -198.9549 331.8676)"
            class="st38"
            width="4"
            height="32.8"
          />
        </g>
        <g
          id="gate_40_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                40
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="486.6"
            y="480.9"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -185.6291 319.6164)"
            class="st39"
            width="8"
            height="35.5"
          />

          <rect
            x="486.9"
            y="482"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -186.427 318.9036)"
            class="st38"
            width="4"
            height="35.5"
          />
        </g>
        <g
          id="gate_26_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                26
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="301.9"
            y="481.5"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -147.598 157.2352)"
            class="st39"
            width="115.5"
            height="8"
          />

          <rect
            x="302.6"
            y="485.4"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -148.2207 157.6021)"
            class="st38"
            width="115.5"
            height="4"
          />
        </g>
        <g
          id="gate_34_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[34])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                34
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          transform="translate(-1)"
        >
          <rect
            x="245.2"
            y="471.5"
            transform="matrix(0.3513 -0.9363 0.9363 0.3513 -339.9839 580.8569)"
            class="st39"
            width="8"
            height="128.5"
          />

          <rect
            x="246.8"
            y="473.5"
            transform="matrix(0.3513 -0.9363 0.9363 0.3513 -342.1295 581.7713)"
            class="st38"
            width="4"
            height="128.5"
          />
        </g>
        <g
          id="gate_10_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          transform="translate(-0.75)"
        >
          <rect x="247" y="397.9" class="st39" width="49.5" height="8" />
          <rect x="247" y="401.9" class="st38" width="49.5" height="4" />
        </g>
        <g
          id="gate_51_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                51
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="421.2"
            y="423"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -183.0747 376.6263)"
            class="st39"
            width="8"
            height="33"
          />

          <rect
            x="421.7"
            y="424.2"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -184.1985 375.9426)"
            class="st38"
            width="4"
            height="33"
          />
        </g>

        <g
          id="gate_25_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                25
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="400.5"
            y="399"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -172.0043 357.5046)"
            class="st39"
            width="8"
            height="31.5"
          />

          <rect
            x="400.9"
            y="400.3"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -173.2158 356.78)"
            class="st38"
            width="4"
            height="31.5"
          />
        </g>
        <g
          id="gate_21_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                21
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="434.2"
            y="371.6"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -146.5297 278.2832)"
            class="st39"
            width="8"
            height="73.7"
          />

          <rect
            x="434.4"
            y="372.6"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -147.2844 277.5091)"
            class="st38"
            width="4"
            height="73.7"
          />
        </g>
        <g
          id="gate_45_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                45
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M416.1,378.7c-35.9-61.1-36.1-61.7-36.3-62l3.7-1.6l3.5-1.8c0.9,1.7,26.3,44.9,35.9,61.4L416.1,378.7z"
          />
          <path
            class="st38"
            d="M416.1,378.7c-16.3-27.8-35.9-61.2-36.1-61.8l3.7-1.6l-1.8,0.8l1.8-0.9c0.7,1.4,23.8,40.8,36,61.5
              L416.1,378.7z"
          />
        </g>
        <g
          id="gate_36_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                36
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="505.1"
            y="388.5"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -167.2052 322.4839)"
            class="st39"
            width="8"
            height="160.1"
          />

          <rect
            x="505.3"
            y="389.5"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -167.9599 321.7098)"
            class="st38"
            width="4"
            height="160.1"
          />
        </g>
        <g
          id="gate_39_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                39
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="370.6"
            y="669.8"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -334.5908 430.945)"
            class="st39"
            width="104.8"
            height="8"
          />
          <polygon
            class="st38"
            points="383,707.6 463.1,640 465.6,643.1 385.6,710.7 		"
          />
        </g>
        <g
          id="gate_54_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                54
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="287.3"
            y="607"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -400.7209 457.4514)"
            class="st39"
            width="8"
            height="104.8"
          />

          <rect
            x="288.2"
            y="608.5"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -402.2596 457.1438)"
            class="st38"
            width="4"
            height="104.8"
          />
        </g>
      </g>
      <g id="Слой_3" transform="translate(31.240005,17.409983)">
        <g
          id="gate_42_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                42
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="570.5" class="st39" width="8" height="57" />
          <rect x="324.3" y="570.5" class="st38" width="4" height="57" />
        </g>
        <g>
          <g
            id="gate_05_both_chart1"
            @click="openTooltipsDialog(compoSite.chart.gatesExt[5])"
            :class="{
              visible:
                isDefinedObj(
                  compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                  5
                ) &&
                isChartTurnOn &&
                !isChart2TurnOn
            }"
            class="invisible cursor-pointer over-shadow-anim"
          >
            <rect x="324.3" y="483.5" class="st39" width="8" height="87" />
            <rect x="324.3" y="483.5" class="st38" width="4" height="87" />
          </g>
        </g>
        <g
          id="gate_15_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                15
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="400.8" class="st39" width="8" height="82.7" />
          <rect x="324.3" y="400.8" class="st38" width="4" height="82.7" />
        </g>
        <g
          id="gate_07_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                7
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="339.3" class="st39" width="8" height="61.5" />
          <rect x="324.3" y="339.3" class="st38" width="4" height="61.5" />
        </g>
        <g
          id="gate_31_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                31
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="270.3" class="st39" width="8" height="69" />
          <rect x="324.3" y="270.3" class="st38" width="4" height="69" />
        </g>
        <g
          id="gate_62_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                62
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="214.3" class="st39" width="8" height="56" />
          <rect x="324.3" y="214.3" class="st38" width="4" height="56" />
        </g>
        <g
          id="gate_17_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                17
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="144.8" class="st39" width="8" height="69.5" />
          <rect x="324.3" y="144.8" class="st38" width="4" height="69.5" />
        </g>
        <g
          id="gate_64_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                64
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="74" class="st39" width="8" height="31.8" />
          <rect x="324.3" y="74" class="st38" width="4" height="31.8" />
        </g>
        <g
          id="gate_03_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                3
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="570.9" class="st39" width="8" height="57" />
          <rect x="344.8" y="570.9" class="st38" width="4" height="57" />
        </g>
        <g
          id="gate_14_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                14
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="483.9" class="st39" width="8" height="87" />
          <rect x="344.8" y="483.9" class="st38" width="4" height="87" />
        </g>
        <g
          id="gate_02_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                2
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="401.4" class="st39" width="8" height="82.5" />
          <rect x="344.8" y="401.4" class="st38" width="4" height="82.5" />
        </g>
        <g
          id="gate_01_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                1
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="339.9" class="st39" width="8" height="61.5" />
          <rect x="344.8" y="339.9" class="st38" width="4" height="61.5" />
        </g>
        <g
          id="gate_08_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                8
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="270.9" class="st39" width="8" height="69" />
          <rect x="344.8" y="270.9" class="st38" width="4" height="69" />
        </g>
        <g
          id="gate_23_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                23
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="214.9" class="st39" width="8" height="56" />
          <rect x="344.8" y="214.9" class="st38" width="4" height="56" />
        </g>
        <g
          id="gate_43_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                43
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="145.4" class="st39" width="8" height="69.5" />
          <rect x="344.8" y="145.4" class="st38" width="4" height="69.5" />
        </g>
        <g
          id="gate_61_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                61
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="73.9" class="st39" width="8" height="32" />
          <rect x="344.8" y="73.9" class="st38" width="4" height="32" />
        </g>
        <g
          id="gate_52_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                52
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="627.9" class="st39" width="8" height="79" />
          <rect x="365.3" y="627.9" class="st38" width="4" height="79" />
        </g>
        <g
          id="gate_09_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                9
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="570.9" class="st39" width="8" height="57" />
          <rect x="365.3" y="570.9" class="st38" width="4" height="57" />
        </g>
        <g
          id="gate_29_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                29
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="483.9" class="st39" width="8" height="87" />
          <rect x="365.3" y="483.9" class="st38" width="4" height="87" />
        </g>
        <g
          id="gate_46_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                46
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="401.4" class="st39" width="8" height="82.5" />
          <rect x="365.3" y="401.4" class="st38" width="4" height="82.5" />
        </g>
        <g
          id="gate_13_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                13
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="339.9" class="st39" width="8" height="61.5" />
          <rect x="365.3" y="339.9" class="st38" width="4" height="61.5" />
        </g>
        <g
          id="gate_33_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                33
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="270.9" class="st39" width="8" height="69" />
          <rect x="365.3" y="270.9" class="st38" width="4" height="69" />
        </g>
        <g
          id="gate_56_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                56
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="214.9" class="st39" width="8" height="56" />
          <rect x="365.3" y="214.9" class="st38" width="4" height="56" />
        </g>
        <g
          id="gate_11_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                11
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="145.4" class="st39" width="8" height="69.5" />
          <rect x="365.3" y="145.4" class="st38" width="4" height="69.5" />
        </g>
        <g
          id="gate_04_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                4
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="105.9" class="st39" width="8" height="39.5" />
          <rect x="365.3" y="105.9" class="st38" width="4" height="39.5" />
        </g>
        <g
          id="gate_63_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                63
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="73.9" class="st39" width="8" height="32" />
          <rect x="365.3" y="73.9" class="st38" width="4" height="32" />
        </g>
        <g
          id="gate_53_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                53
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="627.5" class="st39" width="8" height="79.4" />
          <rect x="324.3" y="627.5" class="st38" width="4" height="79.4" />
        </g>
        <g
          id="gate_47_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                47
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="105.3" class="st39" width="8" height="39.5" />
          <rect x="324.3" y="105.3" class="st38" width="4" height="39.5" />
        </g>
        <g
          id="gate_24_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                24
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="105.9" class="st39" width="8" height="39.5" />
          <rect x="344.8" y="105.9" class="st38" width="4" height="39.5" />
        </g>
        <g
          id="gate_60_both_chart1"
          @click="openTooltipsDialog(compoSite.chart.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart.gatesActivatedAtPersonalityAndDesign,
                60
              ) &&
              isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="627.9" class="st39" width="8" height="79" />
          <rect x="344.8" y="627.9" class="st38" width="4" height="79" />
        </g>
      </g>
    </g>
    <g
      id="layer_Gates_Chart2_Both"
      inkscape:groupmode="layer"
      inkscape:label="Gates Both"
      sodipodi:insensitive="true"
    >
      <g
        id="Слой_16_Gates_Chart1_Both"
        transform="translate(31.134997,17.409983)"
      >
        <g
          id="gate_44_both_chart2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[44])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                44
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="189.7"
            y="522.8"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -169.1191 121.1813)"
            class="st39"
            width="119.6"
            height="8"
          />

          <rect
            x="190.4"
            y="526.7"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -169.7418 121.5482)"
            class="st38"
            width="119.6"
            height="4"
          />
        </g>
        <g
          id="gate_41_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[41])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                41
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="377.7"
            y="690.4"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -346.1918 440.363)"
            class="st39"
            width="104.8"
            height="8"
          />
          <polygon
            class="st38"
            points="389.9,728 470.2,660.6 472.7,663.7 392.5,731.1 			"
          />
        </g>
        <g
          id="gate_58_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[58])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                58
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="266.3"
            y="640.6"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -437.0297 454.8037)"
            class="st39"
            width="8"
            height="113"
          />

          <rect
            x="267"
            y="642"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -438.6014 454.325)"
            class="st38"
            width="4"
            height="113.1"
          />
        </g>
        <g
          id="gate_30_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[30])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                30
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="458.1"
            y="623.5"
            transform="matrix(0.7646 -0.6445 0.6445 0.7646 -284.5009 476.052)"
            class="st39"
            width="102.7"
            height="8"
          />

          <rect
            x="459.3"
            y="627.1"
            transform="matrix(0.7646 -0.6445 0.6445 0.7646 -285.2496 477.2019)"
            class="st38"
            width="102.7"
            height="4"
          />
        </g>
        <g
          id="gate_18_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[18])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                18
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="183.7"
            y="576.1"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -413.1847 366.8568)"
            class="st39"
            width="8"
            height="102.8"
          />

          <rect
            x="184.4"
            y="577.6"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -414.7946 366.3961)"
            class="st38"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_16_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[16])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                16
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M234.4,401.6l-6.9-4c26.4-45,76.7-130.7,78.2-133.6l2.7,1.4l4.6,2C312.4,268.6,269.9,341,234.4,401.6z"
          />
          <path
            class="st38"
            d="M234.4,401.6l-3.5-2c26.5-45.2,77-131.4,78.2-133.7l3.6,1.7C312.2,268.9,252.6,370.5,234.4,401.6z"
          />
        </g>
        <g
          id="gate_48_both_chart2"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[48])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                48
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="110.9"
            y="464.2"
            transform="matrix(0.5074 -0.8617 0.8617 0.5074 -309.5637 394.9042)"
            class="st38"
            width="159.4"
            height="8"
          />
          <polygon
            class="st39"
            points="150.2,536.9 230.9,399.6 234.4,401.6 153.7,539 		"
          />
        </g>
        <g
          id="gate_35_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[35])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                35
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M465.1,401.6c-35.5-60.5-80.7-137.4-81.2-138.7l3.7-1.6l3.6-1.8c1.5,2.9,54.5,93.1,80.9,138L465.1,401.6z"
          />
          <path
            class="st38"
            d="M465.1,401.6c-18.2-31.1-80.5-137.1-81.1-138.5l1.8-0.8l1.8-0.9c1.2,2.3,54.4,92.9,80.9,138.1L465.1,401.6z"
          />
        </g>
        <g
          id="gate_57_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[5])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                5
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="129.5"
            y="466.3"
            transform="matrix(0.5066 -0.8622 0.8622 0.5066 -302.1188 412.7231)"
            class="st39"
            width="160.1"
            height="8"
          />

          <rect
            x="130.3"
            y="469.3"
            transform="matrix(0.5068 -0.862 0.862 0.5068 -302.5332 413.8016)"
            class="st38"
            width="160.1"
            height="4"
          />
        </g>
        <g
          id="gate_38_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[38])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                38
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st27 invisible cursor-pointer over-shadow-anim"
        >
          <polygon
            class="st39"
            points="235.6,648.5 240.8,642.4 321.4,709.8 316.3,716 		"
          />
          <polygon
            class="st38"
            points="235.6,648.5 238.2,645.4 318.9,712.9 316.3,716 		"
          />
        </g>
        <g
          id="gate_55_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[55])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                55
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="451"
            y="602.9"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -272.8225 466.3625)"
            class="st39"
            width="102.8"
            height="8"
          />

          <rect
            x="452.2"
            y="606.4"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -273.5066 467.4883)"
            class="st38"
            width="102.8"
            height="4"
          />
        </g>
        <g
          id="gate_28_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[28])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                28
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="194.9"
            y="560.9"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -397.573 370.015)"
            class="st39"
            width="8"
            height="102.8"
          />
          <line class="st39" x1="159.5" y1="579.2" x2="238.1" y2="645.4" />

          <rect
            x="195.5"
            y="562.4"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -399.2184 369.4779)"
            class="st38"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_22_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[22])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                22
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="489.7"
            y="392.9"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -171.5566 315.2886)"
            class="st39"
            width="8"
            height="160.1"
          />

          <rect
            x="490"
            y="393.9"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -172.2975 314.5652)"
            class="st38"
            width="4"
            height="160.1"
          />
        </g>
        <g
          id="gate_12_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[12])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                12
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M449.7,406c-35.5-60.5-78-133-78.5-134.3l3.7-1.6l3.6-1.8c1.5,2.9,51.8,88.7,78.2,133.6L449.7,406z"
          />
          <path
            class="st38"
            d="M449.7,406c-18.2-31.1-77.8-132.7-78.4-134.1l1.8-0.8l1.8-0.9c1.2,2.3,51.7,88.5,78.2,133.7L449.7,406z"
          />
        </g>
        <g
          id="gate_20_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[20])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                20
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M253.6,403.3l-6.9-4c26.4-45,76.7-130.7,78.2-133.6l2.7,1.4l4.6,2C331.5,270.3,289.1,342.8,253.6,403.3z"
          />
          <path
            class="st38"
            d="M253.6,403.3l-3.5-2c26.5-45.2,77-131.4,78.2-133.7l3.6,1.7C331.4,270.6,271.8,372.2,253.6,403.3z"
          />
        </g>
        <g
          id="gate_19_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[19])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                19
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="354.3"
            y="655"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -328.8897 416.9642)"
            class="st39"
            width="104.8"
            height="8"
          />

          <rect
            x="355.7"
            y="658.6"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -329.5912 418.2423)"
            class="st38"
            width="104.8"
            height="4"
          />
        </g>
        <g
          id="gate_49_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[49])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                49
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="434.7"
            y="588.2"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.1857 452.4052)"
            class="st39"
            width="102.8"
            height="8"
          />

          <rect
            x="436"
            y="591.7"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -267.8463 453.5954)"
            class="st38"
            width="102.8"
            height="4"
          />
        </g>
        <g
          id="gate_32_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[32])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                32
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="208.1"
            y="541.2"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -377.8074 373.1017)"
            class="st39"
            width="8"
            height="102.8"
          />

          <rect
            x="208.7"
            y="542.7"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -379.4529 372.5647)"
            class="st38"
            width="4"
            height="102.8"
          />
        </g>
        <g
          id="gate_27_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[27])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                27
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <polygon
            class="st39"
            points="262.8,576.9 265,569.2 310.7,583 308.4,590.6 			"
          />
          <polygon
            class="st38"
            points="262.8,577 263.9,573.1 309.5,586.8 308.4,590.6 			"
          />
        </g>
        <g
          id="gate_59_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[59])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                59
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="380.6"
            y="575.8"
            transform="matrix(0.9625 -0.2713 0.2713 0.9625 -142.1215 131.5405)"
            class="st39"
            width="48.2"
            height="8"
          />

          <rect
            x="381.2"
            y="579.8"
            transform="matrix(0.9624 -0.2715 0.2715 0.9624 -142.7344 131.8931)"
            class="st38"
            width="48.2"
            height="4"
          />
        </g>
        <g
          id="gate_50_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[50])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                50
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="234.7"
            y="539.5"
            transform="matrix(0.2713 -0.9625 0.9625 0.2713 -370.7979 642.1307)"
            class="st39"
            width="8"
            height="52.8"
          />
          <polygon
            class="st38"
            points="212,562.6 213.1,558.7 263.9,573.1 262.8,577 			"
          />
        </g>
        <g
          id="gate_06_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[6])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                6
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="427"
            y="562.1"
            transform="matrix(0.9625 -0.2713 0.2713 0.9625 -136.5782 144.239)"
            class="st39"
            width="52.8"
            height="8"
          />

          <rect
            x="427.5"
            y="566.1"
            transform="matrix(0.9624 -0.2715 0.2715 0.9624 -137.1893 144.5734)"
            class="st38"
            width="52.8"
            height="4"
          />
        </g>
        <g
          id="gate_37_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[37])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                37
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="504"
            y="511.7"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -198.208 332.5944)"
            class="st39"
            width="8"
            height="32.8"
          />

          <rect
            x="504.3"
            y="512.7"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -198.9549 331.8676)"
            class="st38"
            width="4"
            height="32.8"
          />
        </g>
        <g
          id="gate_40_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[40])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                40
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="486.6"
            y="480.9"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -185.6291 319.6164)"
            class="st39"
            width="8"
            height="35.5"
          />

          <rect
            x="486.9"
            y="482"
            transform="matrix(0.8604 -0.5096 0.5096 0.8604 -186.427 318.9036)"
            class="st38"
            width="4"
            height="35.5"
          />
        </g>
        <g
          id="gate_26_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[26])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                26
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="301.9"
            y="481.5"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -147.598 157.2352)"
            class="st39"
            width="115.5"
            height="8"
          />

          <rect
            x="302.6"
            y="485.4"
            transform="matrix(0.9363 -0.3512 0.3512 0.9363 -148.2207 157.6021)"
            class="st38"
            width="115.5"
            height="4"
          />
        </g>
        <g
          id="gate_34_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[34])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                34
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          transform="translate(-1)"
        >
          <rect
            x="245.2"
            y="471.5"
            transform="matrix(0.3513 -0.9363 0.9363 0.3513 -339.9839 580.8569)"
            class="st39"
            width="8"
            height="128.5"
          />

          <rect
            x="246.8"
            y="473.5"
            transform="matrix(0.3513 -0.9363 0.9363 0.3513 -342.1295 581.7713)"
            class="st38"
            width="4"
            height="128.5"
          />
        </g>
        <g
          id="gate_10_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[10])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                10
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
          transform="translate(-0.75)"
        >
          <rect x="247" y="397.9" class="st39" width="49.5" height="8" />
          <rect x="247" y="401.9" class="st38" width="49.5" height="4" />
        </g>
        <g
          id="gate_51_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[51])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                51
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="421.2"
            y="423"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -183.0747 376.6263)"
            class="st39"
            width="8"
            height="33"
          />

          <rect
            x="421.7"
            y="424.2"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -184.1985 375.9426)"
            class="st38"
            width="4"
            height="33"
          />
        </g>

        <g
          id="gate_25_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[25])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                25
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="400.5"
            y="399"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -172.0043 357.5046)"
            class="st39"
            width="8"
            height="31.5"
          />

          <rect
            x="400.9"
            y="400.3"
            transform="matrix(0.7655 -0.6434 0.6434 0.7655 -173.2158 356.78)"
            class="st38"
            width="4"
            height="31.5"
          />
        </g>
        <g
          id="gate_21_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[21])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                21
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="434.2"
            y="371.6"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -146.5297 278.2832)"
            class="st39"
            width="8"
            height="73.7"
          />

          <rect
            x="434.4"
            y="372.6"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -147.2844 277.5091)"
            class="st38"
            width="4"
            height="73.7"
          />
        </g>
        <g
          id="gate_45_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[45])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                45
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <path
            class="st39"
            d="M416.1,378.7c-35.9-61.1-36.1-61.7-36.3-62l3.7-1.6l3.5-1.8c0.9,1.7,26.3,44.9,35.9,61.4L416.1,378.7z"
          />
          <path
            class="st38"
            d="M416.1,378.7c-16.3-27.8-35.9-61.2-36.1-61.8l3.7-1.6l-1.8,0.8l1.8-0.9c0.7,1.4,23.8,40.8,36,61.5
              L416.1,378.7z"
          />
        </g>
        <g
          id="gate_36_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[36])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                36
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="505.1"
            y="388.5"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -167.2052 322.4839)"
            class="st39"
            width="8"
            height="160.1"
          />

          <rect
            x="505.3"
            y="389.5"
            transform="matrix(0.8622 -0.5066 0.5066 0.8622 -167.9599 321.7098)"
            class="st38"
            width="4"
            height="160.1"
          />
        </g>
        <g
          id="gate_39_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[39])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                39
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="370.6"
            y="669.8"
            transform="matrix(0.7648 -0.6442 0.6442 0.7648 -334.5908 430.945)"
            class="st39"
            width="104.8"
            height="8"
          />
          <polygon
            class="st38"
            points="383,707.6 463.1,640 465.6,643.1 385.6,710.7 		"
          />
        </g>
        <g
          id="gate_54_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[54])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                54
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="st9 invisible cursor-pointer over-shadow-anim"
        >
          <rect
            x="287.3"
            y="607"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -400.7209 457.4514)"
            class="st39"
            width="8"
            height="104.8"
          />

          <rect
            x="288.2"
            y="608.5"
            transform="matrix(0.6442 -0.7649 0.7649 0.6442 -402.2596 457.1438)"
            class="st38"
            width="4"
            height="104.8"
          />
        </g>
      </g>
      <g id="Слой_3" transform="translate(31.240005,17.409983)">
        <g
          id="gate_42_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[42])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                42
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="570.5" class="st39" width="8" height="57" />
          <rect x="324.3" y="570.5" class="st38" width="4" height="57" />
        </g>
        <g>
          <g
            id="gate_05_both_chart1"
            @click="openTooltipsDialog(compoSite.chart2.gatesExt[5])"
            :class="{
              visible:
                isDefinedObj(
                  compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                  5
                ) &&
                isChartTurnOn &&
                !isChart2TurnOn
            }"
            class="invisible cursor-pointer over-shadow-anim"
          >
            <rect x="324.3" y="483.5" class="st39" width="8" height="87" />
            <rect x="324.3" y="483.5" class="st38" width="4" height="87" />
          </g>
        </g>
        <g
          id="gate_15_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[15])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                15
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="400.8" class="st39" width="8" height="82.7" />
          <rect x="324.3" y="400.8" class="st38" width="4" height="82.7" />
        </g>
        <g
          id="gate_07_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[7])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                7
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="339.3" class="st39" width="8" height="61.5" />
          <rect x="324.3" y="339.3" class="st38" width="4" height="61.5" />
        </g>
        <g
          id="gate_31_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[31])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                31
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="270.3" class="st39" width="8" height="69" />
          <rect x="324.3" y="270.3" class="st38" width="4" height="69" />
        </g>
        <g
          id="gate_62_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[62])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                62
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="214.3" class="st39" width="8" height="56" />
          <rect x="324.3" y="214.3" class="st38" width="4" height="56" />
        </g>
        <g
          id="gate_17_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[17])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                17
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="144.8" class="st39" width="8" height="69.5" />
          <rect x="324.3" y="144.8" class="st38" width="4" height="69.5" />
        </g>
        <g
          id="gate_64_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[64])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                64
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="74" class="st39" width="8" height="31.8" />
          <rect x="324.3" y="74" class="st38" width="4" height="31.8" />
        </g>
        <g
          id="gate_03_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[3])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                3
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="570.9" class="st39" width="8" height="57" />
          <rect x="344.8" y="570.9" class="st38" width="4" height="57" />
        </g>
        <g
          id="gate_14_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[14])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                14
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="483.9" class="st39" width="8" height="87" />
          <rect x="344.8" y="483.9" class="st38" width="4" height="87" />
        </g>
        <g
          id="gate_02_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[2])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                2
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="401.4" class="st39" width="8" height="82.5" />
          <rect x="344.8" y="401.4" class="st38" width="4" height="82.5" />
        </g>
        <g
          id="gate_01_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[1])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                1
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="339.9" class="st39" width="8" height="61.5" />
          <rect x="344.8" y="339.9" class="st38" width="4" height="61.5" />
        </g>
        <g
          id="gate_08_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[8])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                8
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="270.9" class="st39" width="8" height="69" />
          <rect x="344.8" y="270.9" class="st38" width="4" height="69" />
        </g>
        <g
          id="gate_23_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[23])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                23
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="214.9" class="st39" width="8" height="56" />
          <rect x="344.8" y="214.9" class="st38" width="4" height="56" />
        </g>
        <g
          id="gate_43_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[43])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                43
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="145.4" class="st39" width="8" height="69.5" />
          <rect x="344.8" y="145.4" class="st38" width="4" height="69.5" />
        </g>
        <g
          id="gate_61_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[61])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                61
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="73.9" class="st39" width="8" height="32" />
          <rect x="344.8" y="73.9" class="st38" width="4" height="32" />
        </g>
        <g
          id="gate_52_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[52])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                52
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="627.9" class="st39" width="8" height="79" />
          <rect x="365.3" y="627.9" class="st38" width="4" height="79" />
        </g>
        <g
          id="gate_09_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[9])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                9
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="570.9" class="st39" width="8" height="57" />
          <rect x="365.3" y="570.9" class="st38" width="4" height="57" />
        </g>
        <g
          id="gate_29_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[29])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                29
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="483.9" class="st39" width="8" height="87" />
          <rect x="365.3" y="483.9" class="st38" width="4" height="87" />
        </g>
        <g
          id="gate_46_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[46])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                46
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="401.4" class="st39" width="8" height="82.5" />
          <rect x="365.3" y="401.4" class="st38" width="4" height="82.5" />
        </g>
        <g
          id="gate_13_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[13])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                13
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="339.9" class="st39" width="8" height="61.5" />
          <rect x="365.3" y="339.9" class="st38" width="4" height="61.5" />
        </g>
        <g
          id="gate_33_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[33])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                33
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="270.9" class="st39" width="8" height="69" />
          <rect x="365.3" y="270.9" class="st38" width="4" height="69" />
        </g>
        <g
          id="gate_56_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[56])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                56
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="214.9" class="st39" width="8" height="56" />
          <rect x="365.3" y="214.9" class="st38" width="4" height="56" />
        </g>
        <g
          id="gate_11_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[11])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                11
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="145.4" class="st39" width="8" height="69.5" />
          <rect x="365.3" y="145.4" class="st38" width="4" height="69.5" />
        </g>
        <g
          id="gate_04_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[4])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                4
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="105.9" class="st39" width="8" height="39.5" />
          <rect x="365.3" y="105.9" class="st38" width="4" height="39.5" />
        </g>
        <g
          id="gate_63_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[63])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                63
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="365.3" y="73.9" class="st39" width="8" height="32" />
          <rect x="365.3" y="73.9" class="st38" width="4" height="32" />
        </g>
        <g
          id="gate_53_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[53])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                53
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="627.5" class="st39" width="8" height="79.4" />
          <rect x="324.3" y="627.5" class="st38" width="4" height="79.4" />
        </g>
        <g
          id="gate_47_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[47])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                47
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="324.3" y="105.3" class="st39" width="8" height="39.5" />
          <rect x="324.3" y="105.3" class="st38" width="4" height="39.5" />
        </g>
        <g
          id="gate_24_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[24])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                24
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="105.9" class="st39" width="8" height="39.5" />
          <rect x="344.8" y="105.9" class="st38" width="4" height="39.5" />
        </g>
        <g
          id="gate_60_both_chart1"
          @click="openTooltipsDialog(compoSite.chart2.gatesExt[60])"
          :class="{
            visible:
              isDefinedObj(
                compoSite.chart2.gatesActivatedAtPersonalityAndDesign,
                60
              ) &&
              !isChartTurnOn &&
              isChart2TurnOn
          }"
          class="invisible cursor-pointer over-shadow-anim"
        >
          <rect x="344.8" y="627.9" class="st39" width="8" height="79" />
          <rect x="344.8" y="627.9" class="st38" width="4" height="79" />
        </g>
      </g>
    </g>
    <g id="All Chart 1 Channels">
      <polygon
        id="gate_35_36_channel"
        v-if="
          isChannel(compoSite.connection.channels, '35-36') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '35-36')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="584.2,553.1 503.1,414.9 503,415 422.3,276.9 415.4,281 496.1,419.1 496.3,419 577.3,557.1 	"
      />
      <polygon
        id="gate_30_41_channel"
        v-if="
          isChannel(compoSite.connection.channels, '30-41') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '30-41')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="582.4,614.8 577.2,608.7 498.6,674.9 498.7,675 418.7,742.4 423.8,748.5 503.9,681 503.9,681 	"
      />
      <polygon
        id="gate_39_55_channel"
        v-if="
          isChannel(compoSite.connection.channels, '39-55') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '39-55')
          )
        "
      />
      <polygon
        id="gate_19_49_channel"
        v-if="
          isChannel(compoSite.connection.channels, '19-49') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '19-49')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="559.1,579.5 553.9,573.4 475.3,639.6 475.3,639.7 395.2,707.1 400.3,713.3 480.5,645.8 480.5,645.7 	
        "
      />
      <path
        id="gate_21_45_channel"
        v-if="
          isChannel(compoSite.connection.channels, '21-45') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '21-45')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M454,392.1c-12.1-20.7-35.1-60.1-35.9-61.5l-3.5,1.9l-3.6,1.8c0.2,0.4,12.4,21.2,36.1,61.8l37.4,63.5l6.9-4.1
        L454,392.1z"
      />
      <polygon
        id="gate_18_58_channel"
        v-if="
          isChannel(compoSite.connection.channels, '18-58') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '18-58')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="260.7,675 260.7,675 182.1,608.8 177,614.9 255.5,681.1 255.5,681.1 342,754 347.2,747.8 	"
      />
      <polygon
        id="gate_28_38_channel"
        v-if="
          isChannel(compoSite.connection.channels, '28-38') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '28-38')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="352.5,727.1 271.7,659.6 271.7,659.6 193.2,593.6 188.1,599.7 266.7,665.9 266.7,665.9 347.4,733.2 	
        "
      />
      <polygon
        id="gate_32_54_channel"
        v-if="
          isChannel(compoSite.connection.channels, '32-54') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '32-54')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="365.2,707.4 285.1,640 285,640.1 206.4,573.9 201.3,580 279.9,646.2 279.9,646.1 360,713.5 	"
      />
      <path
        id="gate_12_22_channel"
        v-if="
          isChannel(compoSite.connection.channels, '12-22') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '12-22')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M487.8,419.5L487.8,419.5c-26.3-45-76.6-130.8-78.1-133.7l-3.6,1.8l3.7-1.6l-7.4,3.2
        c0.6,1.3,43,73.8,78.5,134.3l0,0l81.2,138l6.9-4.1L487.8,419.5z"
      />
      <path
        id="gate_16_48_channel"
        v-if="
          isChannel(compoSite.connection.channels, '16-48') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '16-48')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M339.6,282.7l-2.7-1.4c-1.5,2.9-51.9,88.7-78.3,133.7l-81.2,138l6.9,4.1l81.2-138
        c35.5-60.5,78-133.1,78.7-134.4L339.6,282.7z"
      />
      <path
        id="gate_10_20_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M363.3,286.4l-4.6-2L356,283c-1.5,2.9-53.3,91.1-79.7,136.1l6.9,4l47.2,0.2v-8h-42.6
        C322.4,356.4,362.7,287.7,363.3,286.4z"
      />
      <polygon
        id="gate_27_50_channel"
        v-if="
          isChannel(compoSite.connection.channels, '27-50') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '27-50')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="341.5,600.5 296.1,586.5 245.6,572.1 243.4,579.8 293.9,594.2 293.9,594.1 339.2,608.2 	"
      />
      <polygon
        id="gate_06_59_channel"
        v-if="
          isChannel(compoSite.connection.channels, '6-59') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '6-59')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="508.8,572.6 457.9,586.9 411.6,600 413.7,607.7 460.1,594.6 510.9,580.3 	"
      />
      <polygon
        id="gate_37_40_channel"
        v-if="
          isChannel(compoSite.connection.channels, '37-40') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '37-40')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="534.2,529.3 516.2,498.6 509.3,502.7 527.3,533.4 544.1,561.8 551,557.7 	"
      />
      <polygon
        id="gate_25_51_channel"
        v-if="
          isChannel(compoSite.connection.channels, '25-51') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '25-51')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="448.6,441.6 428.3,417.5 422.2,422.7 442.5,446.8 464,472.1 470.1,466.9 	"
      />
      <polygon
        id="gate_26_44_channel"
        v-if="
          isChannel(compoSite.connection.channels, '26-44') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '26-44')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="446.4,486.4 443.6,478.9 335.3,519.5 335.3,519.5 223.3,561.4 226.1,568.9 338.1,526.9 338.1,527 	
        "
      />
      <g id="Vertical Channels">
        <path
          id="gate_04_63_channel"
          v-if="
            isChannel(compoSite.connection.channels, '4-63') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '4-63')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 396 112 L 396 113.213257 L 396 114.710876 L 396 117.345947 L 396 119.469177 L 396 122.085327 L 396 124.417053 L 396 127.545044 L 396 130.843628 L 396 133.004761 L 396 136 L 405 136 L 405 133.004761 L 405 130.843628 L 405 127.545044 L 405 124.417053 L 405 122.085327 L 405 119.469177 L 405 117.345947 L 405 114.710876 L 405 113.213257 L 405 112 Z"
        />
        <path
          id="gate_47_64_channel"
          v-if="
            isChannel(compoSite.connection.channels, '47-64') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '47-64')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 355 110 L 355 111.314392 L 355 112.936829 L 355 115.791443 L 355 118.091614 L 355 120.92572 L 355 123.451843 L 355 126.840454 L 355 130.413879 L 355 132.755127 L 355 136 L 364 136 L 364 132.755127 L 364 130.413879 L 364 126.840454 L 364 123.451843 L 364 120.92572 L 364 118.091614 L 364 115.791443 L 364 112.936829 L 364 111.314392 L 364 110 Z"
        />
        <path
          id="gate_24_61_channel"
          v-if="
            isChannel(compoSite.connection.channels, '24-61') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '24-61')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 376 112 L 376 113.213257 L 376 114.710876 L 376 117.345947 L 376 119.469177 L 376 122.085327 L 376 124.417053 L 376 127.545044 L 376 130.843628 L 376 133.004761 L 376 136 L 384 136 L 384 133.004761 L 384 130.843628 L 384 127.545044 L 384 124.417053 L 384 122.085327 L 384 119.469177 L 384 117.345947 L 384 114.710876 L 384 113.213257 L 384 112 Z"
        />
        <path
          id="gate_11_56_channel"
          v-if="
            isChannel(compoSite.connection.channels, '11-56') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '11-56')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 396 173 L 396 177.24646 L 396 182.488159 L 396 191.710876 L 396 199.142151 L 396 208.298584 L 396 216.459717 L 396 227.407593 L 396 238.952637 L 396 246.516602 L 396 257 L 405 257 L 405 246.516602 L 405 238.952637 L 405 227.407593 L 405 216.459717 L 405 208.298584 L 405 199.142151 L 405 191.710876 L 405 182.488159 L 405 177.24646 L 405 173 Z"
        />
        <path
          id="gate_17_62_channel"
          v-if="
            isChannel(compoSite.connection.channels, '17-62') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '17-62')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 355 178 L 355 181.993652 L 355 186.923401 L 355 195.597168 L 355 202.586121 L 355 211.197449 L 355 218.872803 L 355 229.169006 L 355 240.026855 L 355 247.140625 L 355 257 L 364 257 L 364 247.140625 L 364 240.026855 L 364 229.169006 L 364 218.872803 L 364 211.197449 L 364 202.586121 L 364 195.597168 L 364 186.923401 L 364 181.993652 L 364 178 Z"
        />
        <path
          id="gate_23_43_channel"
          v-if="
            isChannel(compoSite.connection.channels, '23-43') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '23-43')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 376 212 L 376 214.274902 L 376 217.082947 L 376 222.023682 L 376 226.004761 L 376 230.909973 L 376 235.281982 L 376 241.146912 L 376 247.331726 L 376 251.383911 L 376 257 L 384 257 L 384 251.383911 L 384 247.331726 L 384 241.146912 L 384 235.281982 L 384 230.909973 L 384 226.004761 L 384 222.023682 L 384 217.082947 L 384 214.274902 L 384 212 Z"
        />
        <path
          id="gate_07_31_channel"
          v-if="
            isChannel(compoSite.connection.channels, '7-31') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '7-31')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 355 334 L 355 336.628754 L 355 339.873627 L 355 345.582947 L 355 350.183258 L 355 355.851501 L 355 360.903625 L 355 367.680878 L 355 374.82782 L 355 379.510284 L 355 386 L 364 386 L 364 379.510284 L 364 374.82782 L 364 367.680878 L 364 360.903625 L 364 355.851501 L 364 350.183258 L 364 345.582947 L 364 339.873627 L 364 336.628754 L 364 334 Z"
        />
        <path
          id="gate_01_08_channel"
          v-if="
            isChannel(compoSite.connection.channels, '1-8') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '1-8')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 376 336 L 376 338.173767 L 376 340.857025 L 376 345.578186 L 376 349.382294 L 376 354.069519 L 376 358.247223 L 376 363.851501 L 376 369.761444 L 376 373.633484 L 376 379 L 384 379 L 384 373.633484 L 384 369.761444 L 384 363.851501 L 384 358.247223 L 384 354.069519 L 384 349.382294 L 384 345.578186 L 384 340.857025 L 384 338.173767 L 384 336 Z"
        />
        <path
          id="gate_13_33_channel"
          v-if="
            isChannel(compoSite.connection.channels, '13-33') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '13-33')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 396 336 L 396 338.527649 L 396 341.647705 L 396 347.137451 L 396 351.560822 L 396 357.011047 L 396 361.868866 L 396 368.385468 L 396 375.257507 L 396 379.759888 L 396 386 L 405 386 L 405 379.759888 L 405 375.257507 L 405 368.385468 L 405 361.868866 L 405 357.011047 L 405 351.560822 L 405 347.137451 L 405 341.647705 L 405 338.527649 L 405 336 Z"
        />
        <path
          id="gate_5_15_channel"
          v-if="
            isChannel(compoSite.connection.channels, '5-15') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '5-15')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 355 449 L 355 453.954193 L 355 460.069519 L 355 470.829376 L 355 479.499207 L 355 490.181671 L 355 499.703003 L 355 512.475525 L 355 525.944702 L 355 534.769348 L 355 547 L 364 547 L 364 534.769348 L 364 525.944702 L 364 512.475525 L 364 499.703003 L 364 490.181671 L 364 479.499207 L 364 470.829376 L 364 460.069519 L 364 453.954193 L 364 449 Z"
        />
        <path
          id="gate_2_14_channel"
          v-if="
            isChannel(compoSite.connection.channels, '2-14') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '2-14')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 376 463 L 376 467.145325 L 376 472.262238 L 376 481.265411 L 376 488.519745 L 376 497.45813 L 376 505.424957 L 376 516.112183 L 376 527.382324 L 376 534.766174 L 376 545 L 384 545 L 384 534.766174 L 384 527.382324 L 384 516.112183 L 384 505.424957 L 384 497.45813 L 384 488.519745 L 384 481.265411 L 384 472.262238 L 384 467.145325 L 384 463 Z"
        />
        <path
          id="gate_29_46_channel"
          v-if="
            isChannel(compoSite.connection.channels, '29-46') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '29-46')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 396 451 L 396 455.903625 L 396 461.956543 L 396 472.606628 L 396 481.187988 L 396 491.761444 L 396 501.185638 L 396 513.827759 L 396 527.159546 L 396 535.894165 L 396 548 L 405 548 L 405 535.894165 L 405 527.159546 L 405 513.827759 L 405 501.185638 L 405 491.761444 L 405 481.187988 L 405 472.606628 L 405 461.956543 L 405 455.903625 L 405 451 Z"
        />
        <path
          id="gate_42_53_channel"
          v-if="
            isChannel(compoSite.connection.channels, '42-53') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '42-53')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 355 627 L 355 629.375977 L 355 632.308838 L 355 637.469177 L 355 641.627197 L 355 646.750366 L 355 651.316772 L 355 657.442322 L 355 663.902039 L 355 668.134277 L 355 674 L 364 674 L 364 668.134277 L 364 663.902039 L 364 657.442322 L 364 651.316772 L 364 646.750366 L 364 641.627197 L 364 637.469177 L 364 632.308838 L 364 629.375977 L 364 627 Z"
        />
        <path
          id="gate_03_60_channel"
          v-if="
            isChannel(compoSite.connection.channels, '3-60') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '3-60')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 376 632 L 376 634.022095 L 376 636.518188 L 376 640.909973 L 376 644.448669 L 376 648.808838 L 376 652.695068 L 376 657.908386 L 376 663.406006 L 376 667.007874 L 376 672 L 384 672 L 384 667.007874 L 384 663.406006 L 384 657.908386 L 384 652.695068 L 384 648.808838 L 384 644.448669 L 384 640.909973 L 384 636.518188 L 384 634.022095 L 384 632 Z"
        />
        <path
          id="gate_09_52_channel"
          v-if="
            isChannel(compoSite.connection.channels, '9-52') &&
              !isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.channels, '9-52')
            )
          "
          class="st37 cursor-pointer channels-over"
          d="M 396 627 L 396 629.426514 L 396 632.421814 L 396 637.691956 L 396 641.938354 L 396 647.170593 L 396 651.834106 L 396 658.090027 L 396 664.687195 L 396 669.00946 L 396 675 L 405 675 L 405 669.00946 L 405 664.687195 L 405 658.090027 L 405 651.834106 L 405 647.170593 L 405 641.938354 L 405 637.691956 L 405 632.421814 L 405 629.426514 L 405 627 Z"
        />
      </g>
      <polygon
        id="gate_10_34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="222.2,527.6 283.2,423.3 330.4,423.3 330.4,415.3 278.7,415.3 210.6,531 218.1,534.6 338.1,579.7 
        340.9,572.2 	"
      />
      <polygon
        id="gate_10_57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="330.4,415.3 278.7,415.3 196.7,554.7 203.6,558.8 283.2,423.3 330.4,423.3 	"
      />
      <path
        id="gate_20_34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M284.7,420.7L284.7,420.7c35.5-60.5,77.9-133,78.6-134.3l-4.6-2L356,283c-1.5,2.9-51.8,88.7-78.2,133.7l0,0
        l-66.7,114l6.2,3.6l0,0h0.1l0.6,0.4l0.1-0.1l120.1,45.1l2.8-7.5l-118.8-44.6L284.7,420.7z"
      />
      <path
        id="gate_20_57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M363.3,286.4l-4.6-2L356,283c-1.5,2.9-51.8,88.7-78.2,133.7l0,0l-81.1,138l6.9,4.1l81.1-138l0,0
        C320.2,360.2,362.6,287.7,363.3,286.4z"
      />
      <polygon
        id="gate_34_57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        points="340.9,572.2 222.2,527.6 222.3,527.5 215.4,523.4 196.7,554.7 203.6,558.8 218,534.6 338.1,579.7 	
        "
      />
      <path
        id="gate_10-20_10-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-57_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_10-57_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_10-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_10-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_20-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            !isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '20-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '20-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-34') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChannel(compoSite.connection.channels, '10-20') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel"
        v-if="
          isChannel(compoSite.connection.channels, '10-20') &&
            isChannel(compoSite.connection.channels, '10-34') &&
            isChannel(compoSite.connection.channels, '10-57') &&
            isChannel(compoSite.connection.channels, '20-34') &&
            isChannel(compoSite.connection.channels, '20-57') &&
            isChannel(compoSite.connection.channels, '34-57') &&
            !isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-20'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '10-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-34'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '20-57'),
            toSearchItemAtArrInObj(compoSite.connection.channels, '34-57')
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
    </g>
    <g id="Chart 1 Only Channels">
      <polygon
        id="gate_35_36_channel_chart1"
        v-if="
          compoSite.chart.channels2['35-36'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['35-36'])"
        class="st37 cursor-pointer channels-over"
        points="584.2,553.1 503.1,414.9 503,415 422.3,276.9 415.4,281 496.1,419.1 496.3,419 577.3,557.1 	"
      />
      <polygon
        id="gate_30_41_channel_chart1"
        v-if="
          compoSite.chart.channels2['30-41'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['30-41'])"
        class="st37 cursor-pointer channels-over"
        points="582.4,614.8 577.2,608.7 498.6,674.9 498.7,675 418.7,742.4 423.8,748.5 503.9,681 503.9,681 	"
      />
      <polygon
        id="gate_39_55_channel_chart1"
        v-if="
          compoSite.chart.channels2['39-55'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['39-55'])"
        class="st37 cursor-pointer channels-over"
        points="575.4,594.4 570.2,588.3 491.6,654.4 491.5,654.3 411.5,721.8 416.6,727.9 496.7,660.6 496.7,660.6 	
        "
      />
      <polygon
        id="gate_19_49_channel_chart1"
        v-if="
          compoSite.chart.channels2['19-49'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['19-49'])"
        class="st37 cursor-pointer channels-over"
        points="559.1,579.5 553.9,573.4 475.3,639.6 475.3,639.7 395.2,707.1 400.3,713.3 480.5,645.8 480.5,645.7 	
        "
      />
      <path
        id="gate_21_45_channel_chart1"
        v-if="
          compoSite.chart.channels2['21-45'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['21-45'])"
        class="st37 cursor-pointer channels-over"
        d="M454,392.1c-12.1-20.7-35.1-60.1-35.9-61.5l-3.5,1.9l-3.6,1.8c0.2,0.4,12.4,21.2,36.1,61.8l37.4,63.5l6.9-4.1
        L454,392.1z"
      />
      <polygon
        id="gate_18_58_channel_chart1"
        v-if="
          compoSite.chart.channels2['18-58'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['18-58'])"
        class="st37 cursor-pointer channels-over"
        points="260.7,675 260.7,675 182.1,608.8 177,614.9 255.5,681.1 255.5,681.1 342,754 347.2,747.8 	"
      />
      <polygon
        id="gate_28_38_channel_chart1"
        v-if="
          compoSite.chart.channels2['28-38'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['28-38'])"
        class="st37 cursor-pointer channels-over"
        points="352.5,727.1 271.7,659.6 271.7,659.6 193.2,593.6 188.1,599.7 266.7,665.9 266.7,665.9 347.4,733.2 	
        "
      />
      <polygon
        id="gate_32_54_channel_chart1"
        v-if="
          compoSite.chart.channels2['32-54'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['32-54'])"
        class="st37 cursor-pointer channels-over"
        points="365.2,707.4 285.1,640 285,640.1 206.4,573.9 201.3,580 279.9,646.2 279.9,646.1 360,713.5 	"
      />
      <path
        id="gate_12_22_channel_chart1"
        v-if="
          compoSite.chart.channels2['12-22'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['12-22'])"
        class="st37 cursor-pointer channels-over"
        d="M487.8,419.5L487.8,419.5c-26.3-45-76.6-130.8-78.1-133.7l-3.6,1.8l3.7-1.6l-7.4,3.2
        c0.6,1.3,43,73.8,78.5,134.3l0,0l81.2,138l6.9-4.1L487.8,419.5z"
      />
      <path
        id="gate_16_48_channel_chart1"
        v-if="
          compoSite.chart.channels2['16-48'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['16-48'])"
        class="st37 cursor-pointer channels-over"
        d="M339.6,282.7l-2.7-1.4c-1.5,2.9-51.9,88.7-78.3,133.7l-81.2,138l6.9,4.1l81.2-138
        c35.5-60.5,78-133.1,78.7-134.4L339.6,282.7z"
      />
      <path
        id="gate_10_20_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['10-20'])"
        class="st37 cursor-pointer channels-over"
        d="M363.3,286.4l-4.6-2L356,283c-1.5,2.9-53.3,91.1-79.7,136.1l6.9,4l47.2,0.2v-8h-42.6
        C322.4,356.4,362.7,287.7,363.3,286.4z"
      />
      <polygon
        id="gate_27_50_channel_chart1"
        v-if="
          compoSite.chart.channels2['27-50'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['27-50'])"
        class="st37 cursor-pointer channels-over"
        points="341.5,600.5 296.1,586.5 245.6,572.1 243.4,579.8 293.9,594.2 293.9,594.1 339.2,608.2 	"
      />
      <polygon
        id="gate_06_59_channel_chart1"
        v-if="
          compoSite.chart.channels2['6-59'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['6-59'])"
        class="st37 cursor-pointer channels-over"
        points="508.8,572.6 457.9,586.9 411.6,600 413.7,607.7 460.1,594.6 510.9,580.3 	"
      />
      <polygon
        id="gate_37_40_channel_chart1"
        v-if="
          compoSite.chart.channels2['37-40'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['37-40'])"
        class="st37 cursor-pointer channels-over"
        points="534.2,529.3 516.2,498.6 509.3,502.7 527.3,533.4 544.1,561.8 551,557.7 	"
      />
      <polygon
        id="gate_25_51_channel_chart1"
        v-if="
          compoSite.chart.channels2['25-51'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['25-51'])"
        class="st37 cursor-pointer channels-over"
        points="448.6,441.6 428.3,417.5 422.2,422.7 442.5,446.8 464,472.1 470.1,466.9 	"
      />
      <polygon
        id="gate_26_44_channel_chart1"
        v-if="
          compoSite.chart.channels2['26-44'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['26-44'])"
        class="st37 cursor-pointer channels-over"
        points="446.4,486.4 443.6,478.9 335.3,519.5 335.3,519.5 223.3,561.4 226.1,568.9 338.1,526.9 338.1,527 	
        "
      />
      <g id="Vertical Channels">
        <path
          id="gate_04_63_channel_chart1"
          v-if="
            compoSite.chart.channels2['4-63'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['4-63'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 112 L 396 113.213257 L 396 114.710876 L 396 117.345947 L 396 119.469177 L 396 122.085327 L 396 124.417053 L 396 127.545044 L 396 130.843628 L 396 133.004761 L 396 136 L 405 136 L 405 133.004761 L 405 130.843628 L 405 127.545044 L 405 124.417053 L 405 122.085327 L 405 119.469177 L 405 117.345947 L 405 114.710876 L 405 113.213257 L 405 112 Z"
        />
        <path
          id="gate_47_64_channel_chart1"
          v-if="
            compoSite.chart.channels2['47-64'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['47-64'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 110 L 355 111.314392 L 355 112.936829 L 355 115.791443 L 355 118.091614 L 355 120.92572 L 355 123.451843 L 355 126.840454 L 355 130.413879 L 355 132.755127 L 355 136 L 364 136 L 364 132.755127 L 364 130.413879 L 364 126.840454 L 364 123.451843 L 364 120.92572 L 364 118.091614 L 364 115.791443 L 364 112.936829 L 364 111.314392 L 364 110 Z"
        />
        <path
          id="gate_24_61_channel_chart1"
          v-if="
            compoSite.chart.channels2['24-61'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['24-61'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 112 L 376 113.213257 L 376 114.710876 L 376 117.345947 L 376 119.469177 L 376 122.085327 L 376 124.417053 L 376 127.545044 L 376 130.843628 L 376 133.004761 L 376 136 L 384 136 L 384 133.004761 L 384 130.843628 L 384 127.545044 L 384 124.417053 L 384 122.085327 L 384 119.469177 L 384 117.345947 L 384 114.710876 L 384 113.213257 L 384 112 Z"
        />
        <path
          id="gate_11_56_channel_chart1"
          v-if="
            compoSite.chart.channels2['11-56'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['11-56'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 173 L 396 177.24646 L 396 182.488159 L 396 191.710876 L 396 199.142151 L 396 208.298584 L 396 216.459717 L 396 227.407593 L 396 238.952637 L 396 246.516602 L 396 257 L 405 257 L 405 246.516602 L 405 238.952637 L 405 227.407593 L 405 216.459717 L 405 208.298584 L 405 199.142151 L 405 191.710876 L 405 182.488159 L 405 177.24646 L 405 173 Z"
        />
        <path
          id="gate_17_62_channel_chart1"
          v-if="
            compoSite.chart.channels2['17-62'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['17-62'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 178 L 355 181.993652 L 355 186.923401 L 355 195.597168 L 355 202.586121 L 355 211.197449 L 355 218.872803 L 355 229.169006 L 355 240.026855 L 355 247.140625 L 355 257 L 364 257 L 364 247.140625 L 364 240.026855 L 364 229.169006 L 364 218.872803 L 364 211.197449 L 364 202.586121 L 364 195.597168 L 364 186.923401 L 364 181.993652 L 364 178 Z"
        />
        <path
          id="gate_23_43_channel_chart1"
          v-if="
            compoSite.chart.channels2['23-43'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['23-43'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 212 L 376 214.274902 L 376 217.082947 L 376 222.023682 L 376 226.004761 L 376 230.909973 L 376 235.281982 L 376 241.146912 L 376 247.331726 L 376 251.383911 L 376 257 L 384 257 L 384 251.383911 L 384 247.331726 L 384 241.146912 L 384 235.281982 L 384 230.909973 L 384 226.004761 L 384 222.023682 L 384 217.082947 L 384 214.274902 L 384 212 Z"
        />
        <path
          id="gate_07_31_channel_chart1"
          v-if="
            compoSite.chart.channels2['7-31'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['7-31'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 334 L 355 336.628754 L 355 339.873627 L 355 345.582947 L 355 350.183258 L 355 355.851501 L 355 360.903625 L 355 367.680878 L 355 374.82782 L 355 379.510284 L 355 386 L 364 386 L 364 379.510284 L 364 374.82782 L 364 367.680878 L 364 360.903625 L 364 355.851501 L 364 350.183258 L 364 345.582947 L 364 339.873627 L 364 336.628754 L 364 334 Z"
        />
        <path
          id="gate_01_08_channel_chart1"
          v-if="
            compoSite.chart.channels2['1-8'] && isChartTurnOn && !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['1-8'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 336 L 376 338.173767 L 376 340.857025 L 376 345.578186 L 376 349.382294 L 376 354.069519 L 376 358.247223 L 376 363.851501 L 376 369.761444 L 376 373.633484 L 376 379 L 384 379 L 384 373.633484 L 384 369.761444 L 384 363.851501 L 384 358.247223 L 384 354.069519 L 384 349.382294 L 384 345.578186 L 384 340.857025 L 384 338.173767 L 384 336 Z"
        />
        <path
          id="gate_13_33_channel_chart1"
          v-if="
            compoSite.chart.channels2['13-33'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['13-33'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 336 L 396 338.527649 L 396 341.647705 L 396 347.137451 L 396 351.560822 L 396 357.011047 L 396 361.868866 L 396 368.385468 L 396 375.257507 L 396 379.759888 L 396 386 L 405 386 L 405 379.759888 L 405 375.257507 L 405 368.385468 L 405 361.868866 L 405 357.011047 L 405 351.560822 L 405 347.137451 L 405 341.647705 L 405 338.527649 L 405 336 Z"
        />
        <path
          id="gate_5_15_channel_chart1"
          v-if="
            compoSite.chart.channels2['5-15'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['5-15'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 449 L 355 453.954193 L 355 460.069519 L 355 470.829376 L 355 479.499207 L 355 490.181671 L 355 499.703003 L 355 512.475525 L 355 525.944702 L 355 534.769348 L 355 547 L 364 547 L 364 534.769348 L 364 525.944702 L 364 512.475525 L 364 499.703003 L 364 490.181671 L 364 479.499207 L 364 470.829376 L 364 460.069519 L 364 453.954193 L 364 449 Z"
        />
        <path
          id="gate_2_14_channel_chart1"
          v-if="
            compoSite.chart.channels2['2-14'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['2-14'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 463 L 376 467.145325 L 376 472.262238 L 376 481.265411 L 376 488.519745 L 376 497.45813 L 376 505.424957 L 376 516.112183 L 376 527.382324 L 376 534.766174 L 376 545 L 384 545 L 384 534.766174 L 384 527.382324 L 384 516.112183 L 384 505.424957 L 384 497.45813 L 384 488.519745 L 384 481.265411 L 384 472.262238 L 384 467.145325 L 384 463 Z"
        />
        <path
          id="gate_29_46_channel_chart1"
          v-if="
            compoSite.chart.channels2['29-46'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['29-46'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 451 L 396 455.903625 L 396 461.956543 L 396 472.606628 L 396 481.187988 L 396 491.761444 L 396 501.185638 L 396 513.827759 L 396 527.159546 L 396 535.894165 L 396 548 L 405 548 L 405 535.894165 L 405 527.159546 L 405 513.827759 L 405 501.185638 L 405 491.761444 L 405 481.187988 L 405 472.606628 L 405 461.956543 L 405 455.903625 L 405 451 Z"
        />
        <path
          id="gate_42_53_channel_chart1"
          v-if="
            compoSite.chart.channels2['42-53'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['42-53'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 627 L 355 629.375977 L 355 632.308838 L 355 637.469177 L 355 641.627197 L 355 646.750366 L 355 651.316772 L 355 657.442322 L 355 663.902039 L 355 668.134277 L 355 674 L 364 674 L 364 668.134277 L 364 663.902039 L 364 657.442322 L 364 651.316772 L 364 646.750366 L 364 641.627197 L 364 637.469177 L 364 632.308838 L 364 629.375977 L 364 627 Z"
        />
        <path
          id="gate_03_60_channel_chart1"
          v-if="
            compoSite.chart.channels2['3-60'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['3-60'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 632 L 376 634.022095 L 376 636.518188 L 376 640.909973 L 376 644.448669 L 376 648.808838 L 376 652.695068 L 376 657.908386 L 376 663.406006 L 376 667.007874 L 376 672 L 384 672 L 384 667.007874 L 384 663.406006 L 384 657.908386 L 384 652.695068 L 384 648.808838 L 384 644.448669 L 384 640.909973 L 384 636.518188 L 384 634.022095 L 384 632 Z"
        />
        <path
          id="gate_09_52_channel_chart1"
          v-if="
            compoSite.chart.channels2['9-52'] &&
              isChartTurnOn &&
              !isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart.channels2['9-52'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 627 L 396 629.426514 L 396 632.421814 L 396 637.691956 L 396 641.938354 L 396 647.170593 L 396 651.834106 L 396 658.090027 L 396 664.687195 L 396 669.00946 L 396 675 L 405 675 L 405 669.00946 L 405 664.687195 L 405 658.090027 L 405 651.834106 L 405 647.170593 L 405 641.938354 L 405 637.691956 L 405 632.421814 L 405 629.426514 L 405 627 Z"
        />
      </g>
      <polygon
        id="gate_10_34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['10-34'])"
        class="st37 cursor-pointer channels-over"
        points="222.2,527.6 283.2,423.3 330.4,423.3 330.4,415.3 278.7,415.3 210.6,531 218.1,534.6 338.1,579.7 
        340.9,572.2 	"
      />
      <polygon
        id="gate_10_57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['10-57'])"
        class="st37 cursor-pointer channels-over"
        points="330.4,415.3 278.7,415.3 196.7,554.7 203.6,558.8 283.2,423.3 330.4,423.3 	"
      />
      <path
        id="gate_20_34_channel_chart1"
        v-if="
          compoSite.chart.channels2['20-34'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['20-34'])"
        class="st37 cursor-pointer channels-over"
        d="M284.7,420.7L284.7,420.7c35.5-60.5,77.9-133,78.6-134.3l-4.6-2L356,283c-1.5,2.9-51.8,88.7-78.2,133.7l0,0
        l-66.7,114l6.2,3.6l0,0h0.1l0.6,0.4l0.1-0.1l120.1,45.1l2.8-7.5l-118.8-44.6L284.7,420.7z"
      />
      <path
        id="gate_20_57_channel_chart1"
        v-if="
          compoSite.chart.channels2['20-57'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['20-57'])"
        class="st37 cursor-pointer channels-over"
        d="M363.3,286.4l-4.6-2L356,283c-1.5,2.9-51.8,88.7-78.2,133.7l0,0l-81.1,138l6.9,4.1l81.1-138l0,0
        C320.2,360.2,362.6,287.7,363.3,286.4z"
      />
      <polygon
        id="gate_34_57_channel_chart1"
        v-if="
          compoSite.chart.channels2['34-57'] && isChartTurnOn && !isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart.channels2['34-57'])"
        class="st37 cursor-pointer channels-over"
        points="340.9,572.2 222.2,527.6 222.3,527.5 215.4,523.4 196.7,554.7 203.6,558.8 218,534.6 338.1,579.7 	
        "
      />
      <path
        id="gate_10-20_10-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-57_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_10-57_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_10-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_10-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            !compoSite.chart.channels2['10-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            !compoSite.chart.channels2['10-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_20-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            !compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['20-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['20-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-34'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            !compoSite.chart.channels2['10-20'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart1"
        v-if="
          compoSite.chart.channels2['10-20'] &&
            compoSite.chart.channels2['10-34'] &&
            compoSite.chart.channels2['10-57'] &&
            compoSite.chart.channels2['20-34'] &&
            compoSite.chart.channels2['20-57'] &&
            compoSite.chart.channels2['34-57'] &&
            isChartTurnOn &&
            !isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart.channels2['10-20'],
            compoSite.chart.channels2['10-34'],
            compoSite.chart.channels2['10-57'],
            compoSite.chart.channels2['20-34'],
            compoSite.chart.channels2['20-57'],
            compoSite.chart.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
    </g>
    <g id="Chart 2 Only Channels">
      <polygon
        id="gate_35_36_channel_chart2"
        v-if="
          compoSite.chart2.channels2['35-36'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['35-36'])"
        class="st37 cursor-pointer channels-over"
        points="584.2,553.1 503.1,414.9 503,415 422.3,276.9 415.4,281 496.1,419.1 496.3,419 577.3,557.1 	"
      />
      <polygon
        id="gate_30_41_channel_chart2"
        v-if="
          compoSite.chart2.channels2['30-41'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['30-41'])"
        class="st37 cursor-pointer channels-over"
        points="582.4,614.8 577.2,608.7 498.6,674.9 498.7,675 418.7,742.4 423.8,748.5 503.9,681 503.9,681 	"
      />
      <polygon
        id="gate_39_55_channel_chart2"
        v-if="
          compoSite.chart2.channels2['39-55'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['39-55'])"
        class="st37 cursor-pointer channels-over"
        points="575.4,594.4 570.2,588.3 491.6,654.4 491.5,654.3 411.5,721.8 416.6,727.9 496.7,660.6 496.7,660.6 	
        "
      />
      <polygon
        id="gate_19_49_channel_chart2"
        v-if="
          compoSite.chart2.channels2['19-49'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['19-49'])"
        class="st37 cursor-pointer channels-over"
        points="559.1,579.5 553.9,573.4 475.3,639.6 475.3,639.7 395.2,707.1 400.3,713.3 480.5,645.8 480.5,645.7 	
        "
      />
      <path
        id="gate_21_45_channel_chart2"
        v-if="
          compoSite.chart2.channels2['21-45'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['21-45'])"
        class="st37 cursor-pointer channels-over"
        d="M454,392.1c-12.1-20.7-35.1-60.1-35.9-61.5l-3.5,1.9l-3.6,1.8c0.2,0.4,12.4,21.2,36.1,61.8l37.4,63.5l6.9-4.1
        L454,392.1z"
      />
      <polygon
        id="gate_18_58_channel_chart2"
        v-if="
          compoSite.chart2.channels2['18-58'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['18-58'])"
        class="st37 cursor-pointer channels-over"
        points="260.7,675 260.7,675 182.1,608.8 177,614.9 255.5,681.1 255.5,681.1 342,754 347.2,747.8 	"
      />
      <polygon
        id="gate_28_38_channel_chart2"
        v-if="
          compoSite.chart2.channels2['28-38'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['28-38'])"
        class="st37 cursor-pointer channels-over"
        points="352.5,727.1 271.7,659.6 271.7,659.6 193.2,593.6 188.1,599.7 266.7,665.9 266.7,665.9 347.4,733.2 	
        "
      />
      <polygon
        id="gate_32_54_channel_chart2"
        v-if="
          compoSite.chart2.channels2['32-54'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['32-54'])"
        class="st37 cursor-pointer channels-over"
        points="365.2,707.4 285.1,640 285,640.1 206.4,573.9 201.3,580 279.9,646.2 279.9,646.1 360,713.5 	"
      />
      <path
        id="gate_12_22_channel_chart2"
        v-if="
          compoSite.chart2.channels2['12-22'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['12-22'])"
        class="st37 cursor-pointer channels-over"
        d="M487.8,419.5L487.8,419.5c-26.3-45-76.6-130.8-78.1-133.7l-3.6,1.8l3.7-1.6l-7.4,3.2
        c0.6,1.3,43,73.8,78.5,134.3l0,0l81.2,138l6.9-4.1L487.8,419.5z"
      />
      <path
        id="gate_16_48_channel_chart2"
        v-if="
          compoSite.chart2.channels2['16-48'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['16-48'])"
        class="st37 cursor-pointer channels-over"
        d="M339.6,282.7l-2.7-1.4c-1.5,2.9-51.9,88.7-78.3,133.7l-81.2,138l6.9,4.1l81.2-138
        c35.5-60.5,78-133.1,78.7-134.4L339.6,282.7z"
      />
      <path
        id="gate_10_20_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['10-20'])"
        class="st37 cursor-pointer channels-over"
        d="M363.3,286.4l-4.6-2L356,283c-1.5,2.9-53.3,91.1-79.7,136.1l6.9,4l47.2,0.2v-8h-42.6
        C322.4,356.4,362.7,287.7,363.3,286.4z"
      />
      <polygon
        id="gate_27_50_channel_chart2"
        v-if="
          compoSite.chart2.channels2['27-50'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['27-50'])"
        class="st37 cursor-pointer channels-over"
        points="341.5,600.5 296.1,586.5 245.6,572.1 243.4,579.8 293.9,594.2 293.9,594.1 339.2,608.2 	"
      />
      <polygon
        id="gate_06_59_channel_chart2"
        v-if="
          compoSite.chart2.channels2['6-59'] && !isChartTurnOn && isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['6-59'])"
        class="st37 cursor-pointer channels-over"
        points="508.8,572.6 457.9,586.9 411.6,600 413.7,607.7 460.1,594.6 510.9,580.3 	"
      />
      <polygon
        id="gate_37_40_channel_chart2"
        v-if="
          compoSite.chart2.channels2['37-40'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['37-40'])"
        class="st37 cursor-pointer channels-over"
        points="534.2,529.3 516.2,498.6 509.3,502.7 527.3,533.4 544.1,561.8 551,557.7 	"
      />
      <polygon
        id="gate_25_51_channel_chart2"
        v-if="
          compoSite.chart2.channels2['25-51'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['25-51'])"
        class="st37 cursor-pointer channels-over"
        points="448.6,441.6 428.3,417.5 422.2,422.7 442.5,446.8 464,472.1 470.1,466.9 	"
      />
      <polygon
        id="gate_26_44_channel_chart2"
        v-if="
          compoSite.chart2.channels2['26-44'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['26-44'])"
        class="st37 cursor-pointer channels-over"
        points="446.4,486.4 443.6,478.9 335.3,519.5 335.3,519.5 223.3,561.4 226.1,568.9 338.1,526.9 338.1,527 	
        "
      />
      <g id="Vertical Channels">
        <path
          id="gate_04_63_channel_chart2"
          v-if="
            compoSite.chart2.channels2['4-63'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['4-63'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 112 L 396 113.213257 L 396 114.710876 L 396 117.345947 L 396 119.469177 L 396 122.085327 L 396 124.417053 L 396 127.545044 L 396 130.843628 L 396 133.004761 L 396 136 L 405 136 L 405 133.004761 L 405 130.843628 L 405 127.545044 L 405 124.417053 L 405 122.085327 L 405 119.469177 L 405 117.345947 L 405 114.710876 L 405 113.213257 L 405 112 Z"
        />
        <path
          id="gate_47_64_channel_chart2"
          v-if="
            compoSite.chart2.channels2['47-64'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['47-64'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 110 L 355 111.314392 L 355 112.936829 L 355 115.791443 L 355 118.091614 L 355 120.92572 L 355 123.451843 L 355 126.840454 L 355 130.413879 L 355 132.755127 L 355 136 L 364 136 L 364 132.755127 L 364 130.413879 L 364 126.840454 L 364 123.451843 L 364 120.92572 L 364 118.091614 L 364 115.791443 L 364 112.936829 L 364 111.314392 L 364 110 Z"
        />
        <path
          id="gate_24_61_channel_chart2"
          v-if="
            compoSite.chart2.channels2['24-61'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['24-61'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 112 L 376 113.213257 L 376 114.710876 L 376 117.345947 L 376 119.469177 L 376 122.085327 L 376 124.417053 L 376 127.545044 L 376 130.843628 L 376 133.004761 L 376 136 L 384 136 L 384 133.004761 L 384 130.843628 L 384 127.545044 L 384 124.417053 L 384 122.085327 L 384 119.469177 L 384 117.345947 L 384 114.710876 L 384 113.213257 L 384 112 Z"
        />
        <path
          id="gate_11_56_channel_chart2"
          v-if="
            compoSite.chart2.channels2['11-56'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['11-56'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 173 L 396 177.24646 L 396 182.488159 L 396 191.710876 L 396 199.142151 L 396 208.298584 L 396 216.459717 L 396 227.407593 L 396 238.952637 L 396 246.516602 L 396 257 L 405 257 L 405 246.516602 L 405 238.952637 L 405 227.407593 L 405 216.459717 L 405 208.298584 L 405 199.142151 L 405 191.710876 L 405 182.488159 L 405 177.24646 L 405 173 Z"
        />
        <path
          id="gate_17_62_channel_chart2"
          v-if="
            compoSite.chart2.channels2['17-62'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['17-62'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 178 L 355 181.993652 L 355 186.923401 L 355 195.597168 L 355 202.586121 L 355 211.197449 L 355 218.872803 L 355 229.169006 L 355 240.026855 L 355 247.140625 L 355 257 L 364 257 L 364 247.140625 L 364 240.026855 L 364 229.169006 L 364 218.872803 L 364 211.197449 L 364 202.586121 L 364 195.597168 L 364 186.923401 L 364 181.993652 L 364 178 Z"
        />
        <path
          id="gate_23_43_channel_chart2"
          v-if="
            compoSite.chart2.channels2['23-43'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['23-43'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 212 L 376 214.274902 L 376 217.082947 L 376 222.023682 L 376 226.004761 L 376 230.909973 L 376 235.281982 L 376 241.146912 L 376 247.331726 L 376 251.383911 L 376 257 L 384 257 L 384 251.383911 L 384 247.331726 L 384 241.146912 L 384 235.281982 L 384 230.909973 L 384 226.004761 L 384 222.023682 L 384 217.082947 L 384 214.274902 L 384 212 Z"
        />
        <path
          id="gate_07_31_channel_chart2"
          v-if="
            compoSite.chart2.channels2['7-31'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['7-31'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 334 L 355 336.628754 L 355 339.873627 L 355 345.582947 L 355 350.183258 L 355 355.851501 L 355 360.903625 L 355 367.680878 L 355 374.82782 L 355 379.510284 L 355 386 L 364 386 L 364 379.510284 L 364 374.82782 L 364 367.680878 L 364 360.903625 L 364 355.851501 L 364 350.183258 L 364 345.582947 L 364 339.873627 L 364 336.628754 L 364 334 Z"
        />
        <path
          id="gate_01_08_channel_chart2"
          v-if="
            compoSite.chart2.channels2['1-8'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['1-8'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 336 L 376 338.173767 L 376 340.857025 L 376 345.578186 L 376 349.382294 L 376 354.069519 L 376 358.247223 L 376 363.851501 L 376 369.761444 L 376 373.633484 L 376 379 L 384 379 L 384 373.633484 L 384 369.761444 L 384 363.851501 L 384 358.247223 L 384 354.069519 L 384 349.382294 L 384 345.578186 L 384 340.857025 L 384 338.173767 L 384 336 Z"
        />
        <path
          id="gate_13_33_channel_chart2"
          v-if="
            compoSite.chart2.channels2['13-33'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['13-33'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 336 L 396 338.527649 L 396 341.647705 L 396 347.137451 L 396 351.560822 L 396 357.011047 L 396 361.868866 L 396 368.385468 L 396 375.257507 L 396 379.759888 L 396 386 L 405 386 L 405 379.759888 L 405 375.257507 L 405 368.385468 L 405 361.868866 L 405 357.011047 L 405 351.560822 L 405 347.137451 L 405 341.647705 L 405 338.527649 L 405 336 Z"
        />
        <path
          id="gate_5_15_channel_chart2"
          v-if="
            compoSite.chart2.channels2['5-15'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['5-15'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 449 L 355 453.954193 L 355 460.069519 L 355 470.829376 L 355 479.499207 L 355 490.181671 L 355 499.703003 L 355 512.475525 L 355 525.944702 L 355 534.769348 L 355 547 L 364 547 L 364 534.769348 L 364 525.944702 L 364 512.475525 L 364 499.703003 L 364 490.181671 L 364 479.499207 L 364 470.829376 L 364 460.069519 L 364 453.954193 L 364 449 Z"
        />
        <path
          id="gate_2_14_channel_chart2"
          v-if="
            compoSite.chart2.channels2['2-14'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['2-14'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 463 L 376 467.145325 L 376 472.262238 L 376 481.265411 L 376 488.519745 L 376 497.45813 L 376 505.424957 L 376 516.112183 L 376 527.382324 L 376 534.766174 L 376 545 L 384 545 L 384 534.766174 L 384 527.382324 L 384 516.112183 L 384 505.424957 L 384 497.45813 L 384 488.519745 L 384 481.265411 L 384 472.262238 L 384 467.145325 L 384 463 Z"
        />
        <path
          id="gate_29_46_channel_chart2"
          v-if="
            compoSite.chart2.channels2['29-46'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['29-46'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 451 L 396 455.903625 L 396 461.956543 L 396 472.606628 L 396 481.187988 L 396 491.761444 L 396 501.185638 L 396 513.827759 L 396 527.159546 L 396 535.894165 L 396 548 L 405 548 L 405 535.894165 L 405 527.159546 L 405 513.827759 L 405 501.185638 L 405 491.761444 L 405 481.187988 L 405 472.606628 L 405 461.956543 L 405 455.903625 L 405 451 Z"
        />
        <path
          id="gate_42_53_channel_chart2"
          v-if="
            compoSite.chart2.channels2['42-53'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['42-53'])"
          class="st37 cursor-pointer channels-over"
          d="M 355 627 L 355 629.375977 L 355 632.308838 L 355 637.469177 L 355 641.627197 L 355 646.750366 L 355 651.316772 L 355 657.442322 L 355 663.902039 L 355 668.134277 L 355 674 L 364 674 L 364 668.134277 L 364 663.902039 L 364 657.442322 L 364 651.316772 L 364 646.750366 L 364 641.627197 L 364 637.469177 L 364 632.308838 L 364 629.375977 L 364 627 Z"
        />
        <path
          id="gate_03_60_channel_chart2"
          v-if="
            compoSite.chart2.channels2['3-60'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['3-60'])"
          class="st37 cursor-pointer channels-over"
          d="M 376 632 L 376 634.022095 L 376 636.518188 L 376 640.909973 L 376 644.448669 L 376 648.808838 L 376 652.695068 L 376 657.908386 L 376 663.406006 L 376 667.007874 L 376 672 L 384 672 L 384 667.007874 L 384 663.406006 L 384 657.908386 L 384 652.695068 L 384 648.808838 L 384 644.448669 L 384 640.909973 L 384 636.518188 L 384 634.022095 L 384 632 Z"
        />
        <path
          id="gate_09_52_channel_chart2"
          v-if="
            compoSite.chart2.channels2['9-52'] &&
              !isChartTurnOn &&
              isChart2TurnOn
          "
          @click="openTooltipsDialog(compoSite.chart2.channels2['9-52'])"
          class="st37 cursor-pointer channels-over"
          d="M 396 627 L 396 629.426514 L 396 632.421814 L 396 637.691956 L 396 641.938354 L 396 647.170593 L 396 651.834106 L 396 658.090027 L 396 664.687195 L 396 669.00946 L 396 675 L 405 675 L 405 669.00946 L 405 664.687195 L 405 658.090027 L 405 651.834106 L 405 647.170593 L 405 641.938354 L 405 637.691956 L 405 632.421814 L 405 629.426514 L 405 627 Z"
        />
      </g>
      <polygon
        id="gate_10_34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['10-34'])"
        class="st37 cursor-pointer channels-over"
        points="222.2,527.6 283.2,423.3 330.4,423.3 330.4,415.3 278.7,415.3 210.6,531 218.1,534.6 338.1,579.7 
        340.9,572.2 	"
      />
      <polygon
        id="gate_10_57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['10-57'])"
        class="st37 cursor-pointer channels-over"
        points="330.4,415.3 278.7,415.3 196.7,554.7 203.6,558.8 283.2,423.3 330.4,423.3 	"
      />
      <path
        id="gate_20_34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['20-34'])"
        class="st37 cursor-pointer channels-over"
        d="M284.7,420.7L284.7,420.7c35.5-60.5,77.9-133,78.6-134.3l-4.6-2L356,283c-1.5,2.9-51.8,88.7-78.2,133.7l0,0
        l-66.7,114l6.2,3.6l0,0h0.1l0.6,0.4l0.1-0.1l120.1,45.1l2.8-7.5l-118.8-44.6L284.7,420.7z"
      />
      <path
        id="gate_20_57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['20-57'])"
        class="st37 cursor-pointer channels-over"
        d="M363.3,286.4l-4.6-2L356,283c-1.5,2.9-51.8,88.7-78.2,133.7l0,0l-81.1,138l6.9,4.1l81.1-138l0,0
        C320.2,360.2,362.6,287.7,363.3,286.4z"
      />
      <polygon
        id="gate_34_57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="openTooltipsDialog(compoSite.chart2.channels2['34-57'])"
        class="st37 cursor-pointer channels-over"
        points="340.9,572.2 222.2,527.6 222.3,527.5 215.4,523.4 196.7,554.7 203.6,558.8 218,534.6 338.1,579.7 	
        "
      />
      <path
        id="gate_10-20_10-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-57_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_10-57_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 203.600388 558.799988 L 196.699997 554.700378 L 276.422668 419.171082 L 276.299622 419.100769 C 302.699615 374.1008 354.500793 285.8992 356.000793 282.999207 L 358.700012 284.399597 L 363.299622 286.399597 C 362.699646 287.699615 322.399567 356.400055 287.799622 415.299988 L 330.399231 415.299988 L 330.399231 423.299988 L 283.200012 423.299988 L 203.600388 558.799988 Z"
      />
      <path
        id="gate_10-20_10-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100006 579.700012 L 218.100006 534.600403 L 218.000397 534.700012 L 217.400787 534.299622 L 217.299225 534.299622 L 216.469147 533.8172 L 210.600006 531.000793 L 265.074615 438.450012 L 277.799225 416.700012 C 304.199219 371.700073 354.500397 285.900787 356.000397 283.000793 L 358.699615 284.399231 L 363.299225 286.399231 C 362.61972 287.661224 322.680908 355.956512 287.867584 415.299622 L 330.400787 415.299622 L 330.400787 423.299622 L 283.199615 423.299622 L 222.199615 527.600403 L 341.000397 572.200012 L 338.199615 579.700012 L 338.111725 579.666809 L 338.100006 579.700012 Z"
      />
      <path
        id="gate_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.199982 579.700012 L 218.100388 534.600403 L 218.000778 534.700012 L 217.830856 534.586731 L 203.600388 558.799622 L 196.699997 554.700012 L 277.799591 416.700012 C 304.199615 371.700073 354.500763 285.900787 356.000763 283.000793 L 358.699982 284.399231 L 363.299591 286.399231 C 363.124603 286.724213 360.343079 291.499176 355.715607 299.424622 C 355.715393 299.424988 355.71582 299.426208 355.715607 299.426575 C 341.831268 323.206238 311.324982 375.349701 284.699982 420.799622 L 273.37381 440.071106 L 222.199997 527.600403 L 341.000763 572.200012 L 338.199982 579.700012 Z"
      />
      <path
        id="gate_10-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100372 579.700012 L 218.100388 534.600403 L 217.879684 534.494934 L 203.600388 558.799622 L 196.699997 554.700012 L 210.631638 531.016418 L 210.600388 531.000793 L 278.699982 415.299622 L 330.3992 415.299622 L 330.3992 423.299622 L 283.199982 423.299622 L 222.199997 527.600403 L 340.8992 572.200012 L 338.100372 579.700012 Z"
      />
      <path
        id="gate_10-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-57_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_20-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            !compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['20-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['20-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-57_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-34'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-34_10-57_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !compoSite.chart2.channels2['10-20'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
      <path
        id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart2"
        v-if="
          compoSite.chart2.channels2['10-20'] &&
            compoSite.chart2.channels2['10-34'] &&
            compoSite.chart2.channels2['10-57'] &&
            compoSite.chart2.channels2['20-34'] &&
            compoSite.chart2.channels2['20-57'] &&
            compoSite.chart2.channels2['34-57'] &&
            !isChartTurnOn &&
            isChart2TurnOn
        "
        @click="
          openTooltipsMultiDialog(
            compoSite.chart2.channels2['10-20'],
            compoSite.chart2.channels2['10-34'],
            compoSite.chart2.channels2['10-57'],
            compoSite.chart2.channels2['20-34'],
            compoSite.chart2.channels2['20-57'],
            compoSite.chart2.channels2['34-57']
          )
        "
        class="st37 cursor-pointer channels-over"
        d="M 338.100403 579.700012 L 218.073044 534.627747 L 218.000778 534.700012 L 217.957809 534.672668 L 203.600388 558.799622 L 196.699997 554.700012 L 276.366028 419.137512 L 276.299622 419.100403 C 300.260193 378.258545 345.139099 301.83847 354.350403 285.895325 C 354.356506 285.884735 354.367737 285.866791 354.37384 285.856262 C 354.380005 285.845551 354.387238 285.83371 354.393372 285.823059 C 354.991486 284.787567 355.440948 284.002136 355.713684 283.518372 C 355.856476 283.265076 355.953918 283.091431 356.000793 283.000793 L 358.700012 284.399231 L 363.299622 286.399231 C 362.95932 287.136536 349.546265 310.067047 331.940247 340.094543 C 318.557831 362.973022 302.971954 389.603546 287.920715 415.299622 L 330.399231 415.299622 L 330.399231 423.299622 L 283.34845 423.100403 L 273.229309 440.3172 L 222.268356 527.483215 L 222.299606 527.500793 L 222.199997 527.600403 L 341.000793 572.200012 L 338.200012 579.700012 L 338.112122 579.666809 L 338.100403 579.700012 Z"
      />
    </g>
    <g
      id="layer3"
      inkscape:groupmode="layer"
      inkscape:label="Centers"
      sodipodi:insensitive="true"
    >
      <g id="g1824" transform="translate(30.889992,17.409983)">
        <g>
          <g>
            <path
              class="st30"
              d="M344.8,9.5l-40,84.5c-1.2,2.5,0.6,5.4,3.4,5.4h80c2.8,0,4.6-2.9,3.4-5.4l-40-84.5
                    C350.3,6.6,346.2,6.6,344.8,9.5z"
            />
          </g>
        </g>
        <path
          class="st30"
          d="M388.2,113.5h-80c-2.8,0-4.6,2.9-3.4,5.4l40,84.5c0.7,1.4,2,2.2,3.4,2.2s2.7-0.7,3.4-2.2l40-84.5
            C392.8,116.3,391,113.5,388.2,113.5z"
        />
        <g
          id="path1810_00000134962507418448608990000018410222364322700207_"
          inkscape:connector-curvature="0"
        >
          <path
            class="st30"
            d="M461.3,429l28.3,55.8c1.4,2.7,0.4,6.1-2.4,7.4c-1.2,0.6-2.6,0.8-3.8,0.4l-79-19.1c-3-0.7-4.8-3.8-4-6.7
                c0.3-1.3,1.1-2.3,2.1-3.1l50.7-36.7c2.5-1.8,6-1.3,7.8,1.3C461,428.5,461.1,428.7,461.3,429L461.3,429z"
          />
        </g>

        <path
          id="center_splenic_00000025416811691161449300000000887313877762108057_"
          inkscape:connector-curvature="0"
          class="st30"
          d="
            M232.9,562.5l-84.8,38.2c-2.7,1.2-6.1,0.5-7.7-1.6c-0.5-0.7-0.8-1.5-0.8-2.3v-76.5c0-2.4,2.5-4.4,5.6-4.4c1,0,2,0.2,2.9,0.6
            l84.8,38.2c2.7,1.2,3.7,4,2.1,6.1C234.4,561.6,233.7,562.1,232.9,562.5L232.9,562.5z"
        />

        <path
          id="center_splenic_00000043458666641437615070000001668631892654645691_"
          inkscape:connector-curvature="0"
          class="st30"
          d="
            M465.4,554.7l84.8-38.2c2.7-1.2,6.1-0.5,7.7,1.6c0.5,0.7,0.8,1.5,0.8,2.3v76.5c0,2.4-2.5,4.4-5.6,4.4c-1,0-2-0.2-2.9-0.6
            l-84.8-38.2c-2.7-1.2-3.7-4-2.1-6.1C463.9,555.6,464.6,555.1,465.4,554.7L465.4,554.7z"
        />
        <circle class="st30" cx="348.2" cy="569.1" r="50.9" />
        <path
          class="st30"
          d="M342.6,456.5L295.1,409c-3.6-3.6-3.6-9.4,0-12.9l47.5-47.5c3.6-3.6,9.4-3.6,12.9,0l47.5,47.5
            c3.6,3.6,3.6,9.4,0,12.9l-47.5,47.5C352,460.1,346.2,460.1,342.6,456.5z"
        />
        <path
          class="st30"
          d="M384.5,322.4h-70.6c-4.2,0-7.6-3.5-7.6-7.6v-70.7c0-4.2,3.5-7.6,7.6-7.6h70.6c4.2,0,7.6,3.5,7.6,7.6v70.6
            C392.1,318.9,388.6,322.4,384.5,322.4z"
        />
        <path
          class="st30"
          d="M384.6,737.8h-70.9c-4.2,0-7.6-3.4-7.6-7.6v-70.9c0-4.2,3.4-7.6,7.6-7.6h70.9c4.2,0,7.6,3.4,7.6,7.6v70.9
            C392.2,734.4,388.8,737.8,384.6,737.8z"
        />
      </g>
      <g id="Слой_11" transform="translate(30.89,17.409983)">
        <path
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_8
            )
          "
          class="st26 cursor-pointer over-shadow-anim"
          d="M388.2,113.5h-80c-2.8,0-4.6,2.9-3.4,5.4l40,84.5c0.7,1.4,2,2.2,3.4,2.2s2.7-0.7,3.4-2.2l40-84.5
            C392.8,116.3,391,113.5,388.2,113.5z"
        />
        <path
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_9
            )
          "
          class="st26 cursor-pointer over-shadow-anim"
          d="M308.2,99.4h80c2.8,0,4.6-2.9,3.4-5.4l-40-84.5c-0.7-1.4-2-2.2-3.4-2.2s-2.7,0.7-3.4,2.2l-40,84.5
            C303.6,96.6,305.4,99.4,308.2,99.4z"
        />
        <path
          id="path361"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_5
            )
          "
          inkscape:connector-curvature="0"
          class="st26 cursor-pointer over-shadow-anim"
          d="M461.3,429l28.3,55.8c1.4,2.7,0.4,6.1-2.4,7.4
            c-1.2,0.6-2.6,0.8-3.8,0.4l-79-19.1c-3-0.7-4.8-3.8-4-6.7c0.3-1.3,1.1-2.3,2.1-3.1l50.7-36.7c2.5-1.8,6-1.3,7.8,1.3
            C461,428.5,461.1,428.7,461.3,429L461.3,429z"
        />

        <path
          id="center_splenic_00000179613511718783149180000013381394974760136873_"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_4
            )
          "
          inkscape:connector-curvature="0"
          class="st26 cursor-pointer over-shadow-anim"
          d="
            M232.9,562.5l-84.8,38.2c-2.7,1.2-6.1,0.5-7.7-1.6c-0.5-0.7-0.8-1.5-0.8-2.3v-76.5c0-2.4,2.5-4.4,5.6-4.4c1,0,2,0.2,2.9,0.6
            l84.8,38.2c2.7,1.2,3.7,4,2.1,6.1C234.4,561.6,233.7,562.1,232.9,562.5L232.9,562.5z"
        />

        <path
          id="center_splenic_00000031171042519979191290000003677661059959292801_"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_2
            )
          "
          inkscape:connector-curvature="0"
          class="st26 cursor-pointer over-shadow-anim"
          d="
            M465.4,554.7l84.8-38.2c2.7-1.2,6.1-0.5,7.7,1.6c0.5,0.7,0.8,1.5,0.8,2.3v76.5c0,2.4-2.5,4.4-5.6,4.4c-1,0-2-0.2-2.9-0.6
            l-84.8-38.2c-2.7-1.2-3.7-4-2.1-6.1C463.9,555.6,464.6,555.1,465.4,554.7L465.4,554.7z"
        />
        <circle
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_3
            )
          "
          class="st26 cursor-pointer over-shadow-anim"
          cx="348.2"
          cy="569.1"
          r="50.9"
        />
        <path
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_6
            )
          "
          class="st26 cursor-pointer over-shadow-anim"
          d="M342.6,456.5L295.1,409c-3.6-3.6-3.6-9.4,0-12.9l47.5-47.5c3.6-3.6,9.4-3.6,12.9,0l47.5,47.5
            c3.6,3.6,3.6,9.4,0,12.9l-47.5,47.5C352,460.1,346.2,460.1,342.6,456.5z"
        />
        <path
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_7
            )
          "
          class="st26 cursor-pointer over-shadow-anim"
          d="M384.4,322.3h-70.5c-4.2,0-7.6-3.4-7.6-7.6v-70.5c0-4.2,3.4-7.6,7.6-7.6h70.5c4.2,0,7.6,3.4,7.6,7.6v70.5
            C392,318.9,388.6,322.3,384.4,322.3z"
        />
        <path
          @click="
            openTooltipsDialog(
              compoSite.chart.centersUndefinedExt.center_undefined_1
            )
          "
          class="st26 cursor-pointer over-shadow-anim"
          d="M384.6,737.8h-71c-4.2,0-7.6-3.4-7.6-7.6v-71c0-4.2,3.4-7.6,7.6-7.6h71c4.2,0,7.6,3.4,7.6,7.6v71
            C392.3,734.4,388.8,737.8,384.6,737.8z"
        />
      </g>
      <g id="Слой_4-2" transform="translate(30.89,17.409983)">
        <path
          id="center_ego"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_5
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 5) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 5) &&
                isChart2TurnOn)
          }"
          inkscape:connector-curvature="0"
          class="st31 invisible over-shadow-anim"
          d="M461.5,428.9l28.4,56c1.4,2.7,0.4,6.1-2.4,7.5
            c-1.2,0.6-2.6,0.8-3.9,0.4l-79.2-19.2c-3-0.7-4.8-3.8-4-6.7c0.3-1.3,1.1-2.3,2.1-3.1l50.8-36.7c2.4-1.8,5.9-1.3,7.7,1.2
            C461.1,428.3,461.3,428.6,461.5,428.9L461.5,428.9z"
        />
        <path
          id="center_ego_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, 'ego')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 5) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          inkscape:connector-curvature="0"
          class="st31 invisible over-shadow-anim"
          d="M461.5,428.9l28.4,56c1.4,2.7,0.4,6.1-2.4,7.5
            c-1.2,0.6-2.6,0.8-3.9,0.4l-79.2-19.2c-3-0.7-4.8-3.8-4-6.7c0.3-1.3,1.1-2.3,2.1-3.1l50.8-36.7c2.4-1.8,5.9-1.3,7.7,1.2
            C461.1,428.3,461.3,428.6,461.5,428.9L461.5,428.9z"
        />
        <path
          id="center_splenic"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_4
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 4) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 4) &&
                isChart2TurnOn)
          }"
          inkscape:connector-curvature="0"
          class="st32 invisible over-shadow-anim"
          d="M232.9,562.5l-84.8,38.2c-2.7,1.2-6.1,0.5-7.7-1.6
            c-0.5-0.7-0.8-1.5-0.8-2.3v-76.5c0-2.4,2.5-4.4,5.6-4.4c1,0,2,0.2,2.9,0.6l84.8,38.2c2.7,1.2,3.7,4,2.1,6.1
            C234.4,561.6,233.7,562.1,232.9,562.5L232.9,562.5z"
        />
        <path
          id="center_splenic_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, 'splenic')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 4) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          inkscape:connector-curvature="0"
          class="st32 invisible over-shadow-anim"
          d="M232.9,562.5l-84.8,38.2c-2.7,1.2-6.1,0.5-7.7-1.6
            c-0.5-0.7-0.8-1.5-0.8-2.3v-76.5c0-2.4,2.5-4.4,5.6-4.4c1,0,2,0.2,2.9,0.6l84.8,38.2c2.7,1.2,3.7,4,2.1,6.1
            C234.4,561.6,233.7,562.1,232.9,562.5L232.9,562.5z"
        />
        <path
          id="center_emotional"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_2
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 2) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 2) &&
                isChart2TurnOn)
          }"
          inkscape:connector-curvature="0"
          class="st32 invisible over-shadow-anim"
          d="
            M465.4,554.7l84.8-38.2c2.7-1.2,6.1-0.5,7.7,1.6c0.5,0.7,0.8,1.5,0.8,2.3v76.5c0,2.4-2.5,4.4-5.6,4.4c-1,0-2-0.2-2.9-0.6
            l-84.8-38.2c-2.7-1.2-3.7-4-2.1-6.1C463.9,555.6,464.6,555.1,465.4,554.7L465.4,554.7z"
        />
        <path
          id="center_emotional_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, 'emo')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 2) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          inkscape:connector-curvature="0"
          class="st32 invisible over-shadow-anim"
          d="
            M465.4,554.7l84.8-38.2c2.7-1.2,6.1-0.5,7.7,1.6c0.5,0.7,0.8,1.5,0.8,2.3v76.5c0,2.4-2.5,4.4-5.6,4.4c-1,0-2-0.2-2.9-0.6
            l-84.8-38.2c-2.7-1.2-3.7-4-2.1-6.1C463.9,555.6,464.6,555.1,465.4,554.7L465.4,554.7z"
        />
        <circle
          id="center_sacral"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_3
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 3) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 3) &&
                isChart2TurnOn)
          }"
          class="st31 invisible over-shadow-anim"
          cx="348.2"
          cy="569.1"
          r="50.9"
        />
        <circle
          id="center_sacral_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, 'sacral')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 3) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st31 invisible over-shadow-anim"
          cx="348.2"
          cy="569.1"
          r="50.9"
        />
        <path
          id="center_g"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_6
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 6) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 6) &&
                isChart2TurnOn)
          }"
          class="st33 invisible over-shadow-anim"
          d="M342.6,456.5L295.1,409c-3.6-3.6-3.6-9.4,0-12.9l47.5-47.5c3.6-3.6,9.4-3.6,12.9,0l47.5,47.5
            c3.6,3.6,3.6,9.4,0,12.9l-47.5,47.5C352,460.1,346.2,460.1,342.6,456.5z"
        />
        <path
          id="center_g_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, '_g')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 6) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st33 invisible over-shadow-anim"
          d="M342.6,456.5L295.1,409c-3.6-3.6-3.6-9.4,0-12.9l47.5-47.5c3.6-3.6,9.4-3.6,12.9,0l47.5,47.5
            c3.6,3.6,3.6,9.4,0,12.9l-47.5,47.5C352,460.1,346.2,460.1,342.6,456.5z"
        />
        <path
          id="center_root"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_1
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 1) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 1) &&
                isChart2TurnOn)
          }"
          class="st32 invisible over-shadow-anim"
          d="M384.5,737.6h-70.7c-4.2,0-7.6-3.5-7.6-7.6v-70.7c0-4.2,3.5-7.6,7.6-7.6h70.7c4.2,0,7.6,3.5,7.6,7.6V730
            C392.1,734.1,388.7,737.6,384.5,737.6z"
        />
        <path
          id="center_root_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, 'root')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 1) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st32 invisible over-shadow-anim"
          d="M384.5,737.6h-70.7c-4.2,0-7.6-3.5-7.6-7.6v-70.7c0-4.2,3.5-7.6,7.6-7.6h70.7c4.2,0,7.6,3.5,7.6,7.6V730
            C392.1,734.1,388.7,737.6,384.5,737.6z"
        />
        <g>
          <g>
            <path
              id="center_head"
              @click="
                openTooltipsDialog(
                  compoSite.chart.centersDefinedExt.center_defined_9
                )
              "
              :class="{
                visible:
                  (isDefinedObj(compoSite.chart.centersDefined, 9) &&
                    isChartTurnOn) ||
                  (isDefinedObj(compoSite.chart2.centersDefined, 9) &&
                    isChart2TurnOn)
              }"
              class="st33 invisible over-shadow-anim"
              d="M308.2,98.8c-1.1,0-2.2-0.6-2.8-1.5c-0.6-1-0.7-2.1-0.2-3.1l40-84.5c0.5-1.2,1.7-1.9,3-1.9s2.4,0.7,3,1.9
                    l40,84.5c0.5,1,0.4,2.2-0.2,3.1s-1.6,1.5-2.8,1.5H308.2z"
            />
            <path
              id="center_head_connection"
              @click="
                openTooltipsDialog(
                  toSearchItemAtArrInObj(compoSite.connection.centers, 'head')
                )
              "
              :class="{
                visible:
                  isDefinedObj(compoSite.connection.centersDefined, 9) &&
                  !isChartTurnOn &&
                  !isChart2TurnOn
              }"
              class="st33 invisible over-shadow-anim"
              d="M308.2,98.8c-1.1,0-2.2-0.6-2.8-1.5c-0.6-1-0.7-2.1-0.2-3.1l40-84.5c0.5-1.2,1.7-1.9,3-1.9s2.4,0.7,3,1.9
                    l40,84.5c0.5,1,0.4,2.2-0.2,3.1s-1.6,1.5-2.8,1.5H308.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              id="center_ajna"
              @click="
                openTooltipsDialog(
                  compoSite.chart.centersDefinedExt.center_defined_8
                )
              "
              :class="{
                visible:
                  (isDefinedObj(compoSite.chart.centersDefined, 8) &&
                    isChartTurnOn) ||
                  (isDefinedObj(compoSite.chart2.centersDefined, 8) &&
                    isChart2TurnOn)
              }"
              class="st0 invisible over-shadow-anim"
              d="M388.2,114c1.1,0,2.2,0.6,2.8,1.5c0.6,1,0.7,2.1,0.2,3.1l-40,84.5c-0.5,1.2-1.7,1.9-3,1.9s-2.4-0.7-3-1.9
                    l-40-84.5c-0.5-1-0.4-2.2,0.2-3.1s1.6-1.5,2.8-1.5C308.2,114,388.2,114,388.2,114z"
            />
            <path
              id="center_ajna_connection"
              @click="
                openTooltipsDialog(
                  toSearchItemAtArrInObj(compoSite.connection.centers, 'ajna')
                )
              "
              :class="{
                visible:
                  isDefinedObj(compoSite.connection.centersDefined, 8) &&
                  !isChartTurnOn &&
                  !isChart2TurnOn
              }"
              class="st0 invisible over-shadow-anim"
              d="M388.2,114c1.1,0,2.2,0.6,2.8,1.5c0.6,1,0.7,2.1,0.2,3.1l-40,84.5c-0.5,1.2-1.7,1.9-3,1.9s-2.4-0.7-3-1.9
                    l-40-84.5c-0.5-1-0.4-2.2,0.2-3.1s1.6-1.5,2.8-1.5C308.2,114,388.2,114,388.2,114z"
            />
            <path
              class="st34"
              d="M348.2,204.5c-1.1,0-2-0.6-2.5-1.6l-40-84.5c-0.4-0.9-0.3-1.9,0.2-2.7c0.5-0.8,1.4-1.3,2.3-1.3h80
                    c1,0,1.8,0.5,2.3,1.3s0.6,1.8,0.2,2.7l-40,84.5C350.2,203.9,349.3,204.5,348.2,204.5 M348.2,205.5c1.4,0,2.7-0.7,3.4-2.2
                    l40-84.5c1.2-2.5-0.6-5.4-3.4-5.4h-80c-2.8,0-4.6,2.9-3.4,5.4l40,84.5C345.5,204.7,346.8,205.5,348.2,205.5L348.2,205.5z"
            />
          </g>
        </g>
        <path
          id="center_throat"
          @click="
            openTooltipsDialog(
              compoSite.chart.centersDefinedExt.center_defined_7
            )
          "
          :class="{
            visible:
              (isDefinedObj(compoSite.chart.centersDefined, 7) &&
                isChartTurnOn) ||
              (isDefinedObj(compoSite.chart2.centersDefined, 7) &&
                isChart2TurnOn)
          }"
          class="st32 invisible over-shadow-anim"
          d="M384.5,322.4h-70.7c-4.2,0-7.6-3.4-7.6-7.6v-70.7c0-4.2,3.4-7.6,7.6-7.6h70.6c4.2,0,7.6,3.4,7.6,7.6v70.6
            C392.1,318.9,388.6,322.4,384.5,322.4z"
        />
        <path
          id="center_throat_connection"
          @click="
            openTooltipsDialog(
              toSearchItemAtArrInObj(compoSite.connection.centers, 'throat')
            )
          "
          :class="{
            visible:
              isDefinedObj(compoSite.connection.centersDefined, 7) &&
              !isChartTurnOn &&
              !isChart2TurnOn
          }"
          class="st32 invisible over-shadow-anim"
          d="M384.5,322.4h-70.7c-4.2,0-7.6-3.4-7.6-7.6v-70.7c0-4.2,3.4-7.6,7.6-7.6h70.6c4.2,0,7.6,3.4,7.6,7.6v70.6
            C392.1,318.9,388.6,322.4,384.5,322.4z"
        />
      </g>
    </g>
    <g id="layer4" inkscape:groupmode="layer" inkscape:label="Gate Numbers">
      <g id="g2173">
        <g>
          <text
            id="text1135_00000042707575031179185090000005196284712531463045_"
            transform="matrix(1 0 0 1 352.8802 268.7978)"
            class="st35 st1 st4"
          >
            62
          </text>

          <text
            id="text1139_00000120536278689711740270000007960630858528021389_"
            transform="matrix(1 0 0 1 372.613 684.6094)"
            class="st35 st1 st4"
          >
            60
          </text>

          <text
            id="text1141_00000018922601885424865160000017200961695575571361_"
            transform="matrix(1 0 0 1 407.5855 603.8096)"
            class="st35 st1 st4"
          >
            59
          </text>

          <text
            id="text1143_00000091710874887028874240000010205237392462655876_"
            transform="matrix(1 0 0 1 342.4839 744.8516)"
            class="st35 st1 st4"
          >
            58
          </text>

          <text
            id="text1145_00000099649983695111958710000015891853529781441924_"
            transform="matrix(1 0 0 1 197.1401 561.0224)"
            class="st35 st1 st4"
          >
            57
          </text>

          <text
            id="text1147_00000083808443895431767460000016330465345713529530_"
            transform="matrix(1 0 0 1 393.8059 268.7978)"
            class="st35 st1 st4"
          >
            56
          </text>

          <text
            id="text1149_00000137114649508733203440000016966822406113751438_"
            transform="matrix(1 0 0 1 550.5381 600.6353)"
            class="st35 st1 st4"
          >
            55
          </text>

          <text
            id="text1151_00000065787279556832385940000006551768395368262027_"
            transform="matrix(1 0 0 1 342.6031 701.0073)"
            class="st35 st1 st4"
          >
            54
          </text>

          <text
            id="text1153_00000059286999094189144340000008444145065776923286_"
            transform="matrix(1 0 0 1 352.5641 684.6101)"
            class="st35 st1 st4"
          >
            53
          </text>

          <text
            id="text1155_00000026147131721597082620000018331409492708972676_"
            transform="matrix(1 0 0 1 393.5692 684.6094)"
            class="st35 st1 st4"
          >
            52
          </text>

          <text
            id="text1157_00000125566541884441572450000017455096531676463011_"
            transform="matrix(1 0 0 1 461.2662 475.0102)"
            class="st35 st1 st4"
          >
            51
          </text>

          <text
            id="text1159_00000094620311399112127400000017384763259259321258_"
            transform="matrix(1 0 0 1 239.236 580.3292)"
            class="st35 st1 st4"
          >
            50
          </text>

          <text
            id="text1161_00000111888879778153966900000012347221773349216392_"
            transform="matrix(1 0 0 1 527.3984 590.2138)"
            class="st35 st1 st4"
          >
            49
          </text>

          <text
            id="text1163_00000183965081705626038650000016993922239047265169_"
            transform="matrix(1 0 0 1 175.4465 550.7847)"
            class="st35 st1 st4"
          >
            48
          </text>

          <text
            id="text1167_00000081630307029660171270000012388121863978902431_"
            transform="matrix(1 0 0 1 397.0877 444.5056)"
            class="st35 st1 st4"
          >
            46
          </text>

          <text
            id="text1169_00000004546656437741126020000009821016951025023166_"
            transform="matrix(1 0 0 1 402.3348 333.4392)"
            class="st35 st1 st4"
          >
            45
          </text>

          <text
            id="text1171_00000051379755347592996920000001428942757200764578_"
            transform="matrix(1 0 0 1 220.0319 570.796)"
            class="st35 st1 st4"
          >
            44
          </text>

          <text
            id="text1173_00000055673277637140931190000004605208779183033003_"
            transform="matrix(1 0 0 1 372.1555 207.1407)"
            class="st35 st1 st4"
          >
            43
          </text>

          <text
            id="text1175_00000071552570877620343090000017518358866896892592_"
            transform="matrix(1 0 0 1 352.5643 625.5475)"
            class="st35 st1 st4"
          >
            42
          </text>

          <text
            id="text1177_00000103247207011092649750000002426271531380388225_"
            transform="matrix(1 0 0 1 404.5401 744.851)"
            class="st35 st1 st4"
          >
            41
          </text>

          <text
            id="text1179_00000129168413341245445120000005640155333216557716_"
            transform="matrix(1 0 0 1 499.1901 501.634)"
            class="st35 st1 st4"
          >
            40
          </text>

          <text
            id="text1181_00000081630401615042869690000008813065849592409249_"
            transform="matrix(1 0 0 1 404.5399 723.6039)"
            class="st35 st1 st4"
          >
            39
          </text>

          <text
            id="text1183_00000150793457715457017620000003554824654587119493_"
            transform="matrix(1 0 0 1 342.6031 723.7481)"
            class="st35 st1 st4"
          >
            38
          </text>

          <text
            id="text1185_00000033343019432598933680000007204069809465686191_"
            transform="matrix(1 0 0 1 530.8349 569.4897)"
            class="st35 st1 st4"
          >
            37
          </text>

          <text
            id="text1187_00000085953345197354881760000007390486427216624545_"
            transform="matrix(1 0 0 1 572.0504 551.0963)"
            class="st35 st1 st4"
          >
            36
          </text>

          <text
            id="text1189_00000109719145681762311280000015715144105315056538_"
            transform="matrix(1 0 0 1 405.9352 288.3097)"
            class="st35 st1 st4"
          >
            35
          </text>

          <text
            id="text1191_00000000196424397202155300000001765014062575661996_"
            transform="matrix(1 0 0 1 334.4943 579.5446)"
            class="st35 st1 st4"
          >
            34
          </text>

          <text
            id="text1193_00000163046656444464310570000007977960131553831077_"
            transform="matrix(1 0 0 1 383.5239 333.4573)"
            class="st35 st1 st4"
          >
            33
          </text>

          <text
            id="text1195_00000018212973069359731140000000792094430781065629_"
            transform="matrix(1 0 0 1 220.0319 590.214)"
            class="st35 st1 st4"
          >
            32
          </text>

          <text
            id="text1197_00000009576803784453899520000009984955566464240020_"
            transform="matrix(1 0 0 1 345.9092 333.4392)"
            class="st35 st1 st4"
          >
            31
          </text>

          <text
            id="text1199_00000024001434093928811720000010871194388908925087_"
            transform="matrix(1 0 0 1 572.5524 610.0756)"
            class="st35 st1 st4"
          >
            30
          </text>

          <text
            id="text1201_00000109738568042806280890000016943206090649607102_"
            transform="matrix(1 0 0 1 393.5689 556.4897)"
            class="st35 st1 st4"
          >
            29
          </text>

          <text
            id="text1203_00000124154427951769865750000008695951717908406196_"
            transform="matrix(1 0 0 1 196.944 600.1719)"
            class="st35 st1 st4"
          >
            28
          </text>

          <text
            id="text1205_00000177454052015150164090000007820977315874603438_"
            transform="matrix(1 0 0 1 337.09 606.0043)"
            class="st35 st1 st4"
          >
            27
          </text>

          <text
            id="text1207_00000022539570830977341720000008924315692530396073_"
            transform="matrix(1 0 0 1 442.6769 487.3175)"
            class="st35 st1 st4"
          >
            26
          </text>

          <text
            id="text1209_00000036218589488006129250000011547762202032983438_"
            transform="matrix(1 0 0 1 416.4507 424.15)"
            class="st35 st1 st4"
          >
            25
          </text>

          <text
            id="text1213_00000054263991197183313780000016084506861633275568_"
            transform="matrix(1 0 0 1 373.3484 268.7979)"
            class="st35 st1 st4"
          >
            23
          </text>

          <text
            id="text1215_00000181767916591463121940000000454873874486853308_"
            transform="matrix(1 0 0 1 551.0517 560.3696)"
            class="st35 st1 st4"
          >
            22
          </text>

          <text
            id="text1217_00000140708581462935992210000003157021891943080119_"
            transform="matrix(1 0 0 1 479.479 461.6377)"
            class="st35 st1 st4"
          >
            21
          </text>

          <text
            id="text1219_00000121976713203554696120000006836496163720144318_"
            transform="matrix(1 0 0 1 342.484 311.0103)"
            class="st35 st1 st4"
          >
            20
          </text>

          <text
            id="text1221_00000018919741604354791520000014104042293396721294_"
            transform="matrix(1 0 0 1 403.9382 701.1326)"
            class="st35 st1 st4"
          >
            19
          </text>

          <text
            id="text1223_00000121240254628096320360000014616662951313506441_"
            transform="matrix(1 0 0 1 174.6151 610.0099)"
            class="st35 st1 st4"
          >
            18
          </text>

          <text
            id="text1225_00000060714001199343892850000001732502648334916532_"
            transform="matrix(1 0 0 1 360.6152 175.4355)"
            class="st35 st1 st4"
          >
            17
          </text>

          <text
            id="text1227_00000098181702379350443560000016499591182628917659_"
            transform="matrix(1 0 0 1 341.642 288.3099)"
            class="st35 st1 st4"
          >
            16
          </text>

          <text
            id="text1229_00000168819505972016618040000012219829803173868934_"
            transform="matrix(1 0 0 1 348.3801 444.5052)"
            class="st35 st1 st4"
          >
            15
          </text>

          <text
            id="text1231_00000161593949353410463690000015815574505791442611_"
            transform="matrix(1 0 0 1 372.6131 550.3354)"
            class="st35 st1 st4"
          >
            14
          </text>

          <text
            id="text1233_00000025417557644909315050000003705311703856650158_"
            transform="matrix(1 0 0 1 396.944 403.5932)"
            class="st35 st1 st4"
          >
            13
          </text>

          <text
            id="text1235_00000024720690594309308720000012607076680779963569_"
            transform="matrix(1 0 0 1 405.5846 311.0103)"
            class="st35 st1 st4"
          >
            12
          </text>

          <text
            id="text1237_00000067200683134364556850000005901469269546652053_"
            transform="matrix(1 0 0 1 386.0891 175.3354)"
            class="st35 st1 st4"
          >
            11
          </text>

          <text
            id="text1239_00000052813854822761793910000005518041097901108891_"
            transform="matrix(1 0 0 1 329.1732 424.1495)"
            class="st35 st1 st4"
          >
            10
          </text>

          <text
            id="text1241_00000098222984611359342170000008237162346007180714_"
            transform="matrix(1 0 0 1 395.1625 626.2961)"
            class="st35 st1 st4"
          >
            9
          </text>

          <text
            id="text1243_00000109028100242548349250000005999271114232073883_"
            transform="matrix(1 0 0 1 367.9403 333.4573)"
            class="st35 st1 st4"
          >
            8
          </text>

          <text
            id="text1245_00000045581829548188519520000017525386821373885102_"
            transform="matrix(1 0 0 1 352.1901 403.5932)"
            class="st35 st1 st4"
          >
            7
          </text>

          <text
            id="text1247_00000013164934337035749470000006726719770398411706_"
            transform="matrix(1 0 0 1 512.0424 580.3294)"
            class="st35 st1 st4"
          >
            6
          </text>

          <text
            id="text1249_00000087408112507368101120000000957385076736704133_"
            transform="matrix(1 0 0 1 354.5763 556.6335)"
            class="st35 st1 st4"
          >
            5
          </text>

          <text
            id="text1253_00000064344092409298794240000000036413548403994497_"
            transform="matrix(1 0 0 1 375.7817 631.6357)"
            class="st35 st1 st4"
          >
            3
          </text>

          <text
            id="text1255_00000080189119235689363710000004980419887820211386_"
            transform="matrix(1 0 0 1 376.4817 467.6089)"
            class="st35 st1 st4"
          >
            2
          </text>

          <text
            id="text1257_00000124882627619268881240000002812030036318792067_"
            transform="matrix(1 0 0 1 377.0154 378.5356)"
            class="st35 st1 st4"
          >
            1
          </text>

          <text
            id="text1237_00000175282328998338184380000015950297621521796254_"
            transform="matrix(1 0 0 1 397.0879 147.2121)"
            class="st35 st1 st4"
          >
            4
          </text>

          <text
            id="text1237_00000179604897257842826240000006513381250168546233_"
            transform="matrix(1 0 0 1 373.3487 147.2121)"
            class="st35 st1 st4"
          >
            24
          </text>

          <text
            id="text1237_00000003066473774004792690000011049377181923350163_"
            transform="matrix(1 0 0 1 352.8802 147.2121)"
            class="st35 st1 st4"
          >
            47
          </text>

          <text
            id="text1237_00000063616648093135097700000010552281371880858780_"
            transform="matrix(1 0 0 1 394.3403 109.2251)"
            class="st35 st1 st4"
          >
            63
          </text>

          <text
            id="text1237_00000030481374077676533840000001017499415803897520_"
            transform="matrix(1 0 0 1 374.1519 109.2251)"
            class="st35 st1 st4"
          >
            61
          </text>

          <text
            id="text1237_00000083068184043181201200000013808221369069390479_"
            transform="matrix(1 0 0 1 352.5686 109.2251)"
            class="st35 st1 st4"
          >
            64
          </text>
        </g>
      </g>
    </g>
    <g id="layer5" inkscape:groupmode="layer" inkscape:label="Gates Active">
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[17])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 17) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 17) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="367.9" cy="171.2" r="8.4" />
        </g>
        <g id="text1225_00000163780106663957850400000016205098616182825395_">
          <text
            transform="matrix(1 0 0 1 360.6152 175.4355)"
            class="st24 st1 st4"
          >
            17
          </text>
        </g>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[17])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 17) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 17) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="367.9" cy="171.2" r="8.4" />
        </g>
        <g id="text1225_00000163780106663957850400000016205098616182825395_">
          <text
            transform="matrix(1 0 0 1 360.6152 175.4355)"
            class="st24 st1 st4"
          >
            17
          </text>
        </g>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[11])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 11) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 11) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="392.1" cy="171.2" r="8.4" />
        </g>
        <g id="text1237_00000060004437169043664710000016403282321731801531_">
          <text
            transform="matrix(1 0 0 1 386.0891 175.3354)"
            class="st24 st1 st4"
          >
            11
          </text>
        </g>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[11])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 11) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 11) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="392.1" cy="171.2" r="8.4" />
        </g>
        <g id="text1237_00000060004437169043664710000016403282321731801531_">
          <text
            transform="matrix(1 0 0 1 386.0891 175.3354)"
            class="st24 st1 st4"
          >
            11
          </text>
        </g>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[43])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 43) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 43) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="379.1" cy="203.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 372.1555 207.1405)"
          class="st24 st1 st4"
        >
          43
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[43])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 43) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 43) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="379.1" cy="203.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 372.1555 207.1405)"
          class="st24 st1 st4"
        >
          43
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[64])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 64) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 64) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.2" cy="105" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.7361 109.2249)"
          class="st24 st1 st4"
        >
          64
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[64])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 64) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 64) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.2" cy="105" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.7361 109.2249)"
          class="st24 st1 st4"
        >
          64
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[61])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 61) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 61) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380.2" cy="105" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 374.1436 109.2251)"
          class="st24 st1 st4"
        >
          61
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[61])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 61) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 61) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380.2" cy="105" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 374.1436 109.2251)"
          class="st24 st1 st4"
        >
          61
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[63])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 63) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 63) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="401.2" cy="105" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 394.3398 109.225)" class="st24 st1 st4">
          63
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[63])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 63) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 63) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="401.2" cy="105" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 394.3398 109.225)" class="st24 st1 st4">
          63
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[24])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 24) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 24) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="143.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 373.3485 147.2118)"
          class="st24 st1 st4"
        >
          24
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[24])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 24) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 24) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="143.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 373.3485 147.2118)"
          class="st24 st1 st4"
        >
          24
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[4])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 4) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 4) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="400.5" cy="143.1" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 396.9438 147.212)" class="st24 st1 st4">
          4
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[4])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 4) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 4) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="400.5" cy="143.1" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 396.9438 147.212)" class="st24 st1 st4">
          4
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[47])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 47) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 47) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.5" cy="143.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.7361 147.2121)"
          class="st24 st1 st4"
        >
          47
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[47])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 47) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 47) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.5" cy="143.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.7361 147.2121)"
          class="st24 st1 st4"
        >
          47
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[23])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 23) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 23) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="264.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 373.3531 268.7979)"
          class="st24 st1 st4"
        >
          23
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[23])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 23) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 23) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="264.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 373.3531 268.7979)"
          class="st24 st1 st4"
        >
          23
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[56])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 56) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 56) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="400.5" cy="264.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 393.7642 268.7979)"
          class="st24 st1 st4"
        >
          56
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[56])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 56) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 56) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="400.5" cy="264.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 393.7642 268.7979)"
          class="st24 st1 st4"
        >
          56
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[62])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 62) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 62) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.5" cy="264.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.9329 268.7979)"
          class="st24 st1 st4"
        >
          62
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[62])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 62) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 62) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.5" cy="264.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.9329 268.7979)"
          class="st24 st1 st4"
        >
          62
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[16])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 16) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 16) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="284.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 341.6957 288.3101)"
          class="st24 st1 st4"
        >
          16
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[16])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 16) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 16) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="284.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 341.6957 288.3101)"
          class="st24 st1 st4"
        >
          16
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[35])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 35) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 35) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="412.4" cy="284.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 405.8526 288.3097)"
          class="st24 st1 st4"
        >
          35
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[35])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 35) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 35) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="412.4" cy="284.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 405.8526 288.3097)"
          class="st24 st1 st4"
        >
          35
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[20])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 20) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 20) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="307.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.4842 310.9384)"
          class="st24 st1 st4"
        >
          20
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[20])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 20) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 20) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="307.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.4842 310.9384)"
          class="st24 st1 st4"
        >
          20
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[31])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 31) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 31) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="352" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 345.9092 333.4576)"
          class="st24 st1 st4"
        >
          31
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[31])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 31) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 31) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="352" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 345.9092 333.4576)"
          class="st24 st1 st4"
        >
          31
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[8])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 8) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 8) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="371" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 367.8749 333.4576)"
          class="st24 st1 st4"
        >
          8
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[8])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 8) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 8) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="371" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 367.8749 333.4576)"
          class="st24 st1 st4"
        >
          8
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[1])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 1) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 1) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380.1" cy="374.9" r="8.4" />
        </g>
        <g id="text1257_00000089569589079958716780000005971288344743797950_">
          <text
            transform="matrix(1 0 0 1 377.0154 378.5356)"
            class="st24 st1 st4"
          >
            1
          </text>
        </g>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[1])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 1) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 1) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380.1" cy="374.9" r="8.4" />
        </g>
        <g id="text1257_00000089569589079958716780000005971288344743797950_">
          <text
            transform="matrix(1 0 0 1 377.0154 378.5356)"
            class="st24 st1 st4"
          >
            1
          </text>
        </g>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[7])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 7) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 7) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="355.5" cy="399.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.1901 403.5932)"
          class="st24 st1 st4"
        >
          7
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[7])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 7) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 7) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="355.5" cy="399.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.1901 403.5932)"
          class="st24 st1 st4"
        >
          7
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[15])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 15) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 15) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="355.5" cy="440.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 348.3311 444.5052)"
          class="st24 st1 st4"
        >
          15
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[15])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 15) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 15) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="355.5" cy="440.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 348.3311 444.5052)"
          class="st24 st1 st4"
        >
          15
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[13])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 13) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 13) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="404.2" cy="399.6" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 396.944 403.5932)" class="st24 st1 st4">
          13
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[13])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 13) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 13) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="404.2" cy="399.6" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 396.944 403.5932)" class="st24 st1 st4">
          13
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[46])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 46) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 46) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="404.2" cy="440.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 397.0172 444.5048)"
          class="st24 st1 st4"
        >
          46
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[46])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 46) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 46) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="404.2" cy="440.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 397.0172 444.5048)"
          class="st24 st1 st4"
        >
          46
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[26])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 26) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 26) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <ellipse class="st36" cx="449.3" cy="483.4" rx="8.4" ry="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 442.6459 487.3177)"
          class="st24 st1 st4"
        >
          26
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[26])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 26) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 26) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <ellipse class="st36" cx="449.3" cy="483.4" rx="8.4" ry="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 442.6459 487.3177)"
          class="st24 st1 st4"
        >
          26
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[40])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 40) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 40) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="506.1" cy="497.4" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 499.1445 501.6343)"
          class="st24 st1 st4"
        >
          40
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[40])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 40) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 40) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="506.1" cy="497.4" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 499.1445 501.6343)"
          class="st24 st1 st4"
        >
          40
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[6])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 6) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 6) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <ellipse
            transform="matrix(0.9239 -0.3827 0.3827 0.9239 -181.2007 241.042)"
            class="st36"
            cx="515.3"
            cy="576"
            rx="8.4"
            ry="8.4"
          />
        </g>
        <text
          transform="matrix(1 0 0 1 512.0299 580.3291)"
          class="st24 st1 st4"
        >
          6
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[6])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 6) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 6) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <ellipse
            transform="matrix(0.9239 -0.3827 0.3827 0.9239 -181.2007 241.042)"
            class="st36"
            cx="515.3"
            cy="576"
            rx="8.4"
            ry="8.4"
          />
        </g>
        <text
          transform="matrix(1 0 0 1 512.0299 580.3291)"
          class="st24 st1 st4"
        >
          6
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[49])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 49) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 49) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <ellipse class="st36" cx="534.1" cy="586.2" rx="8.4" ry="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 527.3984 590.1642)"
          class="st24 st1 st4"
        >
          49
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[49])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 49) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 49) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <ellipse class="st36" cx="534.1" cy="586.2" rx="8.4" ry="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 527.3984 590.1642)"
          class="st24 st1 st4"
        >
          49
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[55])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 55) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 55) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="557.4" cy="596" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 550.548 600.635)" class="st24 st1 st4">
          55
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[55])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 55) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 55) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="557.4" cy="596" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 550.548 600.635)" class="st24 st1 st4">
          55
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[30])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 30) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 30) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="579.3" cy="605.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 572.5731 610.0754)"
          class="st24 st1 st4"
        >
          30
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[30])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 30) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 30) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="579.3" cy="605.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 572.5731 610.0754)"
          class="st24 st1 st4"
        >
          30
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[37])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 37) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 37) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="537.4" cy="564.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 530.8704 569.4891)"
          class="st24 st1 st4"
        >
          37
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[37])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 37) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 37) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="537.4" cy="564.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 530.8704 569.4891)"
          class="st24 st1 st4"
        >
          37
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[22])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 22) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 22) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="557.4" cy="556.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 550.9888 560.3697)"
          class="st24 st1 st4"
        >
          22
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[22])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 22) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 22) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="557.4" cy="556.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 550.9888 560.3697)"
          class="st24 st1 st4"
        >
          22
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[22])"
        :class="{
          visible: isDefinedObj(compoSite.chart2.gates, 22)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="557.4" cy="556.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 550.9888 560.3697)"
          class="st24 st1 st4"
        >
          22
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[36])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 36) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 36) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="578.9" cy="546.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 572.0687 551.0961)"
          class="st24 st1 st4"
        >
          36
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[36])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 36) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 36) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="578.9" cy="546.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 572.0687 551.0961)"
          class="st24 st1 st4"
        >
          36
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[50])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 50) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 50) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="245.7" cy="576" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 239.2089 580.3292)"
          class="st24 st1 st4"
        >
          50
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[50])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 50) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 50) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="245.7" cy="576" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 239.2089 580.3292)"
          class="st24 st1 st4"
        >
          50
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[32])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 32) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 32) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="227" cy="586.2" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 219.9996 590.214)" class="st24 st1 st4">
          32
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[32])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 32) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 32) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="227" cy="586.2" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 219.9996 590.214)" class="st24 st1 st4">
          32
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[28])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 28) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 28) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="203.7" cy="596" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 196.9044 600.1719)"
          class="st24 st1 st4"
        >
          28
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[28])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 28) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 28) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="203.7" cy="596" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 196.9044 600.1719)"
          class="st24 st1 st4"
        >
          28
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[28])"
        :class="{
          visible: isDefinedObj(compoSite.chart2.gates, 28)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="203.7" cy="596" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 196.9044 600.1719)"
          class="st24 st1 st4"
        >
          28
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[18])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 18) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 18) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="181.8" cy="605.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 174.6495 610.0099)"
          class="st24 st1 st4"
        >
          18
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[18])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 18) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 18) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="181.8" cy="605.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 174.6495 610.0099)"
          class="st24 st1 st4"
        >
          18
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[18])"
        :class="{
          visible: isDefinedObj(compoSite.chart2.gates, 18)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="181.8" cy="605.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 174.6495 610.0099)"
          class="st24 st1 st4"
        >
          18
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[44])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 44) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 44) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="227" cy="567" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 219.8879 570.7967)"
          class="st24 st1 st4"
        >
          44
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[44])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 44) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 44) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="227" cy="567" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 219.8879 570.7967)"
          class="st24 st1 st4"
        >
          44
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[57])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 57) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 57) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="203.7" cy="556.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 197.2518 561.0231)"
          class="st24 st1 st4"
        >
          57
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[57])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 57) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 57) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="203.7" cy="556.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 197.2518 561.0231)"
          class="st24 st1 st4"
        >
          57
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[48])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 48) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 48) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="182.2" cy="546.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 175.3022 550.7848)"
          class="st24 st1 st4"
        >
          48
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[48])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 48) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 48) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="182.2" cy="546.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 175.3022 550.7848)"
          class="st24 st1 st4"
        >
          48
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[21])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 21) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 21) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="485.6" cy="457.5" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 479.479 461.6385)" class="st24 st1 st4">
          21
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[21])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 21) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 21) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="485.6" cy="457.5" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 479.479 461.6385)" class="st24 st1 st4">
          21
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[51])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 51) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 51) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="467.5" cy="470.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 461.2335 475.0102)"
          class="st24 st1 st4"
        >
          51
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[51])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 51) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 51) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="467.5" cy="470.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 461.2335 475.0102)"
          class="st24 st1 st4"
        >
          51
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[2])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 2) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 2) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="463.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 376.4817 467.6089)"
          class="st24 st1 st4"
        >
          2
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[2])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 2) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 2) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="463.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 376.4817 467.6089)"
          class="st24 st1 st4"
        >
          2
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[14])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 14) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 14) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="546.4" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 372.613 550.3354)" class="st24 st1 st4">
          14
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[14])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 14) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 14) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="380" cy="546.4" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 372.613 550.3354)" class="st24 st1 st4">
          14
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[5])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 5) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 5) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="358" cy="552.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 354.5763 556.6337)"
          class="st24 st1 st4"
        >
          5
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[5])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 5) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 5) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="358" cy="552.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 354.5763 556.6337)"
          class="st24 st1 st4"
        >
          5
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[34])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 34) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 34) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="341.2" cy="575.4" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 334.5106 579.5446)"
          class="st24 st1 st4"
        >
          34
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[34])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 34) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 34) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="341.2" cy="575.4" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 334.5106 579.5446)"
          class="st24 st1 st4"
        >
          34
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[27])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 27) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 27) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="343.4" cy="601.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 336.7351 605.7427)"
          class="st24 st1 st4"
        >
          27
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[27])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 27) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 27) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="343.4" cy="601.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 336.7351 605.7427)"
          class="st24 st1 st4"
        >
          27
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[42])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 42) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 42) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.9" cy="621.7" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 352.588 625.5479)" class="st24 st1 st4">
          42
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[42])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 42) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 42) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.9" cy="621.7" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 352.588 625.5479)" class="st24 st1 st4">
          42
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[3])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 3) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 3) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="379.1" cy="627.3" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 375.7817 631.6357)"
          class="st24 st1 st4"
        >
          3
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[3])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 3) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 3) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="379.1" cy="627.3" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 375.7817 631.6357)"
          class="st24 st1 st4"
        >
          3
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[60])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 60) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 60) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="379.1" cy="680.2" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 372.5056 684.6094)"
          class="st24 st1 st4"
        >
          60
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[60])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 60) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 60) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="379.1" cy="680.2" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 372.5056 684.6094)"
          class="st24 st1 st4"
        >
          60
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[52])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 52) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 52) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="400.5" cy="680.2" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 393.657 684.6094)" class="st24 st1 st4">
          52
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[52])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 52) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 52) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="400.5" cy="680.2" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 393.657 684.6094)" class="st24 st1 st4">
          52
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[53])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 53) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 53) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.5" cy="680.2" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.5877 684.6101)"
          class="st24 st1 st4"
        >
          53
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[53])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 53) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 53) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="359.5" cy="680.2" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 352.5877 684.6101)"
          class="st24 st1 st4"
        >
          53
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[54])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 54) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 54) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="696.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.6194 701.0068)"
          class="st24 st1 st4"
        >
          54
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[54])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 54) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 54) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="696.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.6194 701.0068)"
          class="st24 st1 st4"
        >
          54
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[38])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 38) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 38) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="719.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.5951 723.6044)"
          class="st24 st1 st4"
        >
          38
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[38])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 38) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 38) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="719.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.5951 723.6044)"
          class="st24 st1 st4"
        >
          38
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[58])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 58) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 58) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="740.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.5949 744.8518)"
          class="st24 st1 st4"
        >
          58
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[58])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 58) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 58) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="349.1" cy="740.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 342.5949 744.8518)"
          class="st24 st1 st4"
        >
          58
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[19])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 19) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 19) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="411.1" cy="696.6" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 403.985 701.1323)" class="st24 st1 st4">
          19
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[19])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 19) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 19) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="411.1" cy="696.6" r="8.4" />
        </g>
        <text transform="matrix(1 0 0 1 403.985 701.1323)" class="st24 st1 st4">
          19
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[39])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 39) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 39) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="411.1" cy="719.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 404.5404 723.5587)"
          class="st24 st1 st4"
        >
          39
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[39])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 39) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 39) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="411.1" cy="719.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 404.5404 723.5587)"
          class="st24 st1 st4"
        >
          39
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[41])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 41) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 41) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="411.1" cy="740.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 404.5401 744.8514)"
          class="st24 st1 st4"
        >
          41
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[41])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 41) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 41) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="411.1" cy="740.6" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 404.5401 744.8514)"
          class="st24 st1 st4"
        >
          41
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[9])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 9) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 9) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="398.5" cy="621.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 395.1625 626.1525)"
          class="st24 st1 st4"
        >
          9
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[9])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 9) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 9) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="398.5" cy="621.9" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 395.1625 626.1525)"
          class="st24 st1 st4"
        >
          9
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[59])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 59) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 59) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="414.6" cy="599.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 407.5855 603.8096)"
          class="st24 st1 st4"
        >
          59
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[59])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 59) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 59) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="414.6" cy="599.7" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 407.5855 603.8096)"
          class="st24 st1 st4"
        >
          59
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[29])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 29) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 29) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="399.9" cy="552.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 393.5689 556.4897)"
          class="st24 st1 st4"
        >
          29
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[29])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 29) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 29) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="399.9" cy="552.5" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 393.5689 556.4897)"
          class="st24 st1 st4"
        >
          29
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[25])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 25) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 25) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="423" cy="420.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 416.4097 424.1495)"
          class="st24 st1 st4"
        >
          25
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[25])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 25) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 25) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="423" cy="420.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 416.4097 424.1495)"
          class="st24 st1 st4"
        >
          25
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[10])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 10) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 10) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="336.4" cy="420.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 329.1698 424.1498)"
          class="st24 st1 st4"
        >
          10
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[10])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 10) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 10) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="336.4" cy="420.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 329.1698 424.1498)"
          class="st24 st1 st4"
        >
          10
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[33])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 33) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 33) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="390" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 383.5239 333.4576)"
          class="st24 st1 st4"
        >
          33
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[33])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 33) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 33) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="390" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 383.5239 333.4576)"
          class="st24 st1 st4"
        >
          33
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[45])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 45) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 45) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="409" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 402.3348 333.4395)"
          class="st24 st1 st4"
        >
          45
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[45])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 45) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 45) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="409" cy="329.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 402.3348 333.4395)"
          class="st24 st1 st4"
        >
          45
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart.gatesExt[12])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart.gates, 12) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart.gates, 12) &&
              isChartTurnOn &&
              !isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="412.4" cy="307.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 405.5345 311.0104)"
          class="st24 st1 st4"
        >
          12
        </text>
      </g>
      <g
        @click="openTooltipsDialog(compoSite.chart2.gatesExt[12])"
        :class="{
          visible:
            (isDefinedObj(compoSite.chart2.gates, 12) &&
              !isChartTurnOn &&
              !isChart2TurnOn) ||
            (isDefinedObj(compoSite.chart2.gates, 12) &&
              !isChartTurnOn &&
              isChart2TurnOn)
        }"
        class="invisible over-shadow-anim"
      >
        <g>
          <circle class="st36" cx="412.4" cy="307.1" r="8.4" />
        </g>
        <text
          transform="matrix(1 0 0 1 405.5345 311.0104)"
          class="st24 st1 st4"
        >
          12
        </text>
      </g>
    </g>
  </svg>
</template>
<script>
// import Api from "@/api/api";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";

export default {
  name: "CompoSite",
  props: {
    compoSite: {
      type: Object,
      default: null
    },
    isChartTurnOn: {
      type: Boolean,
      default: false
    },
    isChart2TurnOn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // showTooltipsDialog: false
    };
  },
  mixins: [commonReportingMethods],
  computed: {
    css() {
      return `<style>.st0{fill:#97D8C0;}.st1{font-family:'ArialMT';}.st2{font-size:15.22px;}.st3{fill:#D29575;}
        .st4{font-size:12px;}.st5{fill:#58B730;}.st6{font-size:11.68px;}
        .st7{fill:none;stroke:#D29575;stroke-width:2;stroke-miterlimit:10;}
        .st8{fill:none;stroke:#97D8C0;stroke-width:2;stroke-miterlimit:10;}
        .st9{fill:#c095fc;}.st10{fill:#ce7503;}.st11{fill:#a774ec;}.st12{font-size:21px;}
        .st13{fill:#a774ec;stroke:#FFFFFF;stroke-miterlimit:10;}
        .st14{fill:#a774ec;stroke:#FFFFFF;stroke-width:1.5;stroke-miterlimit:10;}
        .st15{fill:#a774ec;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
        .st16{fill:#a774ec;enable-background:new;}
        .st17{font-family:'Arial-BoldMT';}
        .st18{font-size:20.72px;}
        .st19{fill:#ce7503;stroke:#ce7503;stroke-width:0.75;stroke-miterlimit:10;}
        .st20{fill:#ce7503;stroke:#FFFFFF;stroke-width:1.5;stroke-miterlimit:10;}
        .st21{fill:#ce7503;stroke:#FFFFFF;stroke-miterlimit:10;}
        .st22{fill:#ce7503;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
        .st23{fill:#ce7503;stroke:#FFFFFF;stroke-miterlimit:10.0024;}
        .st24{fill:#FFFFFF;}
        .st25{fill:#777777;}
        .st26{fill:#FFFFFF;stroke:#777777;stroke-width:0.25;stroke-miterlimit:10;}
        .st27{fill:#ce7503;}
        .st28{fill:#c095fc;}
        .st29{fill:none;stroke:#ce7503;stroke-width:8;stroke-miterlimit:10;}
        .st30{opacity:0.8125;fill:#0C0B00;enable-background:new;}
        .st31{fill:#DC143C;stroke:#A5A5A5;stroke-miterlimit:10;}
        .st32{fill:#D8A67D;stroke:#A5A5A5;stroke-miterlimit:10;}
        .st33{fill:#FDE287;stroke:#A5A5A5;stroke-miterlimit:10;}
        .st34{fill:#A5A5A5;}
        .st35{fill:#1D1D1B;}
        .st36{fill:#8B4BDD;}
        .st37{fill:#FFFFFF;fill-opacity:0;}
        .invisible { display: none; }
        .visible { display: initial;cursor: pointer;visibility: initial; }</style>`;
    }
  },
  mounted() {
    console.log("compoSite in component: ", this.compoSite);
  },
  methods: {
    openTooltipsDialog(obj) {
      this.$emit("incomeToolObj", obj);
    },
    openTooltipsMultiDialog(...channels) {
      const title = channels.map(({ title }) => `\n${title}`).join("");
      const tooltip = channels
        .map(({ id, tooltip }) => `\n${id}. ${tooltip}`)
        .join("");
      const multiObj = {
        title,
        tooltip
      };
      this.$emit("incomeToolObj", multiObj);
    },
    toSearchItemAtArrInObj(obj, q) {
      let foundItem;
      for (const key in obj) {
        obj[key].forEach(item => {
          if (item.id.includes(q)) {
            foundItem = item;
          }
        });
      }
      return foundItem;
    },
    toSearchItemAtArrInObj2(obj, q) {
      let foundItem;
      for (const key in obj) {
        obj[key].forEach(item => {
          if (item.id.includes(q)) {
            foundItem = item;
            foundItem.id = q; // для красивого заголовка канала в табах типа '10-20'
          }
        });
      }
      return foundItem;
    },
    isChannel(obj, q) {
      let isChannel = false;
      for (const key in obj) {
        obj[key].forEach(item => {
          if (item.id.includes(q)) {
            isChannel = true;
          }
        });
      }
      return isChannel;
    }
  }
};
</script>

<style scoped>
.st0 {
  fill: #97d8c0;
}
.st1 {
  font-family: "ArialMT";
}
.st2 {
  font-size: 15.22px;
}
.st3 {
  fill: #d29575;
}
.st4 {
  font-size: 12px;
}
.st-color {
  font-size: 10px;
}
.st5 {
  fill: #58b730;
}
.st6 {
  font-size: 11.68px;
}
.st7 {
  fill: transparent;
  stroke: #d29575;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st8 {
  fill: transparent;
  stroke: #97d8c0;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #c095fc;
}
.st10 {
  fill: #ce7503;
}
.st10_2 {
  fill: #6c7275;
}
.st11 {
  fill: #a774ec;
}
.st11_2 {
  fill: #e00544;
}
.st12 {
  font-size: 21px;
}
.st13 {
  fill: #a774ec;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st13_2 {
  fill: #e00544;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st14 {
  fill: #a774ec;
  stroke: #ffffff;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}
.st14_2 {
  fill: #e00544;
  stroke: #ffffff;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #a774ec;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st15_2 {
  fill: #e00544;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #a774ec;
  enable-background: new;
}
.st16_2 {
  fill: #e00544;
  enable-background: new;
}
.st17 {
  font-family: "Arial-BoldMT";
}
.st18 {
  font-size: 20.72px;
}
.st19 {
  fill: #ce7503;
  stroke: #ce7503;
  stroke-width: 0.75;
  stroke-miterlimit: 10;
}
.st20 {
  fill: #ce7503;
  stroke: #ffffff;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}
.st21 {
  fill: #ce7503;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st22 {
  fill: #ce7503;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st23 {
  fill: #ce7503;
  stroke: #ffffff;
  stroke-miterlimit: 10.0024;
}
.st19_2 {
  fill: #6c7275;
  stroke: #6c7275;
  stroke-width: 0.75;
  stroke-miterlimit: 10;
}
.st20_2 {
  fill: #6c7275;
  stroke: #ffffff;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}
.st21_2 {
  fill: #6c7275;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st22_2 {
  fill: #6c7275;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st23_2 {
  fill: #6c7275;
  stroke: #ffffff;
  stroke-miterlimit: 10.0024;
}
.st24 {
  fill: #ffffff;
}
.st25 {
  fill: #777777;
}
.st26 {
  fill: #ffffff;
  stroke: #777777;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st27 {
  fill: #ce7503;
}
.st28 {
  fill: #c095fc;
}
.st29 {
  fill: none;
  stroke: #ce7503;
  stroke-width: 8;
  stroke-miterlimit: 10;
}
.st30 {
  opacity: 0.8125;
  fill: #0c0b00;
  enable-background: new;
}
.st31 {
  fill: #dc143c;
  stroke: #a5a5a5;
  stroke-miterlimit: 10;
}
.st32 {
  fill: #d8a67d;
  stroke: #a5a5a5;
  stroke-miterlimit: 10;
}
.st33 {
  fill: #fde287;
  stroke: #a5a5a5;
  stroke-miterlimit: 10;
}
.st34 {
  fill: #a5a5a5;
}
.st35 {
  fill: #1d1d1b;
}
.st36 {
  fill: #8b4bdd;
}
.st37 {
  fill: #ffffff;
  fill-opacity: 0;
}
.st38 {
  fill: #e00544;
  display: initial !important;
  visibility: initial !important;
}
.st39 {
  fill: #3e4448;
  display: initial !important;
  visibility: initial !important;
}
.report-link {
  fill: #373734;
  font-family: "ArialMT";
  font-size: 15.22px;
  cursor: pointer;
}
.over-shadow-anim:hover {
  animation: pulsing 1.5s infinite;
}
.gate-over {
  filter: drop-shadow(0 0 3px rgb(224 5 68 / 1));
}
.channels-over:hover {
  stroke: rgb(139 75 221);
  stroke-width: 2px;
  stroke-linecap: butt;
  stroke-dasharray: 5, 5;
}
@keyframes pulsing {
  0% {
    filter: drop-shadow(0px 0px 2px rgb(139 75 221 / 0.2));
  }
  50% {
    filter: drop-shadow(0px 0px 3px rgb(139 75 221 / 1));
  }
  100% {
    filter: drop-shadow(0px 0px 2px rgb(139 75 221 / 0.2));
  }
}
.invisible {
  display: none;
}
.visible {
  display: initial;
  cursor: pointer;
  visibility: initial;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
